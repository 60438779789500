import React, { useState, useEffect, useCallback } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Paper,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import api from "../config/api";
import Price from "../components/Price";
import FulfillmentStatus from "../components/FulfillmentStatus";
import FinancialStatus from "../components/FinancialStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflowX: "auto",
  },
  card: {
    width: "100%",
    maxWidth: "100%",
  },
  container: {
    width: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  tableRow: {
    cursor: "pointer",
  },
}));

const COLUMNS = [
  {
    key: "name",
    label: "Order",
    minWidth: 100,
  },
  {
    key: "created_at",
    label: "Date",
    minWidth: 250,
  },
  {
    key: "total_price",
    label: "Total",
    minWidth: 120,
  },
  {
    key: "customer",
    label: "Customer",
    minWidth: 150,
  },
  {
    key: "financial_status",
    label: "Payment",
    minWidth: 100,
  },
  {
    key: "fulfillment_status",
    label: "Fulfillment",
    minWidth: 150,
  },
  {
    key: "line_items",
    label: "Items",
    minWidth: 100,
  },
  {
    key: "tags",
    label: "Tags",
    minWidth: 140,
  },
];

const Orders = () => {
  const classes = useStyles();
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [isFetchingOrder, setFetchingOrderStatus] = useState(true);
  const [nextPageParams, setNextPageParams] = useState(null);
  const [prevPageParams, setPrevPageParams] = useState(null);

  const getOrderCount = useCallback(async () => {
    const result = await api
      .get("orders/count", {
        limit,
      })
      .catch((error) => {});
    if (result && result.data && result.data.success) {
      setCount(result.data.data);
    }
  }, []);

  const getOrders = useCallback(async (param) => {
    setFetchingOrderStatus(true);
    const result = await api.post("orders", param).catch((error) => {
      setFetchingOrderStatus(false);
    });
    console.log(result);
    if (result && result.data && result.data.success) {
      setFetchingOrderStatus(false);
      setOrders(result.data.data.orders);
      setNextPageParams(result.data.data.nextPageParams);
      setPrevPageParams(result.data.data.prevPageParams);
    }
  }, []);

  useEffect(() => {
    getOrderCount();
    getOrders({ limit, nextPageParams });
  }, []);

  const onOrderPress = (order) => {
    history.push(`orders/${order.id}`, {
      order,
    });
  };

  const renderDate = (dateToFormate) => {
    let date = new Date(dateToFormate);
    return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    getOrders({ limit: event.target.value });
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage < page) {
      getOrders(prevPageParams).then(() => {
        setPage(newPage);
      });
    } else {
      getOrders(nextPageParams).then(() => {
        setPage(newPage);
      });
    }
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <Typography variant="h3">Orders</Typography>
        </Grid>

        <Paper className={classes.root}>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              className={classes.container}
            >
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.key}
                      align={"left"}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => {
                  return (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={order.id.toString()}
                      onClick={() => {
                        onOrderPress(order);
                      }}
                    >
                      <TableCell align={"left"}>{order.name}</TableCell>
                      <TableCell align={"left"}>
                        {renderDate(order.created_at)}
                      </TableCell>
                      <TableCell align={"left"}>
                        <Price
                          amount={order.total_price}
                          currency={order.currency}
                        />
                      </TableCell>
                      <TableCell align={"left"}>
                        {order?.customer?.first_name || ""}{" "}
                        {order?.customer?.last_name || ""}
                      </TableCell>
                      <TableCell align={"left"}>
                        <FinancialStatus
                          financialStatus={order.financial_status}
                        />
                      </TableCell>
                      <TableCell align={"left"}>
                        <FulfillmentStatus
                          fullfillmentStatus={order.fulfillment_status}
                        />
                      </TableCell>
                      <TableCell align={"left"}>
                        {order.line_items?.length || 0} Items
                      </TableCell>
                      <TableCell align={"left"}>{order.tags}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Backdrop open={isFetchingOrder} className={classes.backdrop}>
            <CircularProgress />
          </Backdrop>
        </Paper>
      </Grid>
    </Container>
  );
};

export default Orders;

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const ConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  onCancel,
  message,
  title = "Delete",
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={(e) => onCancel()}
          color="secondary"
          variant="contained"
        >
          NO
        </Button>
        <Button
          onClick={(e) => onConfirm()}
          color="primary"
          variant="contained"
        >
          YES
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

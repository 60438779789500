import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
import { makeStyles, Box, Popover, ButtonBase } from "@material-ui/core";
import { PRESET_COLORS } from "../config/data";
import FormatColorTextIcon from "@material-ui/icons/FormatColorText";

const defaultColor = {
  r: 0,
  g: 0,
  b: 0,
  a: 1,
};

const useStyle = makeStyles((theme) => ({
  container: {
    position: "relative",
  },
  colorPickerButton: {
    height: 40,
    width: 40,
    borderRadius: 40,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
  color: {
    height: "100%",
    width: "100%",
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.text.hint,
    borderStyle: "solid",
  },
  label: {
    position: "absolute",
    top: -7,
    left: 10,
    paddingLeft: 5,
    paddingRight: 5,
    backgroundColor: theme.palette.background.paper,
  },
}));

const InputFieldColorPicker = ({
  label = "Color",
  selectedColor = defaultColor,
  onSelect,
  style,
}) => {
  const classes = useStyle();
  const [color, setColor] = useState(selectedColor);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    setColor(selectedColor);
  }, [selectedColor]);

  useEffect(() => {
    setColor(selectedColor);
    onSelect({
      value: `rgba(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b}, ${selectedColor.a})`,
      rgba: selectedColor,
    });
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (color) => {
    setColor(color.rgb);
  };

  const onChangeComplete = (e) => {
    onSelect({
      value: `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`,
      rgba: e.rgb,
    });
    setColor(e.rgb);
  };

  return (
    <Box className={classes.container} style={style}>
      {/* Original */}

      <ButtonBase onClick={handleClick} className={classes.colorPickerButton}>
        <FormatColorTextIcon
          style={{
            color: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          }}
        />
      </ButtonBase>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <SketchPicker
          presetColors={PRESET_COLORS}
          color={color}
          onChange={handleChange}
          onChangeComplete={onChangeComplete}
        />
      </Popover>
    </Box>
  );
};

export default InputFieldColorPicker;

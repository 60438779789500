import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  FormGroup,
  InputLabel,
  Button,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import api from "../../config/api";
import { showMessage, showError } from "../../actions/snackbarAction";
import { pageFormMode } from "../../contants/others";
import "react-quill/dist/quill.snow.css";
import { addGiftCardValidationScheme } from "../../utils/yupValidationScheme";
import LinkSelector from "../../components/LinkSelector";
import ImageSelector from "../../components/ImageSelector";
import FormNote from "../../components/FormNote";
import SectionCommonStyleCreator from "../../components/SectionCommonStyleCreator";
import VisibilityCreator from "../../components/VisibilityCreator";
import ButtonField from "../../components/ButtonField";
import TitleCreator from "../../components/TitleCreator";

const useStyle = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  addParagraphBtn: {
    width: "100%",
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderWidth: 1,
    borderStyle: "dashed",
  },
  selectedCollectionItemMedia: {
    height: 190,
    width: "100%",
    backgroundSize: "contain",
  },
}));

const GiftCard = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formMode, setFormMode] = useState(pageFormMode.CREATE);
  const [prevFormData, setPrevFormData] = useState({});

  useEffect(() => {
    const { mode, data } = history.location.state;
    if (data) {
      setFormMode(mode);
      setPrevFormData(data);
    }
  }, [history.location.state]);

  const onSave = (values, { setSubmitting }) => {
    const { section } = history.location.state;
    const params = {
      title: section.title,
      contentType: section.key,
      content: null,
      settings: values,
    };

    if (!formMode || formMode === pageFormMode.CREATE) {
      api
        .post("app-content", params)
        .then((res) => {
          setSubmitting(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showMessage(error.response.data.message));
        });
    } else {
      api
        .put(`app-content/${prevFormData._id}`, params)
        .then((res) => {
          setSubmitting(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showMessage(error.response.data.message));
        });
    }
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <Typography variant="h3">
            {history.location.state.data?.settings?.heading?.text
              ? history.location.state.data?.settings?.heading.text
              : history.location.state.section?.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={
              !formMode || formMode === pageFormMode.CREATE
                ? {
                    heading: null,
                    subHeading: null,
                    headingDivider: null,
                    button: null,
                    image: null,
                    link: null,
                    extraStyle: null,
                    visibility: true,
                  }
                : {
                    ...prevFormData.settings,
                  }
            }
            onSubmit={onSave}
            validationSchema={addGiftCardValidationScheme}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleSubmit,
              setTouched,
            }) => (
              <form>
                <Card variant="outlined">
                  <CardContent>
                    <FormNote />
                    <TitleCreator
                      heading={values.heading}
                      onHeadingChange={(updatedHeading) =>
                        setFieldValue("heading", updatedHeading)
                      }
                      subHeading={values.subHeading}
                      onSubHeadingChange={(updatedSubHeading) =>
                        setFieldValue("subHeading", updatedSubHeading)
                      }
                      headingDivider={values.headingDivider}
                      onHeadingDividerChange={(newHD) => {
                        setFieldValue("headingDivider", newHD);
                      }}
                    />
                    <ButtonField
                      button={values.button}
                      onButtonChange={(button) =>
                        setFieldValue("button", button)
                      }
                    />
                    <FormGroup className={classes.formField}>
                      <InputLabel>Image</InputLabel>
                      <ImageSelector
                        selectedImage={values.image}
                        onImageChange={(image) => setFieldValue("image", image)}
                        imageUploadFolder="App-Content/gift-card"
                      />
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        style={{ marginLeft: 10 }}
                      >
                        Forground image or icon that will be displayed with text
                      </Typography>
                    </FormGroup>
                    <LinkSelector
                      label="Label link"
                      preSelectedLink={values.link}
                      onScreenSelect={(screen) =>
                        setFieldValue("link", { ...values.link, screen })
                      }
                      onParamSelect={(param) =>
                        setFieldValue("link", { ...values.link, param })
                      }
                      helperText="Link that you want to apply on button or on image if button not added"
                    />
                    <SectionCommonStyleCreator
                      extraStyle={values.extraStyle}
                      onExtraStyleChange={(extraStyle) =>
                        setFieldValue("extraStyle", extraStyle)
                      }
                    />
                    <VisibilityCreator
                      visibility={values.visibility}
                      onVisibilityChange={(visibility) =>
                        setFieldValue("visibility", visibility)
                      }
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      title="Save"
                      variant="contained"
                      color="secondary"
                      onClick={() => history.goBack()}
                    >
                      CANCEL
                    </Button>
                    <Button
                      title="Save"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Saving" : "Save"}
                      {isSubmitting && (
                        <CircularProgress
                          size={20}
                          color="primary"
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </Button>
                  </CardActions>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GiftCard;

import React from "react";
import { Typography } from "@babel/core";
import {
  Paid,
  Pending,
  Authorized,
  PartiallyPaid,
  PartiallyRefunded,
  Refunded,
  Voided,
} from "../components/Labels";

const FinancialStatus = ({ financialStatus }) => {
  switch (financialStatus) {
    case "pending": {
      return <Pending />;
    }
    case "authorized": {
      return <Authorized />;
    }
    case "partially_paid": {
      return <PartiallyPaid />;
    }
    case "paid": {
      return <Paid />;
    }
    case "partially_refunded": {
      return <PartiallyRefunded />;
    }
    case "refunded": {
      return <Refunded />;
    }
    case "voided": {
      return <Voided />;
    }
    default: {
      return <Typography variant="caption">{financialStatus}</Typography>;
    }
  }
};

export default FinancialStatus;

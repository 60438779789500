import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik } from "formik";
import { resetPasswordValidationScheme } from "../utils/yupValidationScheme";
import api from "../config/api";
import { showError, showMessage } from "../actions/snackbarAction";
import { useDispatch } from "react-redux";
import useToken from "../utils/useToken";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    overflow: "scroll",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  root: {
    overflow: "scroll",
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(1, 2, 1, 0),
  },
}));

const ResetPasswordModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token, setToken } = useToken();

  const onSubmit = (values, { setSubmitting }) => {
    api
      .post(
        `/users/${props.userId}/reset-password`,
        {
          password: values.password,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSubmitting(false);
        if (res.data.success) {
          setToken(res.data.data.token);
          dispatch(showMessage(res.data.message));
          props.handleClose();
        } else {
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        dispatch(showError(error.response.data.message));
        setSubmitting(false);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Reset Password</DialogTitle>
      <DialogContent dividers>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={resetPasswordValidationScheme}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={errors.password && touched.password}
                helperText={
                  errors.password && touched.password && errors.password
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm New Password"
                type="password"
                id="confirmPassword"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                error={errors.confirmPassword && touched.confirmPassword}
                helperText={
                  errors.confirmPassword &&
                  touched.confirmPassword &&
                  errors.confirmPassword
                }
              />
              <Button
                variant="contained"
                color="secondary"
                className={classes.submit}
                onClick={props.handleClose}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <CircularProgress size={20} color="#ffffff" />
                ) : (
                  "SAVE"
                )}
              </Button>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPasswordModal;

import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ButtonBase,
  Typography,
  Box,
} from "@material-ui/core";
import FormNote from "./FormNote";
import { useFormik } from "formik";
import ButtonField from "./ButtonField";

const ButtonCreator = ({ onButtonCreate, preData }) => {
  const [isOpen, setOpenStatus] = useState(false);
  const { values, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: preData
      ? preData
      : {
          button: null,
        },
    onSubmit: (values) => onSave(values),
  });

  const openDialog = () => {
    setOpenStatus(true);
  };

  const closeDialog = () => {
    setOpenStatus(false);
    resetForm();
  };

  const onSave = (values) => {
    onButtonCreate({ ...values });
    closeDialog();
  };

  return (
    <Box>
      <ButtonBase onClick={openDialog} style={{ margin: 10, width: "100%" }}>
        <Typography>{values?.button?.label || "Button"}</Typography>
      </ButtonBase>
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogContent>
          <FormNote />
          <ButtonField
            button={values.button}
            onButtonChange={(button) => setFieldValue("button", button)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeDialog} variant="contained">
            CANCEL
          </Button>
          <Button color="primary" onClick={handleSubmit} variant="contained">
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ButtonCreator;

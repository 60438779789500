import * as ActionTypes from "../contants/actionKeys";

export const showMessage = (message) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SHOW_SUCCESS_SNACKBAR,
      message,
    });
  };
};

export const closeMessage = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_SUCCESS_SNACKBAR,
    });
  };
};

export const showError = (error) => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.SHOW_ERROR_SNACKBAR,
      message: error,
    });
  };
};

export const closeError = () => {
  return (dispatch) => {
    dispatch({
      type: ActionTypes.CLOSE_ERROR_SNACKBAR,
    });
  };
};

export const clearSnackbar = () => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.CLEAR_SNACKBAR });
  };
};

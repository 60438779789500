import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router";
import api from "../config/api";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IMAGE_PLACEHOLDER } from "../config/data";
import { useDispatch } from "react-redux";
import { showError } from "../actions/snackbarAction";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: 10,
  },
  media: {
    height: 190,
    width: "100%",
    objectFit: "contain",
  },
  addNewButtonContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flex: 1,
  },
  selected: {
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: theme["palette"].primary.main,
  },
}));

const SelectSectionType = (props) => {
  const history = useHistory();
  const descriptionElementRef = React.useRef(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDataLoading, setDataLoadingStatus] = useState(true);
  const [sectionTypes, setSectionTypes] = useState([]);
  const [selectedSectionType, setSelectedSectionType] = useState(null);
  const [isNextLoading, setNextLoadingStatus] = useState(false);

  useEffect(() => {
    api
      .get("section-types")
      .then((res) => {
        setDataLoadingStatus(false);
        if (res.data.success) {
          setSectionTypes(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);

  const onSelectSectionType = (type) => {
    setSelectedSectionType(type);
  };

  const onNext = () => {
    if (!selectedSectionType) {
      dispatch(showError("Please select section type"));
      return;
    }
    setNextLoadingStatus(true);
    api
      .post(`app-content/validate-section/${selectedSectionType._id}`)
      .then((res) => {
        setNextLoadingStatus(false);
        if (res.data.success) {
          history.push(`/app-sections/${selectedSectionType.key}`, {
            section: selectedSectionType,
          });
        } else {
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setNextLoadingStatus(false);
        dispatch(showError(error.message));
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Select Section Type</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} className={classes.row}>
          {isDataLoading ? (
            <>
              {[1, 2, 3, 4, 5, 6].map((_, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  key={index.toString()}
                >
                  <Card key={index.toString()}>
                    <CardMedia>
                      <Skeleton variant="rect" height={190} />
                    </CardMedia>
                    <CardContent>
                      <Skeleton height={20} variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                      <Skeleton variant="text" />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </>
          ) : (
            <>
              {sectionTypes.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  xl={6}
                  key={index.toString()}
                >
                  <Card
                    className={
                      selectedSectionType === item ? classes.selected : null
                    }
                  >
                    <CardActionArea onClick={() => onSelectSectionType(item)}>
                      <img
                        className={classes.media}
                        src={
                          item.image.url ? item.image?.url : IMAGE_PLACEHOLDER
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = IMAGE_PLACEHOLDER;
                        }}
                        alt={
                          item.image.url
                            ? item.image.publicId
                            : "section-type-picture"
                        }
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                          {item.title}
                        </Typography>
                        <Typography>{item.description}</Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
              {sectionTypes?.length === 0 && (
                <Typography gutterBottom variant="h5" component="h2">
                  Section type is empty
                </Typography>
              )}
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.handleClose}
          color="secondary"
          variant="contained"
        >
          CANCEL
        </Button>
        <Button
          onClick={onNext}
          color="primary"
          variant="contained"
          disabled={
            !sectionTypes ||
            isDataLoading ||
            isNextLoading ||
            sectionTypes?.length === 0
          }
        >
          {isNextLoading ? <CircularProgress size={18} /> : "NEXT"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectSectionType;

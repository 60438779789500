import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
} from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { useHistory } from "react-router";
import api from "../config/api";
import Skeleton from "@material-ui/lab/Skeleton";
import { IMAGE_PLACEHOLDER } from "../config/data";
import Zoom from "react-medium-image-zoom";
import { useDispatch } from "react-redux";
import { showError } from "../actions/snackbarAction";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: 10,
  },
  media: {
    height: 250,
    width: "100%",
    backgroundSize: "contain",
    objectFit: "contain",
  },
  addNewButtonContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flex: 1,
  },
}));

const SectionTypes = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDataLoading, setDataLoadingStatus] = useState(true);
  const [sectionTypes, setSectionTypes] = useState([]);

  useEffect(() => {
    api
      .get("section-types")
      .then((res) => {
        if (res.data.success) {
          setSectionTypes(res.data.data);
          setDataLoadingStatus(false);
        } else {
          setDataLoadingStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setDataLoadingStatus(false);
        dispatch(showError(error.message));
      });
  }, []);

  const navigateToAddNewContent = () => {
    history.push("/section-types/add-new-section-type");
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={6} md={6} xl={6}>
          <Typography variant="h3">Section Types</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          xl={6}
          className={classes.addNewButtonContainer}
        >
          <Button onClick={() => navigateToAddNewContent()}>
            ADD NEW <AddIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.row}>
        {isDataLoading ? (
          <>
            {[1, 2, 3, 4, 5, 6].map((item, index) => (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                key={index.toString()}
              >
                <Card key={index.toString()}>
                  <CardHeader
                    title={
                      <Typography variant="h6">
                        <Skeleton />
                      </Typography>
                    }
                  />
                  <CardMedia>
                    <Skeleton variant="rect" height={190} />
                  </CardMedia>
                  <CardContent>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </>
        ) : (
          <>
            {sectionTypes.map((item, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={item._id}>
                <Card key={index.toString()}>
                  <CardHeader
                    title={<Typography variant="h6">{item.title}</Typography>}
                  />
                  <Zoom>
                    <img
                      className={classes.media}
                      src={item.image.url ? item.image?.url : IMAGE_PLACEHOLDER}
                      alt="section"
                    />
                  </Zoom>
                  <CardContent>
                    <Typography>{item.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Container>
  );
};

export default SectionTypes;

import { combineReducers } from "redux";
import snackbarReducer from "./snackbarReducer";
import userReducer from "./userReducer";

const rootReducer = combineReducers({
  snackbarReducer,
  userReducer,
});

export default rootReducer;

import { useState } from "react";

const useToken = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem("token");
    return tokenString;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    if (userToken === "" || userToken === null || userToken === undefined) {
      localStorage.removeItem("token");
      setToken("");
    } else {
      localStorage.setItem("token", userToken);
      setToken(userToken);
    }
  };

  return {
    setToken: saveToken,
    token,
  };
};

export default useToken;

import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  FormControl,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import LinkSelector from "./LinkSelector";
import { useFormik } from "formik";
import { menuItemValidationScheme } from "../utils/yupValidationScheme";
import api from "../config/api";
import { useDispatch } from "react-redux";
import { showMessage, showError } from "../actions/snackbarAction";

const FORM_MODE = {
  CREATE_MENU: "CREATE_MENU",
  EDIT_MENU: "EDIT_MENU",
  CREATE_SUBMENU: "CREATE_SUBMENU",
  EDIT_SUBMENU: "EDIT_SUBMENU",
};

const useStyles = makeStyles((theme) => ({
  formField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const MenuItemDialog = ({
  open,
  handleClose,
  onMenuItemAdd,
  menuItemToEdit,
  parentId,
  formMode,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isSaving, setSavingStatus] = useState(false);
  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    resetForm,
    touched,
    errors,
  } = useFormik({
    enableReinitialize: true,
    initialValues:
      !menuItemToEdit ||
      formMode === FORM_MODE.CREATE_MENU ||
      formMode === FORM_MODE.CREATE_SUBMENU
        ? {
            title: "",
            link: null,
            isPrivate: false,
          }
        : menuItemToEdit,
    onSubmit: (values) => onSave(values),
    validationSchema: menuItemValidationScheme,
  });

  const onSave = () => {
    if (formMode === FORM_MODE.CREATE_MENU) {
      createMenu();
    } else if (formMode === FORM_MODE.CREATE_SUBMENU) {
      createSubmenu();
    } else if (formMode === FORM_MODE.EDIT_MENU && !parentId) {
      editMenu();
    } else if (formMode === FORM_MODE.EDIT_MENU && parentId) {
      editSubmenu();
    }
  };

  const createMenu = () => {
    setSavingStatus(true);
    api
      .post("menu", {
        title: values.title,
        link: values.link,
        isPrivate: values.isPrivate,
      })
      .then((res) => {
        setSavingStatus(false);
        if (res.data.success) {
          onMenuItemAdd(res.data.data);
          dispatch(showMessage(res.data.message));
          resetForm();
          handleClose();
        } else {
          setSavingStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setSavingStatus(false);
        dispatch(showError(error.message));
      });
  };

  const createSubmenu = () => {
    setSavingStatus(true);
    api
      .post(`menu/${menuItemToEdit._id}/submenu`, {
        title: values.title,
        link: values.link,
        isPrivate: values.isPrivate,
      })
      .then((res) => {
        setSavingStatus(false);
        if (res.data.success) {
          onMenuItemAdd(res.data.data);
          dispatch(showMessage(res.data.message));
          resetForm();
          handleClose();
        } else {
          setSavingStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setSavingStatus(false);
        dispatch(showError(error.message));
      });
  };

  const editMenu = () => {
    setSavingStatus(true);
    api
      .put(`menu/${values._id}`, {
        title: values.title,
        link: values.link,
        isPrivate: values.isPrivate,
      })
      .then((res) => {
        setSavingStatus(false);
        if (res.data.success) {
          onMenuItemAdd(res.data.data);
          dispatch(showMessage(res.data.message));
          resetForm();
          handleClose();
        } else {
          setSavingStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setSavingStatus(false);
        dispatch(showError(error.message));
      });
  };

  const editSubmenu = () => {
    setSavingStatus(true);
    api
      .put(`menu/${parentId}/submenu/${values._id}`, {
        title: values.title,
        link: values.link,
        isPrivate: values.isPrivate,
      })
      .then((res) => {
        setSavingStatus(false);
        if (res.data.success) {
          onMenuItemAdd(res.data.data);
          dispatch(showMessage(res.data.message));
          resetForm();
          handleClose();
        } else {
          setSavingStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setSavingStatus(false);
        dispatch(showError(error.message));
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      keepMounted={false}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent dividers>
        <FormControl className={classes.formField} fullWidth>
          <TextField
            label="Title"
            variant="outlined"
            value={values.title}
            name="title"
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              touched.title && errors.title
                ? errors.title
                : "Title of the menu item"
            }
            error={touched.title && errors.title}
          />
        </FormControl>
        <LinkSelector
          preSelectedLink={values.link}
          onScreenSelect={(screen) =>
            setFieldValue("link", { ...values.link, screen })
          }
          onParamSelect={(param) =>
            setFieldValue("link", { ...values.link, param })
          }
        />
        <FormGroup className={classes.formField}>
          <FormControlLabel
            label="Display to only logged-in users"
            control={
              <Checkbox
                checked={values.isPrivate}
                color="primary"
                name="isPrivate"
                onChange={(e) => {
                  setFieldValue("isPrivate", e.target.checked);
                }}
                onBlur={handleBlur}
              />
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          CANCEL
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={isSaving}
        >
          {isSaving ? (
            <>
              Saving
              <CircularProgress size={18} />
            </>
          ) : (
            "Save"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MenuItemDialog;

import * as yup from "yup";

export const addNewSectionTypeValidationScheme = yup.object().shape({
  title: yup.string().required(),
  key: yup.string().required(),
  description: yup.string().required(),
  image: yup.mixed(),
});

export const addAnnouncementBarValidationScheme = yup.object().shape({
  homepageOnly: yup.boolean(),
  text: yup.string().required("Please enter some message"),
  barBackgroundColor: yup.mixed().required(),
  barTextColor: yup.mixed().required(),
  link: yup.mixed(),
  button: yup.mixed(),
  visibility: yup.boolean().required(),
});

export const featuredCategoriesValidationScheme = yup.object().shape({
  heading: yup.mixed(),
  subHeading: yup.mixed(),
  collections: yup.mixed(),
  button: yup.mixed(),
  extraStyle: yup.mixed(),
  visibility: yup.boolean().required(),
});

export const addSimpleParagraphValidationScheme = yup.object().shape({
  heading: yup.mixed(),
  subHeading: yup.mixed(),
  text: yup.string().required("Text cannot be blank"),
  button: yup.mixed(),
  visibility: yup.boolean().required(),
});

export const addGiftCardValidationScheme = yup.object().shape({
  heading: yup.mixed(),
  subHeading: yup.mixed(),
  button: yup.mixed(),
  image: yup.mixed(),
  link: yup.mixed(),
  extraStyle: yup.mixed(),
  visibility: yup.boolean(),
});

export const imageWithTextValidationScheme = yup.object().shape({
  heading: yup.mixed(),
  subHeading: yup.mixed(),
  text: yup.string().required("Please enter some text"),
  button: yup.mixed(),
  image: yup.mixed(),
  imageAlignment: yup.string(),
  link: yup.mixed(),
  extraStyle: yup.mixed(),
  visibility: yup.boolean(),
});

export const heroImageValidationScheme = yup.object().shape({
  heading: yup.mixed(),
  subHeading: yup.mixed(),
  text: yup.string(),
  icon: yup.mixed(),
  button: yup.mixed(),
  image: yup.mixed(),
  imageLink: yup.mixed(),
  visibility: yup.boolean(),
});

export const imageWithTextOverlayValidationScheme = yup.object().shape({
  heading: yup.mixed(),
  subHeading: yup.mixed(),
  text: yup.string().required("Please enter some text"),
  button: yup.mixed(),
  image: yup.mixed(),
  imageAlignment: yup.string(),
  visibility: yup.boolean(),
});

export const simpleImageValidationScheme = yup.object().shape({
  heading: yup.mixed(),
  subHeading: yup.mixed(),
  text: yup.string(),
  button: yup.mixed(),
  image: yup.mixed(),
  imageLink: yup.mixed(),
  extraStyle: yup.mixed(),
  visibility: yup.boolean(),
});

export const cardValidationScheme = yup.object().shape({
  cardType: yup.string(),
  heading: yup.string().required(),
  buttonLabel: yup.string().required(),
  image: yup.mixed().required(),
  buttonLink: yup.mixed(),
  visibility: yup.boolean(),
});

export const pushNotificationValidationScheme = yup.object().shape({
  target: yup.mixed(),
  title: yup.string().required("Title is required"),
  message: yup.string().required("Message is required"),
  image: yup.mixed(),
  link: yup.mixed(),
});

export const buttonValidationScheme = yup.object().shape({
  buttonText: yup.string().required("Button text is required"),
  buttonTextColor: yup.string(),
  buttonBackgroundColor: yup.string(),
  buttonLink: yup.mixed(),
});

export const loginValidationScheme = yup.object().shape({
  email: yup
    .string()
    .required("Please enter email")
    .email("Please enter valid email"),
  password: yup.string().required("Please enter password"),
});

export const signUpValidationScheme = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .required("Email is required")
    .email("Please enter valid email"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be 8 character long"),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export const changePasswordValidationScheme = yup.object().shape({
  password: yup.string().required("Password is required"),
  newPassword: yup.string().required("New password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("newPassword"), null], "Confirm Password must match"),
});

export const resetPasswordValidationScheme = yup.object().shape({
  password: yup.string().required("Password is required"),
  confirmPassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password"), null], "Confirm Password must match"),
});

export const menuItemValidationScheme = yup.object().shape({
  title: yup.string().required("Please enter title"),
  link: yup.mixed(),
});

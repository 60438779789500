import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Checkbox,
  Backdrop,
  CircularProgress,
  Card,
  CardContent,
  ButtonBase,
  IconButton,
  makeStyles,
  Box,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import {
  GET_COLLECTIONS_WITH_IMAGE,
  GET_COLLECTIONS_BY_IDS,
} from "../shopifyStorefrontQueries/queries/collection";
import { IMAGE_PLACEHOLDER } from "../config/data";
import SearchBar from "material-ui-search-bar";

const useStyle = makeStyles((theme) => ({
  selectButton: {
    width: "100%",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderWidth: 1,
    borderStyle: "dashed",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer,
    color: "#fff",
    position: "absolute",
    top: 0,
    bottom: 0,
    height: "100%",
  },
}));

const LoadingPlaceholder = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <ListItem key={i.toString()} button divider>
          <ListItemAvatar>
            <Skeleton height={50}>
              <Avatar />
            </Skeleton>
          </ListItemAvatar>
          <Skeleton variant="text" width={300} />
        </ListItem>
      ))}
    </>
  );
};

const CollectionsSelector = ({
  preSelectedCollections,
  onCollectionsSelect,
}) => {
  const classes = useStyle();
  const [searchQuery, setSearchQuery] = useState("");
  const { loading, data, fetchMore, refetch } = useQuery(
    GET_COLLECTIONS_WITH_IMAGE,
    {
      variables: {
        query: searchQuery !== "" ? `title:*${searchQuery}*` : "",
      },
      onCompleted: (result) => {
        console.log(result);
      },
    }
  );
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedCollectionIds, setSelectedCollectionIds] = useState([]);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [isOpenDialog, setIsOpenDialogStatus] = useState(false);
  const getCollectionsByIdsQuery = useQuery(GET_COLLECTIONS_BY_IDS, {
    variables: {
      collectionIds: selectedCollectionIds,
    },
    onCompleted: (result) => {
      if (result?.nodes) {
        setSelectedCollections(result.nodes);
      }
    },
  });

  useEffect(() => {
    if (preSelectedCollections) {
      setSelectedCollectionIds(preSelectedCollections);
    }
  }, [preSelectedCollections]);

  const openDialog = () => {
    setIsOpenDialogStatus(true);
  };

  const closeDialog = () => {
    setIsOpenDialogStatus(false);
  };

  const loadNextPage = () => {
    if (data.collections?.pageInfo?.hasNextPage && !isNextPageLoading) {
      setIsNextPageLoading(true);
      fetchMore({
        variables: {
          cursor:
            data.collections.edges[data.collections.edges.length - 1]?.cursor ||
            null,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          setIsNextPageLoading(false);
          const newEdges = fetchMoreResult.collections.edges;
          const newPageInfo = fetchMoreResult.collections.pageInfo;
          return newEdges.length
            ? {
                collections: {
                  edges: [...prevResult.collections.edges, ...newEdges],
                  pageInfo: newPageInfo,
                  __typename: prevResult.collections.__typename,
                },
              }
            : prevResult;
        },
      });
    }
  };

  const handleToggle = (collectionId) => {
    const currentIndex = selectedCollectionIds.indexOf(collectionId);
    const newChecked = [...selectedCollectionIds];

    if (currentIndex === -1) {
      newChecked.push(collectionId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedCollectionIds(newChecked);
  };

  const onSearch = () => {
    if (searchQuery !== "") {
      refetch({
        query: `*${searchQuery}*`,
      });
    } else {
      refetch({ query: "" });
    }
  };

  const onNext = () => {
    onCollectionsSelect(selectedCollectionIds);
    closeDialog();
  };

  const onRemoveSelectedCollection = (collectionId) => {
    setSelectedCollectionIds(
      selectedCollectionIds.filter((id) => id !== collectionId)
    );
    onCollectionsSelect(
      selectedCollectionIds.filter((id) => id !== collectionId)
    );
  };

  return (
    <Box
      style={{ marginTop: 20, marginBottom: 20 }}
      border={1}
      borderColor="grey.700"
      borderRadius="borderRadius"
      padding={1}
    >
      <Typography variant="subtitle1">Collections</Typography>
      <Card style={{ marginBottom: 10 }}>
        <CardContent style={{ position: "relative" }}>
          <List
            style={{
              width: "100%",
              position: "relative",
              overflow: "auto",
              maxHeight: 300,
            }}
          >
            {selectedCollections.length === 0 ? (
              <Typography>Please Select Collections</Typography>
            ) : (
              selectedCollections
                .filter((collection) => collection !== null)
                .map((collection, index) => {
                  const labelId = `checkbox-list-secondary-label-${collection.id}`;
                  return (
                    <ListItem key={collection.id} button divider>
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            height: 50,
                            width: 50,
                            marginRight: 10,
                          }}
                          variant="square"
                          alt={
                            collection.image
                              ? collection.image.altText
                              : "no_image"
                          }
                          src={
                            collection.image
                              ? collection.image.originalSrc
                              : IMAGE_PLACEHOLDER
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={collection.title} />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Remove this collection"
                          onClick={() =>
                            onRemoveSelectedCollection(collection.id)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
            )}
          </List>
          <Backdrop
            open={getCollectionsByIdsQuery.loading}
            className={classes.backdrop}
          >
            <CircularProgress />
          </Backdrop>
        </CardContent>
      </Card>
      <ButtonBase className={classes.selectButton} onClick={openDialog}>
        <Typography>
          {preSelectedCollections?.length > 0
            ? "EDIT COLLECTIONS"
            : "SELECT COLLECTION"}
        </Typography>
      </ButtonBase>
      <Dialog
        open={isOpenDialog}
        onClose={closeDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant="h6">Select Collections</Typography>
          <SearchBar
            value={searchQuery}
            onChange={(newValue) => setSearchQuery(newValue)}
            onRequestSearch={onSearch}
            onCancelSearch={() => {
              setSearchQuery("");
              refetch({ query: "" });
            }}
          />
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <List dense>
            {loading ? (
              <LoadingPlaceholder />
            ) : (
              data?.collections?.edges
                .filter((edge) => edge !== null)
                .map((collection) => {
                  const labelId = `checkbox-list-secondary-label-${collection.node.id}`;
                  return (
                    <ListItem
                      key={collection.node.id}
                      button
                      divider
                      onClick={() => handleToggle(collection.node.id)}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{ height: 50, width: 50, marginRight: 10 }}
                          variant="square"
                          alt={
                            collection?.node?.image
                              ? collection.node?.image?.altText
                              : "no_image"
                          }
                          src={
                            collection?.node?.image
                              ? collection.node.image?.transformedSrc
                              : IMAGE_PLACEHOLDER
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        primary={collection.node.title}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={() => handleToggle(collection.node.id)}
                          checked={
                            selectedCollectionIds.indexOf(
                              collection.node.id
                            ) !== -1
                          }
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
            )}
            {data && data.collections?.pageInfo?.hasNextPage && (
              <ListItem
                button
                divider
                onClick={() => loadNextPage()}
                style={{
                  justifyContent: "center",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {isNextPageLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  <Typography>LOAD MORE</Typography>
                )}
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary" variant="contained">
            CANCEL
          </Button>
          <Button
            onClick={onNext}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            NEXT
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CollectionsSelector;

import React from "react";
import { Paper, FormGroup, FormControlLabel, Switch } from "@material-ui/core";

const VisibilityCreator = ({ visibility, onVisibilityChange }) => {
  return (
    <Paper
      variant="outlined"
      style={{ marginTop: 10, marginBottom: 10, padding: 10 }}
    >
      <FormGroup row>
        <FormControlLabel
          label="Visibility"
          control={
            <Switch
              checked={visibility}
              value={visibility}
              color="primary"
              onChange={(e) => {
                onVisibilityChange(e.target.checked);
              }}
            />
          }
          labelPlacement="start"
        />
      </FormGroup>
    </Paper>
  );
};

export default VisibilityCreator;

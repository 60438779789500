import imagePlaceholder from "../assets/images/image-placeholder.png";
import sideMenuIcon from "../assets/images/side-menu.png";

export const IMAGE_PLACEHOLDER = imagePlaceholder;
export const SIDE_MENU_ICON = sideMenuIcon;

export const EXTRA_DATA = ["one", "tow", "thre"];

export const DEFAULT_FONT_SIZE = {
  HEADING: 38,
  SUB_HEADING: 34,
  TEXT: 18,
};

export const BASE_FONTS = [
  "Marmelad-Regular",
  "Philosopher-Bold",
  "Satisfy-Regular",
];

export const DEFAULT_FONT = {
  HEADING: "Marmelad-Regular",
  SUB_HEADING: "Philosopher-Bold",
  TEXT: "PTSans-Regular",
};

export const DEFAULT_COLOR = {
  SIMPLE_BACKGROUND: {
    value: "rgba(255, 255, 255, 1)",
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
  },
  SIMPLE_TEXT: {
    value: "rgba(0, 0, 0, 1)",
    rgba: {
      r: 0,
      g: 0,
      b: 0,
      a: 1,
    },
  },
  BUTTON_BACKGROUND: {
    value: "rgba(218, 56, 126, 1)",
    rgba: {
      r: 218,
      g: 56,
      b: 126,
      a: 1,
    },
  },
  BUTTON_TEXT: {
    value: "rgba(255, 255, 255, 1)",
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
  },
  PRIMARY_BACKGROUND: {
    value: "rgba(218, 56, 126, 1)",
    rgba: {
      r: 218,
      g: 56,
      b: 126,
      a: 1,
    },
  },
  PRIMARY_TEXT: {
    value: "rgba(255, 255, 255, 1)",
    rgba: {
      r: 255,
      g: 255,
      b: 255,
      a: 1,
    },
  },
  HEADING: {
    value: "rgba(18, 18, 19, 1)",
    rgba: {
      r: 18,
      g: 18,
      b: 19,
      a: 1,
    },
  },
  SUB_HEADING: {
    value: "rgba(18, 18, 19, 1)",
    rgba: {
      r: 18,
      g: 18,
      b: 19,
      a: 1,
    },
  },
};

export const PRESET_COLORS = [
  "#da387e",
  "#dadada",
  "#e8e9eb",
  "#868781",
  "#070706",
  "#414141",
  "#000000",
  "#121213",
  "#557b97",
  "#ffffff",
];

import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Paper,
  Box,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import InputFieldColorPicker from "./InputFieldColorPicker";
import {
  DEFAULT_COLOR,
  DEFAULT_FONT_SIZE,
  BASE_FONTS,
  DEFAULT_FONT,
} from "../config/data";

const TitleCreator = ({
  heading,
  onHeadingChange,
  subHeading,
  onSubHeadingChange,
  headingDivider,
  onHeadingDividerChange = () => {},
}) => {
  const [headingText, setHeadingText] = useState("");
  const [headingFontSize, setHeadingFontSize] = useState(
    DEFAULT_FONT_SIZE.HEADING
  );
  const [headingTextColor, setHeadingTextColor] = useState(
    DEFAULT_COLOR.HEADING
  );
  const [headingFontFamily, setHeadingFontFamily] = useState(
    DEFAULT_FONT.HEADING
  );
  const [headingTextAlignment, setHeadingTextAlignment] = useState("center");

  const [subHeadingText, setSubHeadingText] = useState("");
  const [subHeadingFontSize, setSubHeadingFontSize] = useState(
    DEFAULT_FONT_SIZE.SUB_HEADING
  );
  const [subHeadingTextColor, setSubHeadingTextColor] = useState(
    DEFAULT_COLOR.SUB_HEADING
  );
  const [subHeadingFontFamily, setSubHeadingFontFamily] = useState(
    DEFAULT_FONT.SUB_HEADING
  );
  const [subHeadingTextAlignment, setSubHeadingTextAlignment] = useState(
    "center"
  );

  const [displayDividerStatus, setDisplayDividerStatus] = useState(true);
  const [headingDividerAlignment, setHeadingDividerAlignment] = useState(
    "center"
  );

  useEffect(() => {
    if (heading) {
      onHeadingChange(heading);
    } else {
      onHeadingChange({
        text: headingText,
        style: {
          fontSize: headingFontSize,
          color: headingTextColor,
          fontFamily: headingFontFamily,
          textAlign: headingTextAlignment,
        },
      });
    }

    if (subHeading) {
      onSubHeadingChange(subHeading);
    } else {
      onSubHeadingChange({
        text: subHeadingText,
        style: {
          fontSize: subHeadingFontSize,
          color: subHeadingTextColor,
          fontFamily: subHeadingFontFamily,
          textAlign: subHeadingTextAlignment,
        },
      });
    }

    if (headingDivider) {
      onHeadingDividerChange(headingDivider);
    } else {
      onHeadingDividerChange({
        display: displayDividerStatus,
        style: {
          alignItems: headingDividerAlignment,
        },
        type: "default",
        divider: "",
      });
    }
  }, []);

  useEffect(() => {
    if (heading?.text) {
      setHeadingText(heading?.text || "");
    }
    if (heading?.style) {
      setHeadingFontSize(heading?.style?.fontSize || DEFAULT_FONT_SIZE.HEADING);
      setHeadingTextColor(heading?.style?.color || DEFAULT_COLOR.HEADING);
      setHeadingFontFamily(heading?.style?.fontFamily || DEFAULT_FONT.HEADING);
      setHeadingTextAlignment(heading?.style?.textAlign || "center");
    }
  }, [heading]);

  useEffect(() => {
    if (subHeading?.text) {
      setSubHeadingText(subHeading?.text || "");
    }
    if (subHeading?.style) {
      setSubHeadingFontSize(
        subHeading?.style?.fontSize || DEFAULT_FONT_SIZE.SUB_HEADING
      );
      setSubHeadingTextColor(
        subHeading?.style?.color || DEFAULT_COLOR.SUB_HEADING
      );
      setSubHeadingFontFamily(
        subHeading?.style?.fontFamily || DEFAULT_FONT.SUB_HEADING
      );
      setSubHeadingTextAlignment(subHeading?.style?.textAlign || "center");
    }
  }, [subHeading]);

  useEffect(() => {
    if (headingDivider) {
      setDisplayDividerStatus(headingDivider?.display);
    }
    if (headingDivider && headingDivider?.style) {
      setHeadingDividerAlignment(headingDivider?.style?.alignItems || "center");
    }
  }, [headingDivider]);

  const onHeadingBlur = () => {
    onHeadingChange({
      text: headingText,
      style: {
        fontSize: headingFontSize,
        color: headingTextColor,
        fontFamily: headingFontFamily,
        textAlign: headingTextAlignment,
      },
    });
  };

  const onSubHeadingBlur = () => {
    onSubHeadingChange({
      text: subHeadingText,
      style: {
        fontSize: subHeadingFontSize,
        color: subHeadingTextColor,
        fontFamily: subHeadingFontFamily,
        textAlign: subHeadingTextAlignment,
      },
    });
  };

  const onHeadingDividerFieldChange = () => {
    onHeadingDividerChange({
      display: displayDividerStatus,
      style: {
        alignItems: headingDividerAlignment,
      },
      type: "default",
      divider: null,
    });
  };

  return (
    <Box marginY={2}>
      <Paper variant="outlined" style={{ padding: 10 }}>
        <Grid container spacing={2} alignItems="center" wrap="wrap">
          <Grid item md={12} sm={12} xs={12} lg={12} xl={12}>
            <FormControl fullWidth>
              <TextField
                label="Heading"
                value={headingText}
                type="text"
                fullWidth
                variant="outlined"
                placeholder="Heading"
                onChange={(e) => setHeadingText(e.target.value)}
                onBlur={() => onHeadingBlur()}
                style={{ minWidth: 100 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InputFieldColorPicker
                        selectedColor={
                          headingTextColor.rgba || DEFAULT_COLOR.HEADING.rgba
                        }
                        onSelect={(color) => {
                          setHeadingTextColor(color);
                          onHeadingChange({
                            ...heading,
                            style: { ...heading?.style, color: color },
                          });
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Font Size"
                type="number"
                variant="outlined"
                defaultValue={32}
                value={headingFontSize}
                onChange={(e) =>
                  setHeadingFontSize(parseInt(e.target.value, 10))
                }
                onBlur={() => onHeadingBlur()}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="fontLabel" variant="outlined">
                Font
              </InputLabel>
              <Select
                label="Font"
                labelId="fontLabel"
                value={headingFontFamily || DEFAULT_FONT.HEADING}
                variant="outlined"
                onChange={(e) => setHeadingFontFamily(e.target.value)}
                onBlur={() => onHeadingBlur()}
              >
                {BASE_FONTS.map((item, index) => (
                  <MenuItem title={item} value={item} key={index.toString()}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="textAlignLabel" variant="outlined">
                Text Align
              </InputLabel>
              <Select
                label="Text Align"
                labelId="textAlignLabel"
                value={headingTextAlignment || "center"}
                variant="outlined"
                onChange={(e) => setHeadingTextAlignment(e.target.value)}
                onBlur={() => onHeadingBlur()}
              >
                <MenuItem title="left" value="left">
                  Left
                </MenuItem>
                <MenuItem title="center" value="center">
                  Center
                </MenuItem>
                <MenuItem title="right" value="right">
                  Right
                </MenuItem>
                <MenuItem title="justify" value="justify">
                  Justify
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Paper variant="outlined" style={{ padding: 10, marginTop: 20 }}>
        <Grid container spacing={2} alignItems="center" wrap="wrap">
          <Grid item md={12} sm={12} xs={12} lg={12} xl={12}>
            <FormControl fullWidth>
              <TextField
                label="Sub Heading"
                value={subHeadingText}
                type="text"
                fullWidth
                variant="outlined"
                placeholder="SubHeading"
                onChange={(e) => setSubHeadingText(e.target.value)}
                onBlur={() => onSubHeadingBlur()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InputFieldColorPicker
                        selectedColor={
                          subHeadingTextColor.rgba ||
                          DEFAULT_COLOR.SUB_HEADING.rgba
                        }
                        onSelect={(color) => {
                          setSubHeadingTextColor(color);
                          onSubHeadingChange({
                            ...subHeading,
                            style: { ...subHeading?.style, color: color },
                          });
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <TextField
                label="Font Size"
                type="number"
                variant="outlined"
                defaultValue={28}
                value={subHeadingFontSize}
                onChange={(e) =>
                  setSubHeadingFontSize(parseInt(e.target.value, 10))
                }
                onBlur={() => onSubHeadingBlur()}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="fontLabel" variant="outlined">
                Font
              </InputLabel>
              <Select
                label="Font"
                labelId="fontLabel"
                value={subHeadingFontFamily || DEFAULT_FONT.SUB_HEADING}
                variant="outlined"
                onChange={(e) => setSubHeadingFontFamily(e.target.value)}
                onBlur={() => onSubHeadingBlur()}
              >
                {BASE_FONTS.map((item, index) => (
                  <MenuItem title={item} value={item} key={index.toString()}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id="textAlignLabel" variant="outlined">
                Text Align
              </InputLabel>
              <Select
                label="Text Align"
                labelId="textAlignLabel"
                value={subHeadingTextAlignment || "center"}
                variant="outlined"
                onChange={(e) => setSubHeadingTextAlignment(e.target.value)}
                onBlur={() => onSubHeadingBlur()}
              >
                <MenuItem title="left" value="left">
                  Left
                </MenuItem>
                <MenuItem title="center" value="center">
                  Center
                </MenuItem>
                <MenuItem title="right" value="right">
                  Right
                </MenuItem>
                <MenuItem title="justify" value="justify">
                  Justify
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Paper variant="outlined" style={{ padding: 10, marginTop: 20 }}>
        <FormGroup>
          <FormControlLabel
            label="Display Heading Divider"
            control={
              <Checkbox
                checked={displayDividerStatus}
                color="primary"
                name="displayDivider"
                onChange={(e) => {
                  setDisplayDividerStatus(e.target.checked);
                }}
                onBlur={() => onHeadingDividerFieldChange()}
              />
            }
          />
        </FormGroup>
        <FormControl fullWidth style={{ marginTop: 10 }}>
          <InputLabel id="dividerAlignmentLabel" variant="outlined">
            Divider Alignment
          </InputLabel>
          <Select
            label="Divider Alignment"
            labelId="dividerAlignmentLabel"
            value={headingDividerAlignment || "center"}
            variant="outlined"
            onChange={(e) => setHeadingDividerAlignment(e.target.value)}
            onBlur={() => onHeadingDividerFieldChange()}
          >
            <MenuItem title="left" value="flex-start">
              Left
            </MenuItem>
            <MenuItem title="center" value="center">
              Center
            </MenuItem>
            <MenuItem title="right" value="flex-end">
              Right
            </MenuItem>
          </Select>
        </FormControl>
      </Paper>
    </Box>
  );
};

export default TitleCreator;

import { ApolloClient } from "@apollo/client";
import {
  IntrospectionFragmentMatcher,
  InMemoryCache,
} from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

// Live
const shopifyApolloClient = new ApolloClient({
  uri: "https://southern-honey-boutique.myshopify.com/api/2021-01/graphql.json",
  headers: {
    "X-Shopify-Storefront-Access-Token": "e502d25e41959900f1d35b2ad5092f67",
    "Content-Type": "application/graphql",
  },
  cache: new InMemoryCache({ fragmentMatcher }),
});

// Stage
// const shopifyApolloClient = new ApolloClient({
//   uri: "https://proj-southernhoney.myshopify.com/api/2021-01/graphql.json",
//   headers: {
//     "X-Shopify-Storefront-Access-Token": "1d8d5a775a33d8df9c0e82c5b3e59741",
//     "Content-Type": "application/graphql",
//   },
//   cache: new InMemoryCache({ fragmentMatcher }),
// });

export default shopifyApolloClient;

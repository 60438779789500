import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Card,
  CardContent,
  ButtonBase,
  CardMedia,
  FormControlLabel,
  InputLabel,
  FormGroup,
  FormControl,
  Checkbox,
  Button,
  Select,
  MenuItem,
  Paper,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AddCircleOutlineSharp } from "@material-ui/icons";
import AddSlideDialog from "./AddSlideDialog";
import { showError, showMessage } from "../../actions/snackbarAction";
import api from "../../config/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { pageFormMode } from "../../contants/others";
import { IMAGE_PLACEHOLDER } from "../../config/data";
import HtmlField from "../../components/HtmlField";
import FormNote from "../../components/FormNote";
import SectionCommonStyleCreator from "../../components/SectionCommonStyleCreator";
import VisibilityCreator from "../../components/VisibilityCreator";
import ButtonField from "../../components/ButtonField";
import TitleCreator from "../../components/TitleCreator";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  formField: {
    marginTop: 10,
    marginBottom: 10,
  },
  createSlideContainer: {
    borderWidth: 1,
    borderStyle: "dashed",
    height: 100,
    width: "100%",
  },
  sliderItem: {},
  slideItemImage: {
    height: 200,
    width: "100%",
    backgroundSize: "contain",
  },
}));

const AddSlider = (props) => {
  const classes = useStyles();
  const [isOpenAddSlideDialog, setAddSlideDialogOpenState] = useState(false);
  const [slides, setSlides] = useState([]);
  const [autoRotate, setAutoRotate] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);
  const [formMode, setFormMode] = useState(pageFormMode.CREATE);
  const [prevData, setPrevFormData] = useState({});
  const [slideToEdit, setSlideToEdit] = useState(null);
  const [visibility, setVisibility] = useState(true);
  const [heading, setHeading] = useState({});
  const [subHeading, setSubHeading] = useState({});
  const [headingDivider, setHeadingDivider] = useState(null);
  const [text, setText] = useState("");
  const [displayNavigator, setDisplayNavigatorStatus] = useState(false);
  const [slidePerPage, setSlidePerPage] = useState("1");
  const [button, setButton] = useState(null);
  const [extraStyle, setExtraStyle] = useState({});

  useEffect(() => {
    const { mode, data } = history.location.state;
    if (mode === pageFormMode.EDIT && data) {
      setFormMode(mode);
      setPrevFormData(data);
      setSlides(data.content);
      setAutoRotate(data.settings.autoRotate);
      setVisibility(data.settings.visibility);
      setHeading(data.settings.heading);
      setSubHeading(data.settings.subHeading);
      setHeadingDivider(data.settings.headingDivider);
      setText(data.settings.text);
      setDisplayNavigatorStatus(data.settings.displayNavigator);
      setSlidePerPage(data.settings.slidePerPage);
      setExtraStyle(data.settings.extraStyle);
      setButton(data.settings.button);
    }
  }, [history.location.state]);

  const openAddSlideDialog = () => {
    setAddSlideDialogOpenState(true);
  };

  const closeAddSlideDialog = () => {
    setAddSlideDialogOpenState(false);
    setSlideToEdit(null);
  };

  const handleOnAddSlide = (data) => {
    const avaIndex = slides.findIndex((item) => item._id === data._id);
    if (avaIndex < 0) {
      setSlides([
        ...slides,
        {
          ...data,
          _id: (slides.length + 1).toString(),
        },
      ]);
    } else {
      const nextSlides = [].concat(slides);
      nextSlides[avaIndex] = data;
      setSlides(nextSlides);
    }
  };

  const onClose = () => {
    history.goBack();
  };

  const onSave = () => {
    setSubmitting(true);
    const { section } = history.location.state;
    const param = {
      title: section.title,
      contentType: section.key,
      content: slides,
      settings: {
        heading,
        subHeading,
        headingDivider,
        text,
        autoRotate,
        displayNavigator,
        slidePerPage,
        extraStyle,
        button,
        visibility,
      },
    };
    if (!formMode || formMode === pageFormMode.CREATE) {
      api
        .post("app-content", param)
        .then((res) => {
          if (res.data.success) {
            setSubmitting(false);
            dispatch(showMessage(res.data.message));
            history.goBack();
          } else {
            setSubmitting(false);
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showError(error.response.data.message));
        });
    } else {
      api
        .put(`app-content/${prevData._id}`, param)
        .then((res) => {
          setSubmitting(false);
          dispatch(showMessage(res.data.message));
          history.goBack();
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showError(error.response.data.message));
        });
    }
  };

  const onAutoRotateChange = (e) => {
    setAutoRotate(e.target.checked);
  };

  const onRemoveSlide = (index) => {
    const remainSlides = [...slides];
    remainSlides.splice(index, 1);
    setSlides(remainSlides);
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h3">
            {history.location.state.data?.settings?.heading?.text
              ? history.location.state.data?.settings?.heading.text
              : history.location.state.section?.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.row}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card variant="outlined">
            <CardContent>
              <FormNote />
              <TitleCreator
                heading={heading}
                onHeadingChange={(changedHeading) => setHeading(changedHeading)}
                subHeading={subHeading}
                onSubHeadingChange={(changedSubHeading) =>
                  setSubHeading(changedSubHeading)
                }
                headingDivider={headingDivider}
                onHeadingDividerChange={(newHeadingDivider) => {
                  setHeadingDivider(newHeadingDivider);
                }}
              />

              <HtmlField
                value={text}
                onChange={setText}
                className={classes.formField}
              />
              <Typography variant="subtitle1">CONTENT</Typography>
              <Grid container spacing={2}>
                {slides.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={3}
                    key={index.toString()}
                  >
                    <Card className={classes.sliderItem}>
                      <CardMedia
                        image={
                          item?.image?.url ? item.image.url : IMAGE_PLACEHOLDER
                        }
                        className={classes.slideItemImage}
                      />
                      <CardContent>
                        <Typography variant="subtitle1">
                          {item.heading?.text}
                        </Typography>
                        <Typography variant="subtitle2">
                          {item.subHeading?.text}
                        </Typography>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{ marginTop: 10 }}
                          onClick={() => {
                            setSlideToEdit(item);
                            openAddSlideDialog();
                          }}
                        >
                          EDIT
                        </Button>
                        <Button
                          fullWidth
                          variant="outlined"
                          style={{ marginTop: 10 }}
                          onClick={() => onRemoveSlide(index)}
                        >
                          REMOVE
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <ButtonBase
                    className={classes.createSlideContainer}
                    onClick={() => {
                      openAddSlideDialog();
                    }}
                  >
                    <AddCircleOutlineSharp style={{ fontSize: 50 }} />
                  </ButtonBase>
                </Grid>
              </Grid>

              <Typography variant="subtitle1" style={{ marginTop: 20 }}>
                SETTINGS
              </Typography>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xz={12} sm={12} md={4} lg={4} xl={4}>
                  <Paper variant="outlined" style={{ paddingLeft: 10 }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={autoRotate}
                            onChange={onAutoRotateChange}
                            color="primary"
                          />
                        }
                        label="Auto Rotate Slides"
                      />
                    </FormGroup>
                  </Paper>
                </Grid>
                <Grid item xz={12} sm={12} md={4} lg={4} xl={4}>
                  <Paper variant="outlined" style={{ paddingLeft: 10 }}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={displayNavigator}
                            onChange={(e) =>
                              setDisplayNavigatorStatus(e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label="Display Navigator"
                      />
                    </FormGroup>
                  </Paper>
                </Grid>
                <Grid item xz={12} sm={12} md={4} lg={4} xl={4}>
                  <FormControl
                    className={classes.formField}
                    variant="outlined"
                    fullWidth
                  >
                    <InputLabel id="slidePerPageLabel">
                      Slide per page
                    </InputLabel>
                    <Select
                      labelId="slidePerPageLabel"
                      label="Slide per page"
                      variant="outlined"
                      value={slidePerPage}
                      onChange={(e) => setSlidePerPage(e.target.value)}
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="auto">Auto</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <ButtonField
                button={button}
                onButtonChange={(changedButton) => setButton(changedButton)}
              />

              <SectionCommonStyleCreator
                extraStyle={extraStyle}
                onExtraStyleChange={(extraStyle) => setExtraStyle(extraStyle)}
              />

              <VisibilityCreator
                visibility={visibility}
                onVisibilityChange={(changedVisibility) => {
                  setVisibility(changedVisibility);
                }}
              />

              <Button
                onClick={onClose}
                color="secondary"
                variant="contained"
                style={{ marginRight: 10 }}
              >
                CANCEL
              </Button>
              <Button
                title="Save"
                variant="contained"
                color="primary"
                onClick={onSave}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving" : "Save"}
                {isSubmitting && (
                  <CircularProgress
                    size={20}
                    color="primary"
                    style={{ marginLeft: 5 }}
                  />
                )}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <AddSlideDialog
        open={isOpenAddSlideDialog}
        handleClose={() => closeAddSlideDialog()}
        onAddSlide={(data) => handleOnAddSlide(data)}
        slideToEdit={slideToEdit}
      />
    </Container>
  );
};

export default AddSlider;

import axios from "axios";

const api = axios.create({
  // baseURL: "http://localhost:3000/api/",
  baseURL: "https://southernhoney-server.herokuapp.com/api/",
  headers: {
    "Content-Type": "Application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

export default api;

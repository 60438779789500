import React, { useState } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  Card,
  CardContent,
  ButtonBase,
  IconButton,
  makeStyles,
  Radio,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import { GET_COLLECTIONS_WITH_IMAGE } from "../shopifyStorefrontQueries/queries/collection";
import { IMAGE_PLACEHOLDER } from "../config/data";
import SearchBar from "material-ui-search-bar";

const useStyle = makeStyles((theme) => ({
  selectButton: {
    width: "100%",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderWidth: 1,
    borderStyle: "dashed",
  },
}));

const LoadingPlaceholder = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <ListItem key={i.toString()} button divider>
          <ListItemAvatar>
            <Skeleton height={50}>
              <Avatar />
            </Skeleton>
          </ListItemAvatar>
          <Skeleton variant="text" width={300} />
        </ListItem>
      ))}
    </>
  );
};

const SingleCollectionSelector = ({
  preSelectedCollection,
  onCollectionSelect,
}) => {
  const classes = useStyle();
  const [searchQuery, setSearchQuery] = useState("");
  const { loading, data, fetchMore, refetch } = useQuery(
    GET_COLLECTIONS_WITH_IMAGE,
    {
      variables: {
        query: searchQuery !== "" ? `title:*${searchQuery}*` : "",
      },
      onCompleted: (result) => {
        console.log(result);
      },
      onError: (apiError) => {
        console.log(apiError);
      },
    }
  );
  const [selectedCollection, setSelectedCollection] = useState(
    preSelectedCollection || null
  );
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [isOpenDialog, setIsOpenDialogStatus] = useState(false);

  const openDialog = () => {
    setIsOpenDialogStatus(true);
  };

  const closeDialog = () => {
    onCollectionSelect(selectedCollection);
    setIsOpenDialogStatus(false);
  };

  const loadNextPage = () => {
    if (data.collections?.pageInfo?.hasNextPage && !isNextPageLoading) {
      setIsNextPageLoading(true);
      fetchMore({
        variables: {
          cursor:
            data.collections.edges[data.collections.edges.length - 1].cursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          setIsNextPageLoading(false);
          const newEdges = fetchMoreResult.collections.edges;
          const newPageInfo = fetchMoreResult.collections.pageInfo;
          return newEdges.length
            ? {
                collections: {
                  edges: [...prevResult.collections.edges, ...newEdges],
                  pageInfo: newPageInfo,
                  __typename: prevResult.collections.__typename,
                },
              }
            : prevResult;
        },
      });
    }
  };

  const handleToggle = (collection) => {
    setSelectedCollection(collection);
  };

  const onSearch = () => {
    if (searchQuery !== "") {
      refetch({
        query: `*${searchQuery}*`,
      });
    } else {
      refetch({ query: "" });
    }
  };

  const removeCollection = () => {
    setSelectedCollection(null);
    onCollectionSelect(null);
  };

  return (
    <>
      <Card style={{ marginTop: 10, marginBottom: 10 }}>
        <CardContent>
          <List
            style={{
              width: "100%",
              position: "relative",
              overflow: "auto",
              maxHeight: 300,
            }}
          >
            {!selectedCollection || selectedCollection === null ? (
              <Typography>Please Select Collection</Typography>
            ) : (
              <ListItem button divider>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      height: 50,
                      width: 50,
                      marginRight: 10,
                    }}
                    variant="square"
                    alt={
                      selectedCollection.image
                        ? selectedCollection.image.altText
                        : "no_image"
                    }
                    src={
                      selectedCollection.image
                        ? selectedCollection.image.transformedSrc
                        : IMAGE_PLACEHOLDER
                    }
                  />
                </ListItemAvatar>
                <ListItemText id={"Title"} primary={selectedCollection.title} />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Remove this collection"
                    onClick={() => removeCollection()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
      <ButtonBase className={classes.selectButton} onClick={openDialog}>
        <Typography>
          {selectedCollection ? "CHANGE COLLECTION" : "SELECT COLLECTION"}
        </Typography>
      </ButtonBase>
      <Dialog
        open={isOpenDialog}
        onClose={closeDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant="h6">Select Collections</Typography>
          <SearchBar
            value={searchQuery}
            onChange={(newValue) => setSearchQuery(newValue)}
            onRequestSearch={onSearch}
            onCancelSearch={() => {
              setSearchQuery("");
              refetch({ query: "" });
            }}
          />
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <List dense>
            {loading || !data || !data.collections ? (
              <LoadingPlaceholder />
            ) : (
              data.collections.edges
                .filter((edge) => edge !== null)
                .map((collection) => {
                  const labelId = `checkbox-list-secondary-label-${collection.node.id}`;
                  return (
                    <ListItem
                      key={collection.node.id}
                      button
                      divider
                      onClick={() => handleToggle(collection.node)}
                      selected={selectedCollection?.id === collection.node.id}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{ height: 50, width: 50, marginRight: 10 }}
                          variant="square"
                          alt={
                            collection?.node?.image
                              ? collection?.node?.image?.altText
                              : "no_image"
                          }
                          src={
                            collection.node?.image
                              ? collection?.node?.image?.transformedSrc
                              : IMAGE_PLACEHOLDER
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        primary={collection.node.title}
                      />
                      <ListItemSecondaryAction>
                        <Radio
                          checked={
                            selectedCollection?.id === collection?.node?.id
                          }
                          onChange={() => handleToggle(collection.node)}
                          value={collection?.node?.id}
                          color="primary"
                          name="selected-collection"
                          inputProps={{ "aria-label": "D" }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
            )}
            {data && data.collections?.pageInfo?.hasNextPage && (
              <ListItem
                button
                divider
                onClick={() => loadNextPage()}
                style={{
                  justifyContent: "center",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {isNextPageLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  <Typography>LOAD MORE</Typography>
                )}
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="contained">
            DONE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SingleCollectionSelector;

import { gql } from "@apollo/client";

export const GET_PRODUCTS_LIST = gql`
  query getProductsList($cursor: String, $query: String) {
    products(first: 20, after: $cursor, query: $query) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          handle
          images(first: 1) {
            edges {
              node {
                id
                altText
                transformedSrc(maxHeight: 200, maxWidth: 200)
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCTS_BY_IDS = gql`
  query getProductsByIds($ids: [ID!]!) {
    nodes(ids: $ids) {
      ... on Product {
        id
        title
        images(first: 1, maxHeight: 100, maxWidth: 100) {
          edges {
            node {
              id
              originalSrc
              altText
            }
          }
        }
      }
    }
  }
`;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { closeError } from "../actions/snackbarAction";

const ErrorSnackbar = () => {
  const dispatch = useDispatch();

  const { errorSnackbarMessage, errorSnackbarOpen } = useSelector(
    (state) => state.snackbarReducer
  );

  const handleClose = () => {
    dispatch(closeError());
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={errorSnackbarOpen}
      onClose={handleClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity="error"
      >
        {errorSnackbarMessage}
      </MuiAlert>
    </Snackbar>
  );
};

export default ErrorSnackbar;

import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ButtonBase,
  Typography,
} from "@material-ui/core";
import HtmlField from "./HtmlField";
import ButtonField from "./ButtonField";
import SectionCommonStyleCreator from "./SectionCommonStyleCreator";
import TitleCreator from "./TitleCreator";
import { useFormik } from "formik";

const ParagraphCreator = ({
  initialallyOpen,
  onDelete,
  onParagraphCreate,
  preData,
}) => {
  const [isOpen, setOpenStatus] = useState(initialallyOpen || false);
  const [textData, setTextData] = useState();
  const [text, setText] = useState("");

  const { values, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: !textData
      ? {
          heading: {},
          subHeading: {},
          headingDivider: null,
          button: null,
          extraStyle: null,
        }
      : textData,
  });

  useEffect(() => {
    if (preData) {
      setTextData(preData);
      setText(preData.text);
    }
  }, [preData, isOpen]);

  const openDialog = () => {
    setOpenStatus(true);
  };

  const closeDialog = () => {
    setOpenStatus(false);
  };

  const onSave = () => {
    onParagraphCreate({ ...values, text });
    closeDialog();
  };

  return (
    <>
      <ButtonBase onClick={openDialog} style={{ margin: 10, width: "100%" }}>
        <Typography>{values.heading?.text || "Paragraph"}</Typography>
      </ButtonBase>
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogContent>
          <TitleCreator
            heading={values.heading}
            onHeadingChange={(updatedHeading) =>
              setFieldValue("heading", updatedHeading)
            }
            subHeading={values.subHeading}
            onSubHeadingChange={(updatedSubHeading) =>
              setFieldValue("subHeading", updatedSubHeading)
            }
            headingDivider={values.headingDivider}
            onHeadingDividerChange={(newHd) => {
              setFieldValue("headingDivider", newHd);
            }}
          />
          <HtmlField
            label="Text"
            value={text}
            onChange={(updatedText) => setText(updatedText)}
          />
          <ButtonField
            button={values.button}
            onButtonChange={(button) => setFieldValue("button", button)}
          />
          <SectionCommonStyleCreator
            extraStyle={values.extraStyle}
            onExtraStyleChange={(extraStyle) =>
              setFieldValue("extraStyle", extraStyle)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeDialog} variant="contained">
            CANCEL
          </Button>
          <Button color="primary" onClick={onSave} variant="contained">
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ParagraphCreator;

import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  InputLabel,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  Button,
  IconButton,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import api from "../../config/api";
import { showMessage, showError } from "../../actions/snackbarAction";
import { pageFormMode } from "../../contants/others";
import "react-quill/dist/quill.snow.css";
import ParagraphCreator from "../../components/ParagraphCreator";
import SingleCollectionSelector from "../../components/SingleCollectionSelector";
import SingleProductSelector from "../../components/SingleProductSelector";
import CollectionsSelector from "../../components/CollectionsSelector";
import ProductsSelector from "../../components/ProductsSelector";
import ImageCreator from "../../components/ImageCreator";
import IWTOCreator from "../../components/IWTOCreator";
import ButtonCreator from "../../components/ButtonCreator";
import FormNote from "../../components/FormNote";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";
import SectionCommonStyleCreator from "../../components/SectionCommonStyleCreator";
import VisibilityCreator from "../../components/VisibilityCreator";
import ButtonField from "../../components/ButtonField";
import TitleCreator from "../../components/TitleCreator";

const useStyle = makeStyles((theme) => ({
  formField: {
    marginTop: 10,
    marginBottom: 10,
  },
  btnAddContent: {
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderStyle: "dashed",
    borderWidth: 1,
    marginTop: 10,
    marginBottom: 10,
    "&.MuiInput-underline:before": {
      borderWidth: 0,
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderWidth: 0,
    },
  },
  contentList: {
    borderWidth: 1,
  },
  blockContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  blockItem: {
    padding: 10,
    flex: 1,
  },
  column: {
    flexDirection: "column",
    width: "100%",
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: "stretch",
    justifyContent: "flex-start",
  },
}));

const BLOCK_TYPES = {
  TEXT: "text",
  IMAGE: "image",
  IMAGE_WITH_TEXT_OVERLAY: "imageWithTextOverlay",
  BUTTON: "button",
  SINGLE_PRODUCT: "singleProduct",
  SINGLE_COLLECTION: "singleCollection",
  MULTIPLE_PRODUCT: "multipleProduct",
  MULTIPLE_COLLECTION: "multipleCollection",
};

const BLOCK_LIST = [
  {
    title: "Text",
    type: BLOCK_TYPES.TEXT,
  },
  {
    title: "Image",
    type: BLOCK_TYPES.IMAGE,
  },
  {
    title: "Image With Text Overlay",
    type: BLOCK_TYPES.IMAGE_WITH_TEXT_OVERLAY,
  },
  {
    title: "Button",
    type: BLOCK_TYPES.BUTTON,
  },
  {
    title: "Single Product",
    type: BLOCK_TYPES.SINGLE_PRODUCT,
  },
  {
    title: "Single Collection",
    type: BLOCK_TYPES.SINGLE_COLLECTION,
  },
  {
    title: "Multiple Product",
    type: BLOCK_TYPES.MULTIPLE_PRODUCT,
  },
  {
    title: "Multiple Collection",
    type: BLOCK_TYPES.MULTIPLE_COLLECTION,
  },
];

const DeleteBlockButton = ({ onClick }) => (
  <IconButton
    aria-label="Delete this sub heading"
    onClick={onClick}
    style={{ marginLeft: 10 }}
    color="secondary"
  >
    <DeleteIcon />
  </IconButton>
);

const CustomContent = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formMode, setFormMode] = useState(pageFormMode.CREATE);
  const [prevFormData, setPrevFormData] = useState({});
  const [heading, setHeading] = useState({});
  const [subHeading, setSubHeading] = useState({});
  const [headingDivider, setHeadingDivider] = useState(null);
  const [blocks, setBlocks] = useState([]);
  const [button, setButton] = useState(null);
  const [extraStyle, setExtraStyle] = useState({});
  const [visibility, setVisibility] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    const { mode, data } = history.location.state;
    if (data) {
      setFormMode(mode);
      setPrevFormData(data);
      setHeading(data.settings.heading);
      setSubHeading(data.settings.subHeading);
      setHeadingDivider(data.settings.headingDivider);
      setBlocks(data.content);
      setButton(data.settings.button);
      setExtraStyle(data.settings.extraStyle);
      setVisibility(data.settings.visibility);
    }
  }, [history.location.state]);

  const contentCreate = (content, index) => {
    let avaContent = [...blocks];
    avaContent[index].content = content;
    setBlocks(avaContent);
  };

  const onBack = () => {
    history.goBack();
  };

  const onSave = () => {
    if (blocks.length === 0) {
      dispatch(showError("Please add some content"));
      return;
    }
    const emptyContent = blocks.find((block) => block.content === null);
    if (emptyContent) {
      dispatch(showError(`${emptyContent.title} cannot be empty`));
      return;
    }
    setSubmitting(true);
    const { section } = history.location.state;
    const param = {
      title: section.title,
      contentType: section.key,
      content: blocks,
      settings: {
        heading,
        subHeading,
        headingDivider,
        button,
        extraStyle,
        visibility,
      },
    };
    // console.log(param);
    // return;
    if (!formMode || formMode === pageFormMode.CREATE) {
      api
        .post("app-content", param)
        .then((res) => {
          setSubmitting(false);
          dispatch(showMessage(res.data.message));
          history.goBack();
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showError(error.response.data.message));
        });
    } else {
      api
        .put(`app-content/${prevFormData._id}`, param)
        .then((res) => {
          setSubmitting(false);
          dispatch(showMessage(res.data.message));
          history.goBack();
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showError(error.response.data.message));
        });
    }
  };

  const removeBlock = (index) => {
    let avaBlock = [...blocks];
    avaBlock.splice(index, 1);
    setBlocks(avaBlock);
  };

  const SortableItem = SortableElement(({ value, altIndex }) => {
    switch (value.type) {
      case BLOCK_TYPES.TEXT: {
        return (
          <ListItem divider>
            <DragHandle />
            <Card className={classes.blockItem}>
              <ParagraphCreator
                key={`${altIndex.toString()}_${new Date().toString()}`}
                initialallyOpen={false}
                onParagraphCreate={(paragraph) =>
                  contentCreate(paragraph, altIndex)
                }
                preData={value.content}
              />
            </Card>
            <DeleteBlockButton onClick={() => removeBlock(altIndex)} />
          </ListItem>
        );
      }
      case BLOCK_TYPES.IMAGE: {
        return (
          <ListItem divider>
            <DragHandle />
            <Card className={classes.blockItem}>
              <ImageCreator
                key={`${altIndex.toString()}_${new Date().toString()}`}
                preData={value.content}
                onImageCreate={(image) => contentCreate(image, altIndex)}
              />
            </Card>
            <DeleteBlockButton onClick={() => removeBlock(altIndex)} />
          </ListItem>
        );
      }
      case BLOCK_TYPES.IMAGE_WITH_TEXT_OVERLAY: {
        return (
          <ListItem divider>
            <DragHandle />
            <Card className={classes.blockItem}>
              <IWTOCreator
                key={`${altIndex.toString()}_${new Date().toString()}`}
                preData={value.content}
                onContentCreate={(createdContent) =>
                  contentCreate(createdContent, altIndex)
                }
              />
            </Card>
            <DeleteBlockButton onClick={() => removeBlock(altIndex)} />
          </ListItem>
        );
      }
      case BLOCK_TYPES.BUTTON: {
        return (
          <ListItem divider>
            <DragHandle />
            <Card className={classes.blockItem}>
              <ButtonCreator
                key={`${altIndex.toString()}_${new Date().toString()}`}
                preData={value.content}
                onButtonCreate={(button) => contentCreate(button, altIndex)}
              />
            </Card>
            <DeleteBlockButton onClick={() => removeBlock(altIndex)} />
          </ListItem>
        );
      }
      case BLOCK_TYPES.SINGLE_PRODUCT: {
        return (
          <ListItem divider>
            <DragHandle />
            <Card className={classes.blockItem}>
              <SingleProductSelector
                key={`${altIndex.toString()}_${new Date().toString()}`}
                onProductSelect={(product) => contentCreate(product, altIndex)}
                preSelectedProduct={value.content}
              />
            </Card>
            <DeleteBlockButton onClick={() => removeBlock(altIndex)} />
          </ListItem>
        );
      }
      case BLOCK_TYPES.SINGLE_COLLECTION: {
        return (
          <ListItem divider>
            <DragHandle />
            <Card className={classes.blockItem}>
              <SingleCollectionSelector
                key={`${altIndex.toString()}_${new Date().toString()}`}
                onCollectionSelect={(collection) =>
                  contentCreate(collection, altIndex)
                }
                preSelectedCollection={value.content}
              />
            </Card>
            <DeleteBlockButton onClick={() => removeBlock(altIndex)} />
          </ListItem>
        );
      }
      case BLOCK_TYPES.MULTIPLE_COLLECTION: {
        return (
          <ListItem divider>
            <DragHandle />
            <Card className={classes.blockItem}>
              <CollectionsSelector
                key={`${altIndex.toString()}_${new Date().toString()}`}
                onCollectionsSelect={(collections) => {
                  contentCreate(collections, altIndex);
                }}
                preSelectedCollections={value.content}
              />
            </Card>
            <DeleteBlockButton onClick={() => removeBlock(altIndex)} />
          </ListItem>
        );
      }
      case BLOCK_TYPES.MULTIPLE_PRODUCT: {
        return (
          <ListItem divider>
            <DragHandle />
            <Card className={classes.blockItem}>
              <ProductsSelector
                key={`${altIndex.toString()}_${new Date().toString()}`}
                preSelectedProducts={value.content}
                onProductsSelect={(products) => {
                  contentCreate(products, altIndex);
                }}
              />
            </Card>
            <DeleteBlockButton onClick={() => removeBlock(altIndex)} />
          </ListItem>
        );
      }
      default: {
        return null;
      }
    }
  });

  const DragHandle = SortableHandle(() => (
    <IconButton>
      <DragIndicatorIcon style={{ cursor: "move" }} />
    </IconButton>
  ));

  const SortableList = SortableContainer(({ items }) => {
    return (
      <List
        style={{
          padding: 0,
        }}
      >
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            altIndex={index}
            value={value}
          />
        ))}
      </List>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setBlocks(arrayMove(blocks, oldIndex, newIndex));
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <Typography variant="h3">
            {history.location.state.data?.settings?.heading?.text
              ? history.location.state.data?.settings?.heading.text
              : history.location.state.section?.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Card variant="outlined">
            <CardContent>
              <FormNote />
              <TitleCreator
                heading={heading}
                onHeadingChange={(updatedHeading) => setHeading(updatedHeading)}
                subHeading={subHeading}
                onSubHeadingChange={(updatedSubHeading) =>
                  setSubHeading(updatedSubHeading)
                }
                headingDivider={headingDivider}
                onHeadingDividerChange={(newHd) => setHeadingDivider(newHd)}
              />
              <Card variant="outlined" className={classes.blockContainer}>
                <InputLabel style={{ marginLeft: 10, marginTop: 10 }}>
                  CONTENT
                </InputLabel>

                {blocks.length === 0 ? (
                  <List
                    style={{
                      padding: 0,
                    }}
                  >
                    <ListItem>
                      <Card
                        className={classes.blockItem}
                        style={{ padding: 10 }}
                      >
                        <ListItemText>Content is empty</ListItemText>
                      </Card>
                    </ListItem>
                  </List>
                ) : (
                  // renderContent()
                  <SortableList
                    items={blocks}
                    onSortEnd={onSortEnd}
                    useDragHandle
                  />
                )}
              </Card>
              <Select
                className={classes.btnAddContent}
                variant="standard"
                fullWidth
                value={{}}
                SelectDisplayProps={{
                  style: {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingRight: 0,
                  },
                }}
                onChange={(e) => {
                  setBlocks([
                    ...blocks,
                    {
                      ...e.target.value,
                      content: null,
                    },
                  ]);
                }}
                renderValue={() => <Typography>+ ADD CONTENT</Typography>}
              >
                {BLOCK_LIST.map((block) => (
                  <MenuItem value={block}>{block.title}</MenuItem>
                ))}
              </Select>
              <ButtonField
                button={button}
                onButtonChange={(changedButton) => setButton(changedButton)}
              />
              <SectionCommonStyleCreator
                extraStyle={extraStyle}
                onExtraStyleChange={(extraStyle) => setExtraStyle(extraStyle)}
                style={{ marginTop: 20 }}
              />
              <VisibilityCreator
                visibility={visibility}
                onVisibilityChange={(visibility) => setVisibility(visibility)}
              />
              <Button
                color="secondary"
                onClick={onBack}
                variant="contained"
                style={{ marginRight: 10 }}
              >
                BACK
              </Button>
              <Button
                title="Save"
                variant="contained"
                color="primary"
                onClick={onSave}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving" : "Save"}
                {isSubmitting && (
                  <CircularProgress
                    size={20}
                    color="primary"
                    style={{ marginLeft: 5 }}
                  />
                )}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CustomContent;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../config/api";
import { useDispatch } from "react-redux";
import { showError, showMessage } from "../actions/snackbarAction";

const NOTIFICATION_ACTIONS = {
  DELETE: 0,
  DUPLICATE: 1,
  EDIT: 2,
  CANCEL: 3,
  PUBLISH: 4,
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const PushNotificationItemAction = ({ notification, onActionSuccess }) => {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();
  const [isLoading, setLoadingStatus] = useState(false);
  const dispatch = useDispatch();

  const onNotificationOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onNotificationActionClick = (action) => {
    setLoadingStatus(true);
    switch (action) {
      case NOTIFICATION_ACTIONS.DUPLICATE: {
        duplicateNotification();
        break;
      }
      case NOTIFICATION_ACTIONS.DELETE: {
        deleteNotification();
        break;
      }
      case NOTIFICATION_ACTIONS.CANCEL: {
        cancelNotification();
        break;
      }
      case NOTIFICATION_ACTIONS.PUBLISH: {
        publishNotification();
        break;
      }
      case NOTIFICATION_ACTIONS.EDIT: {
        history.push(`/push-notifications/${notification._id}`, {
          notification,
        });
        break;
      }
      default: {
        setLoadingStatus(false);
        return;
      }
    }
  };

  const duplicateNotification = () => {
    api
      .post(`push-notification/${notification._id}/duplicate`)
      .then((res) => {
        setLoadingStatus(false);
        if (res.data.success) {
          handleClose();
          dispatch(showMessage(res.data.message));
          onActionSuccess();
        } else {
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setLoadingStatus(false);
        dispatch(showError(error.message));
      });
  };

  const deleteNotification = () => {
    api
      .delete(`push-notification/${notification._id}`)
      .then((res) => {
        setLoadingStatus(false);
        if (res.data.success) {
          handleClose();
          dispatch(showMessage(res.data.message));
          onActionSuccess();
        } else {
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setLoadingStatus(false);
        dispatch(showError(error.message));
      });
  };

  const cancelNotification = () => {
    api
      .post(`push-notification/${notification._id}/cancel`)
      .then((res) => {
        setLoadingStatus(false);
        if (res.data.success) {
          handleClose();
          dispatch(showMessage(res.data.message));
          onActionSuccess();
        } else {
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setLoadingStatus(false);
        dispatch(showError(error.message));
      });
  };

  const publishNotification = () => {
    api
      .post(`push-notification/${notification._id}/publish`)
      .then((res) => {
        setLoadingStatus(false);
        if (res.data.success) {
          handleClose();
          dispatch(showMessage(res.data.message));
          onActionSuccess();
        } else {
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setLoadingStatus(false);
        dispatch(showError(error.message));
      });
  };

  const renderActions = () => {
    let action = [];
    action.push(
      <MenuItem
        key="0"
        onClick={() => onNotificationActionClick(NOTIFICATION_ACTIONS.DELETE)}
      >
        Delete
      </MenuItem>
    );
    action.push(
      <MenuItem
        key="1"
        onClick={() =>
          onNotificationActionClick(NOTIFICATION_ACTIONS.DUPLICATE)
        }
      >
        Duplicate
      </MenuItem>
    );

    switch (notification.status) {
      case "Scheduled": {
        action.push(
          <MenuItem
            key="2"
            onClick={() =>
              onNotificationActionClick(NOTIFICATION_ACTIONS.CANCEL)
            }
          >
            Cancel
          </MenuItem>
        );
        action.push(
          <MenuItem
            key="3"
            onClick={() => onNotificationActionClick(NOTIFICATION_ACTIONS.EDIT)}
          >
            Edit
          </MenuItem>
        );
        break;
      }
      case "Draft": {
        action.push(
          <MenuItem
            key="4"
            onClick={() => onNotificationActionClick(NOTIFICATION_ACTIONS.EDIT)}
          >
            Edit
          </MenuItem>
        );
        action.push(
          <MenuItem
            key="5"
            onClick={() =>
              onNotificationActionClick(NOTIFICATION_ACTIONS.CANCEL)
            }
          >
            Cancel
          </MenuItem>
        );
        action.push(
          <MenuItem
            key="6"
            onClick={() =>
              onNotificationActionClick(NOTIFICATION_ACTIONS.PUBLISH)
            }
          >
            Publish
          </MenuItem>
        );
        break;
      }
      case "In Progress": {
        action.push(
          <MenuItem
            key="7"
            onClick={() =>
              onNotificationActionClick(NOTIFICATION_ACTIONS.CANCEL)
            }
          >
            Cancel
          </MenuItem>
        );
        break;
      }
      default: {
      }
    }
    return action.map((item) => item);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={onNotificationOptionsClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        {renderActions()}
      </Menu>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
};

export default PushNotificationItemAction;

import React, { Fragment, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Popover from "@material-ui/core/Popover";
import ButtonBase from "@material-ui/core/ButtonBase";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeIcon from "@material-ui/icons/Home";
import NotificationsIcon from "@material-ui/icons/Notifications";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import ArchiveIcon from "@material-ui/icons/Archive";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Home from "./pages/Home";
import AppSections from "./pages/AppSections";
import SideMenu from "./pages/SideMenu";
import SectionTypes from "./pages/SectionTypes";
import Slider from "./pages/app-sections/Slider";
import FeaturedCategories from "./pages/app-sections/FeaturedCategories";
import AnnouncementBar from "./pages/app-sections/AnnouncementBar";
import Orders from "./pages/Orders";
import OrderDetail from "./pages/OrderDetail";
import PageNotFound from "./pages/PageNotFound";
import AddNewSectionType from "./pages/AddNewSectionTypes";
import FeaturedProducts from "./pages/app-sections/FeaturedProducts";
import SimpleParagraph from "./pages/app-sections/SimpleParagraph";
import CustomContent from "./pages/app-sections/CustomContent";
import GiftCard from "./pages/app-sections/GiftCard";
import ImageWithText from "./pages/app-sections/ImageWithText";
import ImageWithTextOverlay from "./pages/app-sections/ImageWithTextOverlay";
import PushNotification from "./pages/PushNotification";
// import Discount from "./pages/Discount";
// import CreateDiscount from "./pages/CreateDiscount";
import CreatePushNotification from "./pages/CreatePushNotification";
import SimpleImage from "./pages/app-sections/SimpleImage";
import HeroImage from "./pages/app-sections/HeroImage";
import InfoCard from "./pages/app-sections/InfoCard";

import Login from "./pages/Login";

import ScrollToTop from "./ScrollToTop";
import api from "./config/api";
import { showError, showMessage } from "./actions/snackbarAction";

import yellowfin_logo from "./assets/images/yellowfin-digital-logo-white.png";

import { setUser } from "./actions/userAction";
import ConfirmDialog from "./components/ConfirmDialog";
import SettingsIcon from "@material-ui/icons/Settings";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import InitialLoader from "./pages/InitialLoader";
import Settings from "./pages/Settings";

const drawerWidth = 240;
const history = createBrowserHistory();
const footerHeight = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingBottom: footerHeight,
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 0,
    },
    backgroundColor: theme.palette.primary.light,
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    ...theme.mixins.toolbar,
  },
  drawerToolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "auto",
    padding: theme.spacing(3),
    paddingBottom: footerHeight + theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(1),
      padding: theme.spacing(1),
    },
  },
  flex: {
    flex: 1,
  },
  aboveDrawer: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawerFooter: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    display: "none",
    backgroundColor: theme.palette.primary.dark,
    height: "auto",
    alignSelf: "flex-end",
    padding: 10,
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  footer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    display: "flex",
    backgroundColor: theme.palette.primary.dark,
    height: footerHeight,
    padding: 10,
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.drawer,
    [theme.breakpoints.down("xs")]: {
      display: "none",
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "red",
    },
  },
  contactLineItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 5,
    paddingBottom: 5,
    // color: theme.palette.text.primary,
    color: "#ffffff",
  },
}));

const MyToolbar = ({ onMenuClick }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isLoggedOuting, setLoggingOutStatus] = useState(false);
  const dispatch = useDispatch();
  const [isOpenLogoutConfirmDialog, setLogoutConfirmDialogStatus] = useState(
    false
  );
  const { user, token } = useSelector((state) => state.userReducer);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigateToSettings = () => {
    if (location.pathname !== "/settings") {
      history.push("/settings");
    }
    handleClose();
  };

  const doLogout = () => {
    setLoggingOutStatus(true);
    api
      .post(
        "users/me/logout",
        { token },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          dispatch(setUser(null, ""));
          setLoggingOutStatus(false);
          dispatch(showMessage("Logged out successfully"));
        }
      })
      .catch((error) => {
        setLoggingOutStatus(false);
        dispatch(showError(error.message));
      });
  };

  const onLogout = () => {
    setLogoutConfirmDialogStatus(true);
    handleClose();
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <AppBar className={classes.aboveDrawer}>
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={onMenuClick}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.flex}>
            SouthernHoney
          </Typography>
          <div>
            <ButtonBase
              disabled={isLoggedOuting}
              onClick={handleMenu}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "#ffffff",
              }}
            >
              <div style={{ marginRight: 5 }}>
                <Typography style={{ textAlign: "right" }} variant="subtitle2">
                  {user?.name}
                </Typography>
                <Typography style={{ textAlign: "right" }} variant="caption">
                  {user?.email}
                </Typography>
              </div>

              {isLoggedOuting ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                <AccountCircle />
              )}
            </ButtonBase>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <List component="nav">
                <ListItem button onClick={() => navigateToSettings()}>
                  <ListItemIcon>
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary="Settings" />
                </ListItem>
                <ListItem button onClick={() => onLogout()}>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </List>
            </Popover>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      <ConfirmDialog
        isOpen={isOpenLogoutConfirmDialog}
        message="Are you sure you want to logout ?"
        title="Logout"
        onCancel={() => setLogoutConfirmDialogStatus(false)}
        onClose={() => setLogoutConfirmDialogStatus(false)}
        onConfirm={() => doLogout()}
      />
    </Fragment>
  );
};

const MyDrawer = ({ onMenuItemClick }) => {
  const location = useLocation();
  const classes = useStyles();

  const activeRoute = (routeName) => {
    if (routeName === "/") {
      return location.pathname === "/";
    }
    if (location) {
      return location.pathname.indexOf(routeName) > -1;
    }
    return false;
  };

  return (
    <div className={classes.drawerContainer}>
      <div className={classes.drawerToolbar} />
      <Divider />
      <List style={{ flex: 1, color: "#ffffff" }}>
        <ListItem
          button
          component={Link}
          to="/"
          onClick={onMenuItemClick}
          selected={activeRoute("/")}
        >
          <ListItemIcon>
            <HomeIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/app-sections"
          onClick={onMenuItemClick}
          selected={activeRoute("/app-sections")}
        >
          <ListItemIcon>
            <DashboardIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <ListItemText primary={"App Sections"} />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/push-notifications"
          onClick={onMenuItemClick}
          selected={activeRoute("/push-notifications")}
        >
          <ListItemIcon>
            <NotificationsIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <ListItemText primary={"Push Notification"} />
        </ListItem>
        {/* <ListItem
          button
          component={Link}
          to="/discounts"
          onClick={onMenuItemClick}
          selected={activeRoute("/discounts")}
        >
          <ListItemIcon>
            <MoneyOffIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <ListItemText primary={"Discount"} />
        </ListItem> */}
        <ListItem
          button
          component={Link}
          to="/orders"
          onClick={onMenuItemClick}
          selected={activeRoute("/orders")}
        >
          <ListItemIcon>
            <ArchiveIcon style={{ color: "#ffffff" }} />
          </ListItemIcon>
          <ListItemText primary={"Orders"} />
        </ListItem>
      </List>
      <Box className={classes.drawerFooter}>
        <Box>
          <img
            src={yellowfin_logo}
            alt="logo"
            style={{
              height: 50,
              maxHeight: 50,
              width: "auto",
              verticalAlign: "middle",
            }}
          />
        </Box>
        <Box>
          <Box className={classes.contactLineItem}>
            <Box style={{ marginRight: 5, width: 30 }}>
              <PhoneIcon
                style={{ color: "#f3df1a", verticalAlign: "middle" }}
              />
            </Box>
            <Box>
              <Typography color="textPrimary">
                <a
                  href="tel:3618448550"
                  style={{
                    color: "#ffffff",
                    textDecoration: "none",
                  }}
                >
                  361-844-8550
                </a>
              </Typography>
            </Box>
          </Box>
          <Box className={classes.contactLineItem}>
            <Box style={{ marginRight: 5, width: 30 }}>
              <EmailOutlinedIcon
                style={{ color: "#f3df1a", verticalAlign: "middle" }}
              />
            </Box>
            <Box>
              <Typography>
                <a
                  href="mailto:info@yellowfindigital.com"
                  style={{
                    color: "#ffffff",
                    textDecoration: "none",
                  }}
                >
                  info@yellowfindigital.com
                </a>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography
            style={{ fontWeight: "700", textAlign: "center", color: "#ffffff" }}
          >
            © 2021{" "}
            <a
              href="https://www.yellowfindigital.com/"
              style={{
                color: "#f3df1a",
                textDecoration: "none",
              }}
            >
              YellowFin Digital
            </a>
            . All rights reserved.
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

const Router = (props) => {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isAppLoading, setAppLoadingStatus] = useState(true);
  const { token } = useSelector((state) => state.userReducer);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onMenuItemClick = () => {
    setMobileOpen(false);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  if (isAppLoading) {
    return (
      <InitialLoader
        onLoadingComplete={(result) => {
          setAppLoadingStatus(false);
        }}
      />
    );
  }

  if (!token) {
    return <Login />;
  }

  return (
    <BrowserRouter history={history}>
      <ScrollToTop />
      <div className={classes.root}>
        <MyToolbar onMenuClick={handleDrawerToggle} />
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              <MyDrawer onMenuItemClick={onMenuItemClick} />
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              <MyDrawer onMenuItemClick={onMenuItemClick} />
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/app-sections">
              <AppSections />
            </Route>
            <Route exact path="/app-sections/side-menu">
              <SideMenu />
            </Route>
            <Route exact path="/section-types">
              <SectionTypes />
            </Route>
            <Route exact path="/section-types/add-new-section-type">
              <AddNewSectionType />
            </Route>
            <Route exact path="/app-sections/announcement-bar">
              <AnnouncementBar />
            </Route>
            <Route exact path="/app-sections/slider">
              <Slider />
            </Route>
            <Route exact path="/app-sections/featured-categories">
              <FeaturedCategories />
            </Route>
            <Route exact path="/app-sections/featured-products">
              <FeaturedProducts />
            </Route>
            <Route exact path="/app-sections/simple-paragraph">
              <SimpleParagraph />
            </Route>
            <Route exact path="/app-sections/custom-content">
              <CustomContent />
            </Route>
            <Route exact path="/app-sections/gift-card">
              <GiftCard />
            </Route>
            <Route exact path="/app-sections/simple-image">
              <SimpleImage />
            </Route>
            <Route exact path="/app-sections/image-with-text">
              <ImageWithText />
            </Route>
            <Route exact path="/app-sections/image-with-text-overlay">
              <ImageWithTextOverlay />
            </Route>
            <Route exact path="/app-sections/hero-image">
              <HeroImage />
            </Route>
            <Route exact path="/app-sections/card">
              <InfoCard />
            </Route>
            <Route exact path="/push-notifications">
              <PushNotification />
            </Route>
            <Route exact path="/push-notifications/create">
              <CreatePushNotification />
            </Route>
            <Route exact path="/push-notifications/:id">
              <CreatePushNotification />
            </Route>
            {/* <Route exact path="/discounts">
              <Discount />
            </Route>
            <Route exact path="/discounts/create">
              <CreateDiscount />
            </Route> */}
            <Route exact path="/orders">
              <Orders />
            </Route>
            <Route exact path="/orders/:id">
              <OrderDetail />
            </Route>
            <Route exact path="/settings">
              <Settings />
            </Route>
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
          <Box className={classes.footer}>
            <Box>
              <img
                src={yellowfin_logo}
                alt="logo"
                style={{
                  height: 50,
                  width: "auto",
                  verticalAlign: "middle",
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: "700",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                © 2021{" "}
                <a
                  href="https://www.yellowfindigital.com/"
                  style={{
                    color: "#f3df1a",
                    textDecoration: "none",
                  }}
                >
                  YellowFin Digital
                </a>
                . All rights reserved.
              </Typography>
            </Box>
            <Box>
              <Box className={classes.contactLineItem}>
                <Box style={{ marginRight: 5, width: 30 }}>
                  <PhoneIcon
                    style={{ color: "#f3df1a", verticalAlign: "middle" }}
                  />
                </Box>
                <Box>
                  <Typography color="textPrimary">
                    <a
                      href="tel:3618448550"
                      style={{
                        color: "#ffffff",
                        textDecoration: "none",
                      }}
                    >
                      361-844-8550
                    </a>
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.contactLineItem}>
                <Box style={{ marginRight: 5, width: 30 }}>
                  <EmailOutlinedIcon
                    style={{ color: "#f3df1a", verticalAlign: "middle" }}
                  />
                </Box>
                <Box>
                  <Typography>
                    <a
                      href="mailto:info@yellowfindigital.com"
                      style={{
                        color: "#ffffff",
                        textDecoration: "none",
                      }}
                    >
                      info@yellowfindigital.com
                    </a>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default Router;

import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showError, showMessage } from "../../actions/snackbarAction";
import { addAnnouncementBarValidationScheme } from "../../utils/yupValidationScheme";
import api from "../../config/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import { pageFormMode } from "../../contants/others";
import LinkSelector from "../../components/LinkSelector";
import FormNote from "../../components/FormNote";
import ButtonField from "../../components/ButtonField";
import ColorPicker from "../../components/ColorPicker";
import VisibilityCreator from "../../components/VisibilityCreator";
import { DEFAULT_COLOR } from "../../config/data";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  formField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const AddAnnouncementBar = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [formMode, setFormMode] = useState(pageFormMode.CREATE);
  const [prevData, setPrevData] = useState({});

  useEffect(() => {
    const { mode, data } = history.location.state;

    if (data) {
      setFormMode(mode);
      setPrevData(data);
    }
  }, [history.location.state]);

  const onSave = (values, { setSubmitting }) => {
    const { section } = history.location.state;
    const param = {
      title: section.title,
      contentType: section.key,
      content: null,
      settings: values,
    };
    if (!formMode || formMode === pageFormMode.CREATE) {
      api
        .post("app-content", param)
        .then((res) => {
          setSubmitting(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showError(error.response.data.message));
        });
    } else {
      api
        .put(`app-content/${prevData._id}`, param)
        .then((res) => {
          setSubmitting(false);
          dispatch(showMessage(res.data.message));
          history.goBack();
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showError(error.response.data.message));
        });
    }
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h3">Announcement Bar</Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.row}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Formik
            key={formMode}
            initialValues={
              !formMode || formMode === 0
                ? {
                    homepageOnly: true,
                    text: "",
                    barBackgroundColor: {},
                    barTextColor: {},
                    link: null,
                    button: null,
                    visibility: true,
                  }
                : prevData.settings
            }
            onSubmit={onSave}
            validationSchema={addAnnouncementBarValidationScheme}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <Card variant="outlined">
                <CardContent>
                  <form noValidate autoComplete="off">
                    <FormNote />
                    {/* <FormGroup className={classes.formField}>
                      <FormControlLabel
                        label="Homepage only"
                        control={
                          <Checkbox
                            checked={values.homepageOnly}
                            color="primary"
                            name="homepageOnly"
                            onChange={(e) => {
                              console.log(e);
                              setFieldValue("homepageOnly", e.target.checked);
                            }}
                            onBlur={handleBlur}
                          />
                        }
                      />
                    </FormGroup> */}
                    <FormGroup className={classes.formField}>
                      <TextField
                        id="text"
                        label="Text"
                        variant="outlined"
                        name="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.text}
                        error={errors.text && touched.text}
                        helperText={errors.text && touched.text && errors.text}
                      />
                    </FormGroup>
                    <Grid container className={classes.formField} spacing={2}>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
                        <ColorPicker
                          label="Bar Text Color"
                          selectedColor={
                            values.barTextColor?.rgba ||
                            DEFAULT_COLOR.PRIMARY_TEXT.rgba
                          }
                          onSelect={(color) =>
                            setFieldValue("barTextColor", color)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
                        <ColorPicker
                          label="Bar Background Color"
                          selectedColor={
                            values.barBackgroundColor?.rgba ||
                            DEFAULT_COLOR.PRIMARY_BACKGROUND.rgba
                          }
                          onSelect={(color) =>
                            setFieldValue("barBackgroundColor", color)
                          }
                        />
                      </Grid>
                    </Grid>
                    <LinkSelector
                      label="Link"
                      preSelectedLink={values.link}
                      onParamSelect={(param) =>
                        setFieldValue("link", {
                          ...values.link,
                          param,
                        })
                      }
                      onScreenSelect={(screen) => {
                        setFieldValue("link", {
                          ...values.link,
                          screen,
                        });
                      }}
                    />
                    <ButtonField
                      button={values.button}
                      onButtonChange={(button) =>
                        setFieldValue("button", button)
                      }
                    />
                    <VisibilityCreator
                      visibility={values.visibility}
                      onVisibilityChange={(visibility) =>
                        setFieldValue("visibility", visibility)
                      }
                    />
                  </form>
                </CardContent>
                <CardActions>
                  <Button
                    title="Save"
                    variant="contained"
                    color="secondary"
                    onClick={() => history.goBack()}
                  >
                    CANCEL
                  </Button>
                  <Button
                    title="Save"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Saving" : "Save"}
                    {isSubmitting && (
                      <CircularProgress
                        size={20}
                        color="primary"
                        style={{ marginLeft: 5 }}
                      />
                    )}
                  </Button>
                </CardActions>
              </Card>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddAnnouncementBar;

import { SET_USER, SET_TOKEN } from "../contants/actionKeys";
const initialState = {
  user: null,
  token: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return {
        user: action.user,
        token: action.token,
      };
    }
    case SET_TOKEN: {
      return {
        user: state.user,
        token: action.token,
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;

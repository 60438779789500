import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  FormGroup,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import api from "../../config/api";
import { showMessage, showError } from "../../actions/snackbarAction";
import { pageFormMode } from "../../contants/others";
import { imageWithTextOverlayValidationScheme } from "../../utils/yupValidationScheme";
import ImageSelector from "../../components/ImageSelector";
import FormNote from "../../components/FormNote";
import VisibilityCreator from "../../components/VisibilityCreator";
import ButtonField from "../../components/ButtonField";
import HtmlField from "../../components/HtmlField";
import TitleCreator from "../../components/TitleCreator";

const useStyle = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

const ImageWithTextOverlay = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formMode, setFormMode] = useState(pageFormMode.CREATE);
  const [prevFormData, setPrevFormData] = useState({});

  useEffect(() => {
    const { mode, data } = history.location.state;
    if (data) {
      setFormMode(mode);
      setPrevFormData(data);
    }
  }, [history.location.state]);

  const onSave = (values, { setSubmitting }) => {
    const { section } = history.location.state;
    const params = {
      title: section.title,
      contentType: section.key,
      content: null,
      settings: values,
    };

    if (!formMode || formMode === pageFormMode.CREATE) {
      api
        .post("app-content", params)
        .then((res) => {
          setSubmitting(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showMessage(error.message));
        });
    } else {
      api
        .put(`app-content/${prevFormData._id}`, params)
        .then((res) => {
          setSubmitting(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showMessage(error.message));
        });
    }
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <Typography variant="h3">
            {history.location.state.data?.settings?.heading?.text
              ? history.location.state.data?.settings?.heading.text
              : history.location.state.section?.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={
              !formMode || formMode === pageFormMode.CREATE
                ? {
                    heading: {},
                    subHeading: {},
                    headingDivider: null,
                    text: "",
                    button: null,
                    image: {},
                    imageAlignment: "top",
                    visibility: true,
                  }
                : {
                    ...prevFormData.settings,
                  }
            }
            onSubmit={onSave}
            validationSchema={imageWithTextOverlayValidationScheme}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleSubmit,
              setTouched,
            }) => (
              <form>
                <Card variant="outlined">
                  <CardContent>
                    <FormNote />
                    <TitleCreator
                      heading={values.heading}
                      onHeadingChange={(updatedHeading) =>
                        setFieldValue("heading", updatedHeading)
                      }
                      subHeading={values.subHeading}
                      onSubHeadingChange={(updatedSubHeading) =>
                        setFieldValue("subHeading", updatedSubHeading)
                      }
                      headingDivider={values.headingDivider}
                      onHeadingDividerChange={(newHD) => {
                        setFieldValue("headingDivider", newHD);
                      }}
                    />
                    <HtmlField
                      label="Text"
                      value={values.text}
                      onChange={(text) => setFieldValue("text", text)}
                    />
                    <ButtonField
                      button={values.button}
                      onButtonChange={(button) =>
                        setFieldValue("button", button)
                      }
                    />
                    <FormGroup className={classes.formField}>
                      <InputLabel>Image</InputLabel>
                      <ImageSelector
                        selectedImage={values.image}
                        onImageChange={(image) => setFieldValue("image", image)}
                        imageUploadFolder="App-Content/Image-With-Text-Overlay"
                      />
                    </FormGroup>
                    <FormControl
                      className={classes.formField}
                      fullWidth
                      variant="outlined"
                    >
                      <InputLabel id="imageAlignment">
                        Image Alignment
                      </InputLabel>
                      <Select
                        labelId="imageAlignment"
                        name="imageAlignment"
                        label="Image Alignment"
                        variant="outlined"
                        value={values.imageAlignment}
                        fullWidth
                        onChange={(e) => {
                          setFieldValue("imageAlignment", e.target.value);
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem key={"top"} value={"top"}>
                          Top
                        </MenuItem>
                        <MenuItem key={"bottom"} value={"bottom"}>
                          Bottom
                        </MenuItem>
                        <MenuItem key={"left"} value={"left"}>
                          Left
                        </MenuItem>
                        <MenuItem key={"right"} value={"right"}>
                          Right
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <VisibilityCreator
                      visibility={values.visibility}
                      onVisibilityChange={(visibility) =>
                        setFieldValue("visibility", visibility)
                      }
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      title="Save"
                      variant="contained"
                      color="secondary"
                      onClick={() => history.goBack()}
                    >
                      CANCEL
                    </Button>
                    <Button
                      title="Save"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Saving" : "Save"}
                      {isSubmitting && (
                        <CircularProgress
                          size={20}
                          color="primary"
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </Button>
                  </CardActions>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ImageWithTextOverlay;

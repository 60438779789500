import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ButtonBase,
  makeStyles,
  Box,
} from "@material-ui/core";
import ImageSelector from "./ImageSelector";
import LinkSelector from "./LinkSelector";
import Zoom from "react-medium-image-zoom";
import { IMAGE_PLACEHOLDER } from "../config/data";
import SectionCommonStyleCreator from "./SectionCommonStyleCreator";

const useStyle = makeStyles(() => ({
  formField: {
    marginTop: 10,
    marginBottom: 10,
  },
  media: {
    height: 100,
    width: 100,
    objectFit: "contain",
  },
}));

const ImageCreator = ({ initialallyOpen, onImageCreate, preData }) => {
  const classes = useStyle();
  const [isOpen, setOpenStatus] = useState(initialallyOpen || false);
  const [image, setImage] = useState(preData ? preData.image : null);
  const [imageLink, setImageLink] = useState(
    preData ? preData.imageLink : null
  );
  const [extraStyle, setExtraStyle] = useState(
    preData ? preData.extraStyle : null
  );

  const openDialog = () => {
    setOpenStatus(true);
  };

  const closeDialog = () => {
    setOpenStatus(false);
  };

  const onSave = () => {
    onImageCreate({ image, imageLink, extraStyle });
    closeDialog();
  };

  return (
    <Box>
      <ButtonBase onClick={openDialog} style={{ margin: 10, width: "100%" }}>
        <Zoom>
          <img
            src={image?.url || IMAGE_PLACEHOLDER}
            alt={image?.publicId || "NO_IMAGE"}
            className={classes.media}
          />
        </Zoom>
      </ButtonBase>
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogContent>
          <ImageSelector
            selectedImage={image}
            onImageChange={(value) => setImage(value)}
            imageUploadFolder="App-Content/Custom-Content"
          />
          <LinkSelector
            preSelectedLink={imageLink}
            onParamSelect={(param) => setImageLink({ ...imageLink, param })}
            onScreenSelect={(screen) => setImageLink({ ...imageLink, screen })}
          />
          <SectionCommonStyleCreator
            extraStyle={extraStyle}
            onExtraStyleChange={(changedStyle) => setExtraStyle(changedStyle)}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeDialog} variant="contained">
            CANCEL
          </Button>
          <Button color="primary" onClick={onSave} variant="contained">
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImageCreator;

export const light = {
  palette: {
    type: "light",
    primary: {
      light: "#065b71",
      main: "#065b71",
      dark: "#063039",
      contrastText: "#fff",
    },
  },
};

export const dark = {
  palette: {
    type: "dark",
    primary: {
      light: "#065b71",
      main: "#065b71",
      dark: "#063039",
      contrastText: "#fff",
    },
  },
};

import React from "react";
import { Chip } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircle";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import ScheduleRounded from "@material-ui/icons/ScheduleRounded";
import DraftsIcon from "@material-ui/icons/Drafts";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import CancelIcon from "@material-ui/icons/Cancel";

export const Completed = () => (
  <Chip
    icon={<CheckIcon />}
    label="Completed"
    size="small"
    style={{ backgroundColor: "#5cb85c", color: "#ffffff" }}
  />
);

export const Scheduled = () => (
  <Chip
    icon={<ScheduleRounded />}
    label="Scheduled"
    size="small"
    style={{ backgroundColor: "#f0ad4e", color: "#ffffff" }}
  />
);

export const Failed = () => (
  <Chip
    icon={<SmsFailedIcon />}
    label="Failed"
    size="small"
    style={{ backgroundColor: "#ff0000", color: "#ffffff" }}
  />
);

export const Draft = () => (
  <Chip
    icon={<DraftsIcon />}
    label="Draft"
    size="small"
    style={{ backgroundColor: "#778899", color: "#ffffff" }}
  />
);

export const InProgress = () => (
  <Chip
    icon={<DonutLargeIcon />}
    label="In Progress"
    size="small"
    style={{ backgroundColor: "#5bc0de", color: "#ffffff" }}
  />
);

export const Canceled = () => (
  <Chip
    icon={<CancelIcon />}
    label="Canceled"
    size="small"
    style={{ backgroundColor: "#d9534f", color: "#ffffff" }}
  />
);

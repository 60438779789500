import React, { useState, useEffect } from "react";
import {
  makeStyles,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControl,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";
import SingleCollectionSelector from "./SingleCollectionSelector";
import SingleProductSelector from "./SingleProductSelector";

const SCREENS = [
  {
    title: "Home",
    key: "Home",
  },
  {
    title: "Search",
    key: "Search",
  },
  {
    title: "Collection",
    key: "Products",
  },
  {
    title: "Product",
    key: "ProductDetail",
  },
  {
    title: "Wishlist",
    key: "Wishlist",
  },
  {
    title: "Cart",
    key: "Cart",
  },
  {
    title: "Account",
    key: "Account",
  },
  {
    title: "Orders",
    key: "Orders",
  },
  {
    title: "External",
    key: "External",
  },
];

const useStyle = makeStyles((theme) => ({
  selectLinkButton: {
    width: "100%",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderWidth: 1,
    borderStyle: "dashed",
  },
  formField: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

const SelectLink = ({
  onScreenSelect,
  onParamSelect,
  preSelectedLink,
  label = "Link",
  helperText = "",
}) => {
  const classes = useStyle();
  const [selectedScreen, setSelectedScreen] = useState("");
  const [selectedParam, setSelectedParam] = useState(null);

  useEffect(() => {
    if (preSelectedLink) {
      setSelectedScreen(preSelectedLink.screen || "");
      setSelectedParam(preSelectedLink.param);
    }
  }, [preSelectedLink]);

  const renderParamSelector = () => {
    switch (selectedScreen) {
      case "Products": {
        return (
          <SingleCollectionSelector
            preSelectedCollection={preSelectedLink ? selectedParam : null}
            onCollectionSelect={(data) => onParamSelect(data)}
          />
        );
      }
      case "ProductDetail": {
        return (
          <SingleProductSelector
            preSelectedProduct={preSelectedLink ? selectedParam : null}
            onProductSelect={(data) => onParamSelect(data)}
          />
        );
      }
      case "External": {
        return (
          <FormGroup className={classes.formField}>
            <TextField
              variant="outlined"
              label="Url"
              value={selectedParam ? selectedParam.url : ""}
              onChange={(e) => {
                setSelectedParam({ url: e.target.value });
                onParamSelect({ url: e.target.value });
              }}
            />
          </FormGroup>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Paper variant="outlined" style={{ padding: 10 }}>
      <FormGroup>
        <InputLabel style={{ marginBottom: 10 }}>{label}</InputLabel>
        <FormControl variant="outlined">
          <InputLabel id="linkedToPage" variant="outlined">
            Select Screen
          </InputLabel>
          <Select
            labelId="linkedToPage"
            label="Select Screen"
            name="pageToLink"
            value={selectedScreen}
            onChange={(e) => {
              setSelectedScreen(e.target.value);
              onScreenSelect(e.target.value);
              setSelectedParam(null);
            }}
          >
            <MenuItem key={"none"} value={""}>
              None
            </MenuItem>
            {SCREENS.map((screen) => (
              <MenuItem key={screen.title} value={screen.key}>
                {screen.title}
              </MenuItem>
            ))}
          </Select>
          {helperText !== "" && (
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ marginLeft: 10 }}
            >
              {helperText}
            </Typography>
          )}
        </FormControl>
      </FormGroup>
      {renderParamSelector()}
    </Paper>
  );
};

export default SelectLink;

import React from 'react';

const PageNotFound = () => {
	return (
		<div>
			<h1>This page not found</h1>
		</div>
	)
};

export default PageNotFound;
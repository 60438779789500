import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const PadMarField = ({ label, value, onChange, onBlur, className }) => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={className}
      style={{ marginTop: 10 }}
    >
      <InputLabel id="labelId">{label}</InputLabel>
      <Select
        labelId="labelId"
        label={label}
        variant="outlined"
        value={value?.toString() || "auto"}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
      >
        <MenuItem value="auto">auto</MenuItem>
        <MenuItem value={0}>0</MenuItem>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={15}>15</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={100}>100</MenuItem>
      </Select>
    </FormControl>
  );
};

export default PadMarField;

import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Radio,
  CircularProgress,
  ButtonBase,
  Card,
  CardContent,
  IconButton,
  Box,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS_LIST } from "../shopifyStorefrontQueries/queries/product";
import { IMAGE_PLACEHOLDER } from "../config/data";
import SearchBar from "material-ui-search-bar";

const useStyle = makeStyles((theme) => ({
  selectButton: {
    width: "100%",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderWidth: 1,
    borderStyle: "dashed",
  },
  emptyListContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
}));

const LoadingPlaceholder = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <ListItem key={i.toString()} button divider>
          <ListItemAvatar>
            <Skeleton height={50}>
              <Avatar />
            </Skeleton>
          </ListItemAvatar>
          <Skeleton variant="text" width={300} />
        </ListItem>
      ))}
    </>
  );
};

const SingleProductSelector = ({ onProductSelect, preSelectedProduct }) => {
  const classes = useStyle();
  const [searchQuery, setSearchQuery] = useState("");
  const { loading, data, fetchMore, refetch } = useQuery(GET_PRODUCTS_LIST, {
    variables: {
      query: searchQuery !== "" ? `title:*${searchQuery}*` : "",
    },
  });
  const [selectedProduct, setSelectedProduct] = useState(
    preSelectedProduct || null
  );
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [isOpen, setOpenStatus] = useState(false);

  const openDialog = () => {
    setOpenStatus(true);
  };

  const closeDialog = () => {
    onProductSelect(selectedProduct);
    setOpenStatus(false);
  };

  const loadNextPage = () => {
    if (data.products?.pageInfo?.hasNextPage && !isNextPageLoading) {
      setIsNextPageLoading(true);
      fetchMore({
        variables: {
          cursor: data.products.edges[data.products.edges.length - 1].cursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          setIsNextPageLoading(false);
          const newEdges = fetchMoreResult.products.edges;
          const newPageInfo = fetchMoreResult.products.pageInfo;
          return newEdges.length
            ? {
                products: {
                  edges: [...prevResult.products.edges, ...newEdges],
                  pageInfo: newPageInfo,
                  __typename: prevResult.products.__typename,
                },
              }
            : prevResult;
        },
      });
    }
  };

  const handleToggle = (product) => {
    setSelectedProduct(product);
  };

  const onSearch = () => {
    if (searchQuery !== "") {
      refetch({
        query: `*${searchQuery}*`,
      });
    } else {
      refetch({ query: "" });
    }
  };

  const removeProduct = () => {
    setSelectedProduct(null);
    onProductSelect(null);
  };

  return (
    <Box>
      <Card style={{ marginTop: 10, marginBottom: 10 }}>
        <CardContent>
          <List
            style={{
              width: "100%",
              position: "relative",
              overflow: "auto",
              maxHeight: 300,
            }}
          >
            {!selectedProduct || selectedProduct === null ? (
              <Typography>Please Select Products</Typography>
            ) : (
              <ListItem button divider>
                <ListItemAvatar>
                  <Avatar
                    style={{
                      height: 50,
                      width: 50,
                      marginRight: 10,
                    }}
                    variant="square"
                    alt={
                      selectedProduct?.images?.edges[0]
                        ? selectedProduct.images.edges[0].node.altText
                        : "no_image"
                    }
                    src={
                      selectedProduct?.images?.edges[0]
                        ? selectedProduct.images.edges[0].node.transformedSrc
                        : IMAGE_PLACEHOLDER
                    }
                  />
                </ListItemAvatar>
                <ListItemText primary={selectedProduct.title} />
                <ListItemSecondaryAction>
                  <IconButton
                    aria-label="Delete this sub heading"
                    onClick={() => removeProduct()}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )}
          </List>
        </CardContent>
      </Card>
      <ButtonBase className={classes.selectButton} onClick={openDialog}>
        <Typography>
          {selectedProduct ? "CHANGE PRODUCT" : "SELECT PRODUCT"}
        </Typography>
      </ButtonBase>
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant="h6">Select Product</Typography>
          <SearchBar
            value={searchQuery}
            onChange={(newValue) => setSearchQuery(newValue)}
            onRequestSearch={onSearch}
            onCancelSearch={() => {
              setSearchQuery("");
              refetch({ query: "" });
            }}
          />
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <List dense>
            {loading || !data || !data.products || !data.products.edges ? (
              <LoadingPlaceholder />
            ) : (
              data.products.edges
                .filter((edge) => edge !== null)
                .map((product) => {
                  const labelId = `checkbox-list-secondary-label-${product.node.id}`;
                  return (
                    <ListItem
                      button
                      divider
                      onClick={() => handleToggle(product.node)}
                      selected={selectedProduct?.id === product.node.id}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{ height: 50, width: 50, marginRight: 10 }}
                          variant="square"
                          alt={
                            product?.node?.images?.edges[0]
                              ? product?.node?.images?.edges[0]?.node?.altText
                              : "no_image"
                          }
                          src={
                            product?.node?.images?.edges[0]
                              ? product?.node?.images?.edges[0].node
                                  ?.transformedSrc
                              : IMAGE_PLACEHOLDER
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={product.node.title} />
                      <ListItemSecondaryAction>
                        <Radio
                          checked={selectedProduct?.id === product.node.id}
                          onChange={() => handleToggle(product.node)}
                          value={product.node.id}
                          color="primary"
                          name="selected-collection"
                          inputProps={{ "aria-label": "D" }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
            )}
            {data && data.products?.pageInfo?.hasNextPage && (
              <ListItem
                button
                divider
                onClick={() => loadNextPage()}
                style={{
                  justifyContent: "center",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {isNextPageLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  <Typography>LOAD MORE</Typography>
                )}
              </ListItem>
            )}
          </List>
          {!loading && data.products.edges.length === 0 && (
            <Box className={classes.emptyListContainer}>
              <Typography variant="h4">Product not found</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="contained">
            CLOSE
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SingleProductSelector;

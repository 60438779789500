import React, { useEffect } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Divider,
  Paper,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import FulfillmentStatus from "../components/FulfillmentStatus";
import FinancialStatus from "../components/FinancialStatus";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
  },
  row: {
    display: "flex",
    flex: 1,
    padding: theme.spacing(2),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const OrderDetail = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    console.log(history?.location?.state?.order);
  }, []);

  const order = history?.location?.state?.order;

  const renderDate = (dateToFormate) => {
    let date = new Date(dateToFormate);
    return `${date.toDateString()} at ${date.toLocaleTimeString()}`;
  };

  if (!order) {
    return (
      <Container className={classes.root}>
        <Grid container>
          <Grid item xs={12} md={12} xl={12}>
            <Typography variant="h3">Order Not Found</Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container className={classes.root}>
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item style={{ alignSelf: "flex-start" }}>
          <Typography variant="h4">{order.name}</Typography>
        </Grid>
        <Grid item style={{ alignSelf: "center" }}>
          <FinancialStatus financialStatus={order.financial_status} />
        </Grid>
        <Grid item style={{ alignSelf: "center" }}>
          <FulfillmentStatus fullfillmentStatus={order.fulfillment_status} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1">
            {renderDate(order.created_at)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          {order.fulfillment_status !== "fulfilled" && (
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="h5">Unfulfilled</Typography>
                </Grid>
                {order.line_items
                  .filter((item) => item.fulfillable_quantity > 0)
                  .map((lineItem) => (
                    <>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Divider />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography>{lineItem.name}</Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                        <Typography align="center">
                          ${lineItem.price} &times;{" "}
                          {lineItem.fulfillable_quantity}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={3}
                        lg={3}
                        xl={3}
                        justify="flex-end"
                        alignItems="flex-end"
                      >
                        <Typography align="right">
                          $
                          {parseFloat(lineItem.price) *
                            lineItem.fulfillable_quantity}
                        </Typography>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </Paper>
          )}

          {order?.fulfillments?.map((fulfillment) => (
            <Paper className={classes.paper} style={{ marginTop: 20 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography variant="h5">Fulfillment</Typography>
                </Grid>
                {fulfillment.line_items.map((lineItem) => (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Typography>{lineItem.name}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                      <Typography align="center">
                        ${lineItem.price} &times; {lineItem.quantity}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      lg={3}
                      xl={3}
                      justify="flex-end"
                      alignItems="flex-end"
                    >
                      <Typography align="right">
                        ${parseFloat(lineItem.price) * lineItem.quantity}
                      </Typography>
                    </Grid>
                  </>
                ))}
              </Grid>
            </Paper>
          ))}

          <Paper className={classes.paper} style={{ marginTop: 20 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h5">Paid</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography>Subtotal</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography align="left">
                      {order.line_items.length} Items
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Typography align="right">
                      ${order.subtotal_price}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography>Shipping</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography align="left">
                      {order.shipping_lines[0]?.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Typography align="right">
                      ${order.shipping_lines[0]?.price}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography>Discount</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography align="left">
                      {order.discount_applications[0]?.title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Typography align="right">
                      ${order.total_discounts}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <Typography>Paid By Customer</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography align="left"></Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={3}
                    lg={3}
                    xl={3}
                    justify="flex-end"
                    alignItems="flex-end"
                  >
                    <Typography align="right">${order.total_price}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Paper className={classes.paper}>
            <Typography variant="h5">Notes</Typography>
            <Divider
              variant="fullWidth"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
            {!order.note ? (
              <Typography variant="body1">No notes from customer</Typography>
            ) : (
              <Typography variant="body1">{order.note}</Typography>
            )}
          </Paper>

          <Paper className={classes.paper} style={{ marginTop: 20 }}>
            <Typography variant="h5">Customer</Typography>
            <Divider
              variant="fullWidth"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
            <Typography variant="body1">
              {order.customer?.first_name} {order.customer?.last_name}
            </Typography>
            <Typography variant="body1">{order.customer?.email}</Typography>

            <Divider
              variant="fullWidth"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
            <Typography variant="subtitle2" style={{ marginTop: 10 }}>
              SHIPPING ADDRESS{" "}
            </Typography>

            <Typography variant="body1">
              {order.shipping_address?.first_name}{" "}
              {order.shipping_address?.last_name}
            </Typography>
            <Typography variant="body1">
              {order.shipping_address?.company}
            </Typography>
            <Typography variant="body1">
              {order.shipping_address?.address1}
            </Typography>
            <Typography variant="body1">
              {order.shipping_address?.address2}
            </Typography>
            <Typography variant="body1">
              {order.shipping_address?.zip} {order.shipping_address?.city}{" "}
              {order.shipping_address?.province_code}
            </Typography>
            <Typography variant="body1">
              {order.shipping_address?.country}
            </Typography>

            <Divider
              variant="fullWidth"
              style={{ marginTop: 10, marginBottom: 10 }}
            />
            <Typography variant="subtitle2" style={{ marginTop: 10 }}>
              BILLING ADDRESS{" "}
            </Typography>

            <Typography variant="body1">
              {order.billing_address?.first_name}{" "}
              {order.billing_address?.last_name}
            </Typography>
            <Typography variant="body1">
              {order.billing_address?.company}
            </Typography>
            <Typography variant="body1">
              {order.billing_address?.address1}
            </Typography>
            <Typography variant="body1">
              {order.billing_address?.address2}
            </Typography>
            <Typography variant="body1">
              {order.billing_address?.zip} {order.billing_address?.city}{" "}
              {order.billing_address?.province_code}
            </Typography>
            <Typography variant="body1">
              {order.billing_address?.country}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OrderDetail;

import React, { useEffect, useState } from "react";
import { Box, InputLabel, makeStyles } from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PRESET_COLORS, BASE_FONTS } from "../config/data";

const useStyles = makeStyles((theme) => ({
  container: {
    "& .ql-container.ql-snow": {
      borderColor: theme.palette.divider,
    },
    "& .ql-container": {
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    "& .ql-snow.ql-toolbar": {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
}));

const HtmlField = ({
  value,
  onChange,
  onBlur = () => {},
  label = "Text",
  className,
}) => {
  const classes = useStyles();

  return (
    <Box className={className}>
      <InputLabel style={{ marginBottom: 5 }}>{label}</InputLabel>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        className={classes.container}
        onBlur={onBlur}
        modules={{
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              {
                color: PRESET_COLORS,
              },
            ],
            [
              {
                font: BASE_FONTS,
              },
            ]["link"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
            [{ align: [] }],
            ["clean"],
          ],
        }}
        formats={[
          "header",
          "bold",
          "italic",
          "underline",
          "strike",
          "link",
          "blockquote",
          "list",
          "bullet",
          "indent",
          "align",
          "color",
        ]}
      />
    </Box>
  );
};

export default HtmlField;

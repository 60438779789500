import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  Paper,
  Box,
  Divider,
  Button,
} from "@material-ui/core";
import api from "../config/api";
import Skeleton from "@material-ui/lab/Skeleton";
import { useDispatch } from "react-redux";
import { showError } from "../actions/snackbarAction";
import { getFormatedDate, getRatingPercentage } from "../utils/easyFunctions";
import Rating from "@material-ui/lab/Rating";
import appStoreLogo from "../assets/images/app-store-logo.png";
import playStoreLogo from "../assets/images/google-play-store-logo.png";

const StoreDetailLoadingSckeleton = () => (
  <>
    <Skeleton variant="text" height={40} />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
    <Skeleton variant="text" />
  </>
);

const useStyles = makeStyles((theme) => ({
  storeContainer: {
    position: "relative",
    marginTop: 60,
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  headerContainer: {
    position: "absolute",
    top: -50,
    height: 100,
    width: 100,
    margin: "auto",
    borderWidth: 2,
    borderStyle: "solid",
    borderRadius: 50,
    overflow: "hidden",
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
  },
  storeImage: {
    height: "100%",
    width: "100%",
    padding: 10,
    objectFit: "contain",
  },
  detailContainer: {
    paddingTop: 60,
    padding: theme.spacing(2),
    width: "100%",
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  ratingItemContainer: {
    height: 50,
    marginTop: 5,
    marginBottom: 5,
    width: "100%",
  },
}));

const ListItem = ({ title, detail }) => {
  const classes = useStyles();
  return (
    <>
      <Divider />
      <Box className={classes.listItem}>
        <Typography variant="button" style={{ marginRight: 10 }}>
          {title}
        </Typography>
        <Typography color="textSecondary" style={{ textAlign: "right" }}>
          {detail}
        </Typography>
      </Box>
    </>
  );
};

const RateItem = ({ color, ratePercentage, rateCount, rate }) => (
  <Box
    display="flex"
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    style={{ marginTop: 5, marginBottom: 5 }}
  >
    <Box display="flex" flexDirection="row" flex={1}>
      <Typography variant="caption">{rate}</Typography>
      <Box style={{ width: "100%" }}>
        <Box
          style={{
            backgroundColor: color,
            width: ratePercentage,
            height: "100%",
            marginLeft: 5,
            marginRight: 5,
          }}
        />
      </Box>
    </Box>
    <Typography variant="caption">{rateCount}</Typography>
  </Box>
);

const Histogram = ({ totalRatings, histogram }) => {
  return (
    <Box>
      <RateItem
        color="#57bb8a"
        ratePercentage={getRatingPercentage(histogram["5"], totalRatings)}
        rateCount={histogram["5"]}
        rate={5}
      />
      <RateItem
        color="#9ace6a"
        ratePercentage={getRatingPercentage(histogram["4"], totalRatings)}
        rateCount={histogram["4"]}
        rate={4}
      />
      <RateItem
        color="#ffcf02"
        ratePercentage={getRatingPercentage(histogram["3"], totalRatings)}
        rateCount={histogram["3"]}
        rate={3}
      />
      <RateItem
        color="#ff9f02"
        ratePercentage={getRatingPercentage(histogram["2"], totalRatings)}
        rateCount={histogram["2"]}
        rate={2}
      />
      <RateItem
        color="#ff6f31"
        ratePercentage={getRatingPercentage(histogram["1"], totalRatings)}
        rateCount={histogram["1"]}
        rate={1}
      />
    </Box>
  );
};

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [
    isAndroidAppDetailLoading,
    setAndroidAppDetailLoadingStatus,
  ] = useState(true);
  const [androidAppDetail, setAndroidAppDetail] = useState(null);

  const [isIosAppDetailLoading, setIosAppDetailLoadingStatus] = useState(true);
  const [iosAppDetail, setIosAppDetail] = useState(null);

  useEffect(() => {
    api
      .get("analytics/android-app-detail")
      .then((res) => {
        if (res.data.success) {
          setAndroidAppDetail(res.data.data);
          setAndroidAppDetailLoadingStatus(false);
        } else {
          dispatch(showError(res.data.message));
          setAndroidAppDetailLoadingStatus(false);
        }
      })
      .catch((error) => {
        dispatch(showError(error.message));
        setAndroidAppDetailLoadingStatus(false);
      });

    api
      .get("analytics/ios-app-detail")
      .then((res) => {
        if (res.data.success) {
          setIosAppDetail(res.data.data);
          setIosAppDetailLoadingStatus(false);
        } else {
          dispatch(showError(res.data.message));
          setIosAppDetailLoadingStatus(false);
        }
      })
      .catch((error) => {
        dispatch(showError(error.message));
        setIosAppDetailLoadingStatus(false);
      });

    // api
    //   .get("analytics/total-sale")
    //   .then((res) => {
    //     console.log("Total Sale");
    //     console.log(res.data);
    //     // if (res.data.success) {
    //     //   setIosAppDetail(res.data.data);
    //     //   setIosAppDetailLoadingStatus(false);
    //     // } else {
    //     //   dispatch(showError(res.data.message));
    //     //   setIosAppDetailLoadingStatus(false);
    //     // }
    //   })
    //   .catch((error) => {
    //     console.log(JSON.stringify(error.response));
    //     dispatch(showError(error.response.data.message));
    //     // setIosAppDetailLoadingStatus(false);
    //   });
  }, []);

  const openStoreUrl = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          {/* Google play store detail */}
          <Paper className={classes.storeContainer}>
            <Box className={classes.headerContainer}>
              <img
                className={classes.storeImage}
                src={playStoreLogo}
                alt="google-play-store-logo"
              />
            </Box>
            <Box className={classes.detailContainer}>
              {isAndroidAppDetailLoading ? (
                <StoreDetailLoadingSckeleton />
              ) : androidAppDetail ? (
                <Box>
                  <ListItem title="Version" detail={androidAppDetail.version} />
                  <ListItem
                    title="Last Updated"
                    detail={getFormatedDate(androidAppDetail.updated)}
                  />
                  {androidAppDetail.recentChanges && (
                    <ListItem
                      title="Recent Changes"
                      detail={`${androidAppDetail.recentChanges}`}
                    />
                  )}
                  <ListItem
                    title="Installs"
                    detail={androidAppDetail.maxInstalls}
                  />
                  <Divider />
                  <Box className={classes.listItem}>
                    <Typography variant="button" style={{ marginRight: 10 }}>
                      Reviews
                    </Typography>
                    <Box flexDirection="row" display="flex" alignItems="center">
                      <Typography
                        color="textSecondary"
                        style={{ textAlign: "right", marginRight: 10 }}
                      >
                        {androidAppDetail.reviews}
                      </Typography>
                      {/* <Button size="small" variant="outlined">
                        view all reviews
                      </Button> */}
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.listItem}>
                    <Typography variant="button" style={{ marginRight: 10 }}>
                      Ratings
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      <Rating
                        name="Ratings"
                        value={parseFloat(androidAppDetail.score, 10)}
                        readOnly
                        precision={0.1}
                      />
                      <Typography
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        Score : {androidAppDetail.scoreText}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        Total : {androidAppDetail.ratings}
                      </Typography>
                    </Box>
                  </Box>
                  <Histogram
                    totalRatings={androidAppDetail.ratings}
                    histogram={androidAppDetail.histogram}
                  />
                  <Divider />
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      marginTop: 10,
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    color="primary"
                    onClick={() => openStoreUrl(androidAppDetail.url)}
                  >
                    VIEW ON STORE
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6}>
          <Paper className={classes.storeContainer}>
            <Box className={classes.headerContainer}>
              <img
                className={classes.storeImage}
                src={appStoreLogo}
                alt="app-store-logo"
              />
            </Box>
            <Box className={classes.detailContainer}>
              {isIosAppDetailLoading ? (
                <StoreDetailLoadingSckeleton />
              ) : iosAppDetail ? (
                <Box>
                  <ListItem title="Version" detail={iosAppDetail.version} />
                  <ListItem
                    title="Last Updated"
                    detail={getFormatedDate(iosAppDetail.updated)}
                  />
                  {iosAppDetail.recentChanges && (
                    <ListItem
                      title="Recent Changes"
                      detail={`${iosAppDetail.releaseNotes}`}
                    />
                  )}
                  {/* <ListItem
                    title="Installs"
                    detail={iosAppDetail.maxInstalls}
                  /> */}
                  <Divider />
                  <Box className={classes.listItem}>
                    <Typography variant="button" style={{ marginRight: 10 }}>
                      Reviews
                    </Typography>
                    <Box flexDirection="row" display="flex" alignItems="center">
                      <Typography
                        color="textSecondary"
                        style={{ textAlign: "right", marginRight: 10 }}
                      >
                        {iosAppDetail.reviews}
                      </Typography>
                      {/* <Button size="small" variant="outlined">
                        view all reviews
                      </Button> */}
                    </Box>
                  </Box>
                  <Divider />
                  <Box className={classes.listItem}>
                    <Typography variant="button" style={{ marginRight: 10 }}>
                      Ratings
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      justifyContent="center"
                    >
                      <Rating
                        name="Ratings"
                        value={iosAppDetail.score || 0}
                        readOnly
                        precision={0.1}
                      />
                      <Typography
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        Scroe :{" "}
                        {iosAppDetail.score
                          .toString()
                          .slice(
                            0,
                            iosAppDetail.score.toString().indexOf(".") + 2
                          )}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        style={{ textAlign: "right" }}
                      >
                        Total : {iosAppDetail.ratings}
                      </Typography>
                    </Box>
                  </Box>
                  {iosAppDetail.ratings > 0 && (
                    <Histogram
                      totalRatings={iosAppDetail.ratings}
                      histogram={iosAppDetail.histogram}
                    />
                  )}
                  <Divider />
                  <Button
                    variant="contained"
                    size="large"
                    style={{
                      marginTop: 10,
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                    }}
                    color="primary"
                    onClick={() => openStoreUrl(iosAppDetail.url)}
                  >
                    VIEW ON STORE
                  </Button>
                </Box>
              ) : null}
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} xl={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h4">Total Sale</Typography>
              <Typography variant="subtitle2">$2000.00</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h4">Total Sale</Typography>
              <Typography variant="subtitle2">$2000.00</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4} xl={3}>
          <Card className={classes.card}>
            <CardContent>
              <Typography variant="h4">Total Sale</Typography>
              <Typography variant="subtitle2">$2000.00</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid> */}
    </Container>
  );
};

export default Home;

import React, { useEffect, useContext } from "react";
import {
  CircularProgress,
  makeStyles,
  Backdrop,
  Typography,
} from "@material-ui/core";
import logoDark from "../assets/images/logo_hr_dark.png";
import logoLight from "../assets/images/logo_hr_light.png";
import api from "../config/api";
import { setUser } from "../actions/userAction";
import { useDispatch } from "react-redux";
import ThemeContext from "../utils/themeContext";

const useStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
}));

const InitialLoader = ({ onLoadingComplete }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    const tokenString = localStorage.getItem("token");
    if (!tokenString) {
      onLoadingComplete({
        isValidToken: false,
      });
    } else {
      api
        .get("/users/me", {
          headers: {
            Authorization: tokenString,
          },
        })
        .then((res) => {
          if (!res.data.success) {
            setUser(null, "");
            onLoadingComplete({
              isValidToken: false,
            });
          } else {
            dispatch(setUser(res?.data?.data, tokenString));
            onLoadingComplete({
              isValidToken: true,
            });
          }
        })
        .catch((error) => {
          onLoadingComplete({
            isValidToken: false,
          });
        });
    }
  }, []);

  return (
    <Backdrop open={true} className={classes.backdrop}>
      <div style={{ display: "flex" }}>
        <img
          src={themeContext.theme === "dark" ? logoLight : logoDark}
          alt="southernhoney-logo"
          style={{ height: 80 }}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <CircularProgress size={30} />
        <Typography variant="h4" style={{ marginLeft: 10 }}>
          Loading dashboard...
        </Typography>
      </div>
    </Backdrop>
  );
};

export default InitialLoader;

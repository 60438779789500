import React from "react";
import { Typography, Card, CardContent } from "@material-ui/core";

const FormNote = () => (
  <Card variant="outlined" style={{ marginTop: 10, marginBottom: 10 }}>
    <CardContent style={{ padding: 10 }}>
      <Typography variant="body1" color="textSecondary">
        - Keep field as blank if you don't want to display it on app.
      </Typography>
    </CardContent>
  </Card>
);

export default FormNote;

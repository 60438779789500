import React, { useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import {
  TextField,
  makeStyles,
  Paper,
  FormGroup,
  Typography,
  Grid,
} from "@material-ui/core";
import LinkSelector from "./LinkSelector";
import { DEFAULT_COLOR } from "../config/data";
import ColorPicker from "./ColorPicker";

const useStyle = makeStyles(() => ({
  formField: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

const ButtonField = ({
  button = {
    label: "",
    labelColor: DEFAULT_COLOR.BUTTON_TEXT,
    backgroundColor: DEFAULT_COLOR.BUTTON_BACKGROUND,
    link: null,
  },
  onButtonChange,
  style,
}) => {
  const classes = useStyle();
  useEffect(() => {
    onButtonChange({
      label: button?.label || "",
      labelColor: button?.labelColor || DEFAULT_COLOR.BUTTON_TEXT,
      backgroundColor:
        button?.backgroundColor || DEFAULT_COLOR.BUTTON_BACKGROUND,
      link: button?.link || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      variant="outlined"
      style={{ marginTop: 20, marginBottom: 20, padding: 10, ...style }}
    >
      <Typography color="textSecondary">Button</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item md={6} xs={12} sm={12}>
          <FormGroup className={classes.formField}>
            <TextField
              id="buttonLabel"
              label="Label"
              variant="outlined"
              value={button?.label || ""}
              onChange={(e) =>
                onButtonChange({ ...button, label: e.target.value })
              }
            />
          </FormGroup>
        </Grid>

        <Grid item md={3} xs={12} sm={12}>
          <ColorPicker
            label="Label color"
            selectedColor={button?.labelColor?.rgba}
            onSelect={(color) =>
              onButtonChange({ ...button, labelColor: color })
            }
          />
        </Grid>
        <Grid item md={3} xs={12} sm={12}>
          <ColorPicker
            label="Background color"
            selectedColor={button?.backgroundColor?.rgba}
            onSelect={(color) =>
              onButtonChange({ ...button, backgroundColor: color })
            }
          />
        </Grid>
      </Grid>
      <LinkSelector
        preSelectedLink={button?.link || null}
        onScreenSelect={(screen) =>
          onButtonChange({ ...button, link: { ...button.link, screen } })
        }
        onParamSelect={(param) =>
          onButtonChange({ ...button, link: { ...button.link, param } })
        }
      />
    </Paper>
  );
};

export default ButtonField;

import React from "react";
import {
  Container,
  Grid,
  Typography,
  makeStyles,
  FormGroup,
  TextField,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { showMessage, showError } from "../actions/snackbarAction";
import api from "../config/api";
import { Formik } from "formik";
import { addNewSectionTypeValidationScheme } from "../utils/yupValidationScheme";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: theme.spacing(2),
  },
  formField: {
    marginTop: theme.spacing(2),
  },
}));

const AddNewSectionType = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const saveSectionType = (values, { setSubmitting, resetForm }) => {
    const dataToSubmit = new FormData();
    dataToSubmit.append("title", values.title);
    dataToSubmit.append("key", values.key);
    dataToSubmit.append("description", values.description);
    dataToSubmit.append("image", values.image);

    api
      .post("section-type", dataToSubmit, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        setSubmitting(false);
        if (res.data.success) {
          dispatch(showMessage(res.data.message));
          resetForm({ values: { title: "", description: "", image: null } });
        } else {
          console.log(res.data.message);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(showError(error.response.data.message));
      });
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h3">Add New Section Type</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.row}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Formik
            initialValues={{
              title: "",
              description: "",
              image: null,
            }}
            validationSchema={addNewSectionTypeValidationScheme}
            onSubmit={saveSectionType}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <form autoComplete="off">
                <Card variant="outlined">
                  <CardContent>
                    <FormGroup className={classes.formField}>
                      <TextField
                        id="title"
                        label="Title"
                        variant="outlined"
                        required
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        error={errors.title && touched.title}
                        helperText={
                          errors.title && touched.title && errors.title
                        }
                      />
                    </FormGroup>
                    <FormGroup className={classes.formField}>
                      <TextField
                        id="key"
                        label="Key"
                        variant="outlined"
                        required
                        name="key"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.key}
                        error={errors.key && touched.key}
                        helperText={errors.key && touched.key && errors.key}
                      />
                    </FormGroup>
                    <FormGroup className={classes.formField}>
                      <TextField
                        id="description"
                        label="Description"
                        name="description"
                        multiline
                        rows={4}
                        variant="outlined"
                        required
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        error={errors.description && touched.description}
                        helperText={
                          errors.description &&
                          touched.description &&
                          errors.description
                        }
                      />
                    </FormGroup>
                    <FormGroup className={classes.formField}>
                      <TextField
                        id="image"
                        label="Select Section image"
                        placeholder="Select section image"
                        variant="outlined"
                        inputProps={{
                          type: "file",
                          "aria-label": "section_image",
                        }}
                        name="image"
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText="Image of how section will be look like. You can add screenshot of image latter"
                        onChange={(e) => {
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                    </FormGroup>
                  </CardContent>
                  <CardActions>
                    <Button
                      title="Save"
                      variant="contained"
                      color="secondary"
                      onClick={() => history.goBack()}
                    >
                      BACK
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      title="Save"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      // onClick={() => createSectionType()}
                    >
                      {isSubmitting ? "Saving" : "Save"}
                      {isSubmitting && (
                        <CircularProgress
                          size={20}
                          color="primary"
                          style={{ marginLeft: 5 }}
                        />
                      )}
                    </Button>
                  </CardActions>
                </Card>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddNewSectionType;

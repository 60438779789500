import React from "react";
import { Typography } from "@material-ui/core";
import {
  Fulfilled,
  Partial,
  Restocked,
  Unfullfilled,
} from "../components/Labels";

const FulfillmentStatus = ({ fullfillmentStatus }) => {
  switch (fullfillmentStatus) {
    case "fulfilled": {
      return <Fulfilled />;
    }
    case null: {
      return <Unfullfilled />;
    }
    case "partial": {
      return <Partial />;
    }
    case "restocked": {
      return <Restocked />;
    }
    default: {
      return <Typography variant="caption">{fullfillmentStatus}</Typography>;
    }
  }
};

export default FulfillmentStatus;

import React from "react";
import { Chip } from "@material-ui/core";

const LABEL_COLOR = {
  pending: "#f50057",
  unfullfilled: "#f50057",
  paid: "#e4e5e7",
  authorized: "#008060",
  partially_paid: "#ffd79d",
  partially_refunded: "#02ab80",
  refunded: "#2596be",
  voided: "#3FBF7F",
  fulfilled: "#008060",
  partial: "#ffd79d",
  restocked: "#2596be",
};

export const Unfullfilled = () => (
  <Chip
    label="Unfullfilled"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.unfullfilled, color: "#000000" }}
  />
);

export const Paid = () => (
  <Chip
    label="Paid"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.paid, color: "#000000" }}
  />
);

export const Pending = () => (
  <Chip
    label="Pending"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.pending, color: "#000000" }}
  />
);

export const Authorized = () => (
  <Chip
    label="Authorized"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.authorized, color: "#000000" }}
  />
);

export const PartiallyPaid = () => (
  <Chip
    label="Partially Paid"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.partially_paid, color: "#000000" }}
  />
);

export const PartiallyRefunded = () => (
  <Chip
    label="Partially Refunded"
    size="small"
    style={{
      backgroundColor: LABEL_COLOR.partially_refunded,
      color: "#000000",
    }}
  />
);

export const Refunded = () => (
  <Chip
    label="Refunded"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.refunded, color: "#000000" }}
  />
);

export const Voided = () => (
  <Chip
    label="Voided"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.voided, color: "#000000" }}
  />
);

export const Fulfilled = () => (
  <Chip
    label="Fullfiled"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.fulfilled, color: "#000000" }}
  />
);

export const Partial = () => (
  <Chip
    label="Partial"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.partial, color: "#000000" }}
  />
);

export const Restocked = () => (
  <Chip
    label="Restocked"
    size="small"
    style={{ backgroundColor: LABEL_COLOR.restocked, color: "#000000" }}
  />
);

import React, { useEffect, useState } from "react";
import {
  FormGroup,
  FormControl,
  Select,
  MenuItem,
  Typography,
  makeStyles,
  InputLabel,
  Grid,
  TextField,
  Paper,
} from "@material-ui/core";
import ImageSelector from "./ImageSelector";
import ColorPicker from "./ColorPicker";
import PadMarField from "./PadMarField";
import { DEFAULT_COLOR } from "../config/data";

const useStyles = makeStyles((theme) => ({
  formField: {
    marginTop: 10,
    marginBottom: 10,
  },
  marPadField: {
    paddingTop: 10,
  },
}));

const SectionCommonStyleCreator = ({
  extraStyle = {
    width: "100%",
    paddingTop: "auto",
    paddingRight: "auto",
    paddingBottom: "auto",
    paddingLeft: "auto",
    marginTop: "auto",
    marginRight: "auto",
    marginBottom: "auto",
    marginLeft: "auto",
    backgroundColor: DEFAULT_COLOR.SIMPLE_BACKGROUND,
    backgroundImage: null,
  },
  onExtraStyleChange,
  style,
}) => {
  const classes = useStyles();
  const [extraStyleValue, setExtraStyleValue] = useState(
    !extraStyle
      ? {
          width: "100%",
          paddingTop: "auto",
          paddingRight: "auto",
          paddingBottom: "auto",
          paddingLeft: "auto",
          marginTop: "auto",
          marginRight: "auto",
          marginBottom: "auto",
          marginLeft: "auto",
          backgroundColor: DEFAULT_COLOR.SIMPLE_BACKGROUND,
          backgroundImage: null,
        }
      : extraStyle
  );

  useEffect(() => {
    // setExtraStyleValue(extraStyle);
    if (!extraStyle) {
      setExtraStyleValue({
        width: "100%",
        paddingTop: "auto",
        paddingRight: "auto",
        paddingBottom: "auto",
        paddingLeft: "auto",
        marginTop: "auto",
        marginRight: "auto",
        marginBottom: "auto",
        marginLeft: "auto",
        backgroundColor: DEFAULT_COLOR.SIMPLE_BACKGROUND,
        backgroundImage: null,
      });
      onExtraStyleChange({
        width: "100%",
        paddingTop: "auto",
        paddingRight: "auto",
        paddingBottom: "auto",
        paddingLeft: "auto",
        marginTop: "auto",
        marginRight: "auto",
        marginBottom: "auto",
        marginLeft: "auto",
        backgroundColor: DEFAULT_COLOR.SIMPLE_BACKGROUND,
        backgroundImage: null,
      });
    } else {
      setExtraStyleValue(extraStyle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraStyle]);

  const updateParentValue = () => {
    onExtraStyleChange(extraStyleValue);
  };

  return (
    <Paper
      variant="outlined"
      style={{ marginTop: 20, marginBottom: 20, padding: 10, ...style }}
    >
      <Typography color="textSecondary">Section other style</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={6}>
          <FormControl
            className={classes.formField}
            variant="outlined"
            fullWidth
          >
            <InputLabel id="widthLabel">Width</InputLabel>
            <Select
              labelId="widthLabel"
              label="Width"
              variant="outlined"
              value={extraStyleValue?.width || "100%"}
              onChange={(e) =>
                setExtraStyleValue({
                  ...extraStyleValue,
                  width: e.target.value,
                })
              }
              onBlur={updateParentValue}
            >
              <MenuItem value="100%">100%</MenuItem>
              <MenuItem value="75%">75%</MenuItem>
              <MenuItem value="50%">50%</MenuItem>
              <MenuItem value="33%">33%</MenuItem>
              <MenuItem value="25%">25%</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ColorPicker
            label="Background Color"
            selectedColor={
              extraStyleValue.backgroundColor?.rgba ||
              DEFAULT_COLOR.SIMPLE_BACKGROUND.rgba
            }
            onSelect={(color) => {
              setExtraStyleValue({
                ...extraStyleValue,
                backgroundColor: color,
              });
              onExtraStyleChange({
                ...extraStyleValue,
                backgroundColor: color,
              });
            }}
          />
        </Grid>

        <Grid item spacing={1} xs={12} md={6} lg={6}>
          <Paper variant="outlined" style={{ padding: 10 }}>
            <InputLabel>Padding</InputLabel>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PadMarField
                  label="Top"
                  value={extraStyleValue.paddingTop}
                  onChange={(value) =>
                    setExtraStyleValue({
                      ...extraStyleValue,
                      paddingTop: value,
                    })
                  }
                  onBlur={updateParentValue}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PadMarField
                  label="Right"
                  value={extraStyleValue.paddingRight}
                  onChange={(value) =>
                    setExtraStyleValue({
                      ...extraStyleValue,
                      paddingRight: value,
                    })
                  }
                  onBlur={updateParentValue}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PadMarField
                  label="Bottom"
                  value={extraStyleValue.paddingBottom}
                  onChange={(value) =>
                    setExtraStyleValue({
                      ...extraStyleValue,
                      paddingBottom: value,
                    })
                  }
                  onBlur={updateParentValue}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PadMarField
                  label="Left"
                  value={extraStyleValue.paddingLeft}
                  onChange={(value) =>
                    setExtraStyleValue({
                      ...extraStyleValue,
                      paddingLeft: value,
                    })
                  }
                  onBlur={updateParentValue}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item spacing={1} xs={12} md={6} lg={6}>
          <Paper variant="outlined" style={{ padding: 10 }}>
            <InputLabel>Margin</InputLabel>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PadMarField
                  label="Top"
                  value={extraStyleValue.marginTop}
                  onChange={(value) =>
                    setExtraStyleValue({ ...extraStyleValue, marginTop: value })
                  }
                  onBlur={updateParentValue}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PadMarField
                  label="Right"
                  value={extraStyleValue.marginRight}
                  onChange={(value) =>
                    setExtraStyleValue({
                      ...extraStyleValue,
                      marginRight: value,
                    })
                  }
                  onBlur={updateParentValue}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PadMarField
                  label="Bottom"
                  value={extraStyleValue.marginBottom}
                  onChange={(value) =>
                    setExtraStyleValue({
                      ...extraStyleValue,
                      marginBottom: value,
                    })
                  }
                  onBlur={updateParentValue}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <PadMarField
                  label="Left"
                  value={extraStyleValue.marginLeft}
                  onChange={(value) =>
                    setExtraStyleValue({
                      ...extraStyleValue,
                      marginLeft: value,
                    })
                  }
                  onBlur={updateParentValue}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <FormGroup className={classes.formField}>
        <InputLabel style={{ marginTop: 10 }}>Background Image</InputLabel>
        <ImageSelector
          selectedImage={extraStyleValue.backgroundImage}
          onImageChange={(image) => {
            setExtraStyleValue({ ...extraStyleValue, backgroundImage: image });
            onExtraStyleChange({ ...extraStyleValue, backgroundImage: image });
          }}
          imageUploadFolder="App-Content"
        />
      </FormGroup>
    </Paper>
  );
};

export default SectionCommonStyleCreator;

import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AddAlertIcon from "@material-ui/icons/AddAlert";
import Container from "@material-ui/core/Container";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import api from "../config/api";
import PushNotificationItemAction from "../components/PushNotificationItemAction";
import {
  Completed,
  Scheduled,
  Failed,
  Draft,
  InProgress,
  Canceled,
} from "../components/NotificationStatus";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showError } from "../actions/snackbarAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowX: "auto",
  },
  paper: {
    width: "100%",
  },
  table: {
    minWidth: 750,
  },
  container: {
    maxHeight: 440,
    maxWidth: "100%",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const COLUMNS = [
  {
    key: "title",
    label: "Title",
    minWidth: 200,
    align: "left",
  },
  {
    key: "message",
    label: "Message",
    minWidth: 300,
    align: "left",
  },
  {
    key: "status",
    label: "Status",
    minWidth: 150,
    align: "center",
  },
  {
    key: "target",
    label: "Target",
    minWidth: 200,
    align: "left",
  },
  {
    key: "time",
    label: "Time",
    minWidth: 250,
    align: "left",
  },
  {
    key: "action",
    label: "Action",
    minWidth: "auto",
    align: "right",
  },
];

const PushNotification = () => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isFetching, setFetchingStatus] = useState(true);

  const getNotifications = useCallback((param) => {
    setFetchingStatus(true);
    api
      .post("push-notification", {
        limit: param.limit,
        page: param.page,
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setNotifications(res.data.data.docs);
          setCount(res.data.data.totalDocs);
          setPage(res.data.data.page);
          setFetchingStatus(false);
        } else {
          setFetchingStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setFetchingStatus(false);
        dispatch(showError(error.message));
      });
  }, []);

  useEffect(() => {
    getNotifications({ limit, page });
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    getNotifications({ limit: event.target.value, page: 1 });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    getNotifications({ limit, page: newPage + 1 });
  };

  const renderStatus = (status) => {
    switch (status) {
      case "Completed": {
        return <Completed />;
      }
      case "Scheduled": {
        return <Scheduled />;
      }
      case "Failed": {
        return <Failed />;
      }
      case "Draft": {
        return <Draft />;
      }
      case "In Progress": {
        return <InProgress />;
      }
      case "Canceled": {
        return <Canceled />;
      }
      default: {
        return <Typography variant="caption">{status}</Typography>;
      }
    }
  };

  const navigateToCreateNotification = () => {
    history.push("/push-notifications/create");
  };

  const onActionSuccess = () => {
    getNotifications({ page, limit });
  };

  return (
    <Container>
      <Grid container justify="space-between" alignItems="center">
        <Grid item xs={11} sm={11} md={"auto"} lg={"auto"} xl={"auto"}>
          <Typography variant="h3">Push Notifications</Typography>
        </Grid>
        <Grid item xs={1} sm={1} md={"auto"} lg={"auto"} xl={"auto"}>
          <IconButton onClick={navigateToCreateNotification}>
            <AddAlertIcon />
          </IconButton>
        </Grid>
        <Paper className={classes.root}>
          <TableContainer>
            <Table
              stickyHeader
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.key}
                      style={{ minWidth: column.minWidth }}
                      align={column.align}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications.map((notification, index) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={notification._id}
                  >
                    <TableCell align={"left"}>{notification.title}</TableCell>
                    <TableCell align={"left"}>{notification.message}</TableCell>
                    <TableCell align={"center"}>
                      {renderStatus(notification.status)}
                    </TableCell>
                    <TableCell align={"left"}>
                      {notification.target.title}
                    </TableCell>
                    <TableCell align={"left"}>
                      {new Date(notification.time).toLocaleDateString()} At{" "}
                      {new Date(notification.time).toLocaleTimeString()}
                    </TableCell>
                    <TableCell align={"right"}>
                      <PushNotificationItemAction
                        notification={notification}
                        onActionSuccess={onActionSuccess}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 20, 30, 40, 50]}
            component="div"
            count={count}
            rowsPerPage={limit}
            page={page - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Backdrop open={isFetching} className={classes.backdrop}>
            <CircularProgress />
          </Backdrop>
        </Paper>
      </Grid>
    </Container>
  );
};

export default PushNotification;

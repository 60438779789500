export const TOPIC_GENERAL = "TOPIC-GENERAL";
export const TOPIC_LOGEDIN = "TOPIC-LOGEDIN";
export const TOPIC_GUEST = "TOPIC-GUEST";
export const TOPIC_ANDROID_USERS = "TOPIC-ANDROID-USERS";
export const TOPIC_IOS_USERS = "TOPIC-IOS-USERS";
export const TOPIC_LOGEDIN_IOS_USERS = "TOPIC-LOGEDIN-IOS-USERS";
export const TOPIC_LOGEDIN_ANDROID_USERS = "TOPIC-LOGEDIN-ANDROID-USERS";
export const TOPIC_GUEST_IOS_USERS = "TOPIC-GUEST-IOS-USERS";
export const TOPIC_GUEST_ANDROID_USERS = "TOPIC-GUEST-ANDROID-USERS";

const pushNotificationTopics = {
  TOPIC_GENERAL: {
    title: "All Users",
    topic: TOPIC_GENERAL,
  },
  TOPIC_LOGEDIN: {
    title: "Only Logged-In users",
    topic: TOPIC_LOGEDIN,
  },
  TOPIC_GUEST: {
    title: "Only guest users",
    topic: TOPIC_GUEST,
  },
  TOPIC_ANDROID_USERS: {
    title: "All Android users",
    topic: TOPIC_ANDROID_USERS,
  },
  TOPIC_LOGEDIN_ANDROID_USERS: {
    title: "Only Logged-In Android users",
    topic: TOPIC_LOGEDIN_ANDROID_USERS,
  },
  TOPIC_GUEST_ANDROID_USERS: {
    title: "Only guest Android users",
    topic: TOPIC_GUEST_ANDROID_USERS,
  },
  TOPIC_IOS_USERS: {
    title: "All iOS users",
    topic: TOPIC_IOS_USERS,
  },
  TOPIC_LOGEDIN_IOS_USERS: {
    title: "Only Logged-In iOS users",
    topic: TOPIC_LOGEDIN_IOS_USERS,
  },
  TOPIC_GUEST_IOS_USERS: {
    title: "Only guest iOS users",
    topic: TOPIC_GUEST_IOS_USERS,
  },
};

export default pushNotificationTopics;

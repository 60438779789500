import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
} from "@material-ui/core";
import LinkSelector from "../../components/LinkSelector";
import ImageSelector from "../../components/ImageSelector";
import FormNote from "../../components/FormNote";
import HtmlField from "../../components/HtmlField";
import ButtonField from "../../components/ButtonField";
import TitleCreator from "../../components/TitleCreator";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
  formField: {
    marginTop: theme.spacing(2),
  },
  row: {
    marginTop: 10,
    marginBottom: 10,
  },
  media: {
    height: 200,
    width: "100%",
    objectFit: "contain",
    marginTop: 10,
    marginBottom: 10,
  },
}));

const AddSlideDialog = ({ open, handleClose, onAddSlide, slideToEdit }) => {
  const classes = useStyles();
  const [slideData, setSlideData] = useState();
  const { values, handleSubmit, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues: !slideData
      ? {
          image: null,
          heading: null,
          subHeading: null,
          headingDivider: null,
          text: "",
          link: {
            screen: "",
            param: null,
          },
          button: null,
        }
      : slideData,
    onSubmit: (values) => onSave(values),
  });

  useEffect(() => {
    setSlideData(slideToEdit);
  }, [slideToEdit]);

  const onSave = (values) => {
    onAddSlide({
      _id: slideToEdit ? slideToEdit._id : "",
      ...values,
    });
    resetForm();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      keepMounted={false}
    >
      <DialogTitle id="scroll-dialog-title">Add Slide</DialogTitle>

      <DialogContent dividers>
        <form>
          <FormNote />
          <ImageSelector
            onImageChange={(image) => setFieldValue("image", image)}
            selectedImage={values.image}
            imageUploadFolder="App-Content/Slider"
          />
          <LinkSelector
            preSelectedLink={values.link}
            onScreenSelect={(screen) =>
              setFieldValue("link", { ...values.link, screen })
            }
            onParamSelect={(param) =>
              setFieldValue("link", { ...values.link, param })
            }
          />
          <TitleCreator
            heading={values.heading}
            onHeadingChange={(value) => setFieldValue("heading", value)}
            subHeading={values.subHeading}
            onSubHeadingChange={(value) => setFieldValue("subHeading", value)}
            headingDivider={values.headingDivider}
            onHeadingDividerChange={(newHeadingDivider) => {
              setFieldValue("headingDivider", newHeadingDivider);
            }}
          />
          <FormGroup className={classes.formField}>
            <HtmlField
              value={values.text}
              onChange={(value) => setFieldValue("text", value)}
            />
          </FormGroup>
          <ButtonField
            button={values.button}
            onButtonChange={(button) => setFieldValue("button", button)}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="contained">
          CANCEL
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          NEXT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSlideDialog;

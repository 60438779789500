import React, { useState } from "react";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  ButtonBase,
  makeStyles,
  Typography,
  Box,
  FormGroup,
  InputLabel,
} from "@material-ui/core";
import FormNote from "./FormNote";
import HtmlField from "./HtmlField";
import ButtonField from "./ButtonField";
import ImageSelector from "./ImageSelector";
import SectionCommonStyleCreator from "./SectionCommonStyleCreator";
import TitleCreator from "./TitleCreator";
import { useFormik } from "formik";

const useStyle = makeStyles(() => ({
  formField: {
    marginTop: 10,
    marginBottom: 10,
  },
  media: {
    height: 100,
    width: 100,
  },
}));

const IWTOCreator = ({ initialallyOpen, onContentCreate, preData }) => {
  const classes = useStyle();
  const [isOpen, setOpenStatus] = useState(initialallyOpen || false);
  const {
    values,
    setFieldValue,
    handleBlur,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: preData
      ? preData
      : {
          heading: {},
          subHeading: {},
          headingDivider: null,
          text: "",
          image: {},
          button: null,
          extraStyle: null,
        },
    onSubmit: (values) => onSave(values),
  });

  const openDialog = () => {
    setOpenStatus(true);
  };

  const closeDialog = () => {
    setOpenStatus(false);
    resetForm();
  };

  const onSave = (values) => {
    onContentCreate({ ...values });
    closeDialog();
  };

  return (
    <Box>
      <ButtonBase onClick={openDialog} style={{ margin: 10, width: "100%" }}>
        <Typography>
          {values.heading?.text || "Image with text overlay"}
        </Typography>
      </ButtonBase>
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogContent>
          <FormNote />

          <TitleCreator
            heading={values.heading}
            onHeadingChange={(updatedHeading) =>
              setFieldValue("heading", updatedHeading)
            }
            subHeading={values.subHeading}
            onSubHeadingChange={(updatedSubHeading) =>
              setFieldValue("subHeading", updatedSubHeading)
            }
            headingDivider={values.headingDivider}
            onHeadingDividerChange={(newHd) => {
              setFieldValue("headingDivider", newHd);
            }}
          />
          <FormGroup className={classes.formField}>
            <HtmlField
              label="Text"
              value={values.text}
              onChange={(value) => setFieldValue("text", value)}
              onBlur={() => handleBlur("text")}
            />
          </FormGroup>

          <FormGroup className={classes.formField}>
            <InputLabel>Image</InputLabel>
            <ImageSelector
              selectedImage={values.image}
              onImageChange={(updatedImage) =>
                setFieldValue("image", updatedImage)
              }
              imageUploadFolder="App-Content"
            />
          </FormGroup>

          <ButtonField
            button={values.button}
            onButtonChange={(button) => setFieldValue("button", button)}
          />
          <SectionCommonStyleCreator
            extraStyle={values.extraStyle}
            onExtraStyleChange={(extraStyle) =>
              setFieldValue("extraStyle", extraStyle)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={closeDialog} variant="contained">
            CANCEL
          </Button>
          <Button color="primary" onClick={handleSubmit} variant="contained">
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default IWTOCreator;

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Checkbox,
  Backdrop,
  CircularProgress,
  ButtonBase,
  Card,
  CardContent,
  IconButton,
  Box,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import { useQuery } from "@apollo/client";
import {
  GET_PRODUCTS_LIST,
  GET_PRODUCTS_BY_IDS,
} from "../shopifyStorefrontQueries/queries/product";
import { IMAGE_PLACEHOLDER } from "../config/data";
import SearchBar from "material-ui-search-bar";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyle = makeStyles((theme) => ({
  root: {
    "&.MuiDialog-paperFullWidth": {
      width: "calc(100% - 64px)",
      minHeight: "100%",
    },
    marginBottom: 20,
  },
  selectButton: {
    width: "100%",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    borderWidth: 1,
    borderStyle: "dashed",
  },
  ".MuiDialog-paperFullWidth": {
    width: "calc(100% - 64px)",
    minHeight: "100%",
  },
  dialog: {
    ".MuiDialog-paperFullWidth": {
      width: "calc(100% - 64px)",
      minHeight: "100%",
    },
  },
  emptyListContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer,
    color: "#fff",
    position: "absolute",
    top: 0,
    bottom: 0,
    height: "100%",
  },
}));

const LoadingPlaceholder = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <ListItem key={i.toString()} button divider>
          <ListItemAvatar>
            <Skeleton height={50}>
              <Avatar />
            </Skeleton>
          </ListItemAvatar>
          <Skeleton variant="text" width={300} />
        </ListItem>
      ))}
    </>
  );
};

const ProductsSelector = ({ onProductsSelect, preSelectedProducts }) => {
  const classes = useStyle();
  const [searchQuery, setSearchQuery] = useState("");
  const { loading, data, fetchMore, refetch } = useQuery(GET_PRODUCTS_LIST, {
    variables: {
      query: searchQuery !== "" ? `title:*${searchQuery}*` : "",
    },
  });
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const selectedProductQuery = useQuery(GET_PRODUCTS_BY_IDS, {
    variables: {
      ids: selectedProductIds,
    },
    onCompleted: (result) => {
      console.log(result);
      if (result.nodes) {
        setSelectedProducts(result.nodes);
      }
    },
  });

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);

  const [isOpen, setOpenStatus] = useState(false);

  useEffect(() => {
    if (preSelectedProducts) {
      // setSelectedProductIds(
      //   preSelectedProducts.map((product) => product.node.id)
      // );
      // setSelectedProducts(preSelectedProducts);
      setSelectedProductIds(preSelectedProducts);
    }
  }, [preSelectedProducts]);

  const openDialog = () => {
    setOpenStatus(true);
  };

  const closeDialog = () => {
    setOpenStatus(false);
  };

  const loadNextPage = () => {
    if (data.products?.pageInfo?.hasNextPage && !isNextPageLoading) {
      setIsNextPageLoading(true);
      fetchMore({
        variables: {
          cursor: data.products.edges[data.products.edges.length - 1].cursor,
        },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          setIsNextPageLoading(false);
          const newEdges = fetchMoreResult.products.edges;
          const newPageInfo = fetchMoreResult.products.pageInfo;
          return newEdges.length
            ? {
                products: {
                  edges: [...prevResult.products.edges, ...newEdges],
                  pageInfo: newPageInfo,
                  __typename: prevResult.products.__typename,
                },
              }
            : prevResult;
        },
      });
    }
  };

  const handleToggle = (productId) => {
    const currentIndex = selectedProductIds.indexOf(productId);
    const newChecked = [...selectedProductIds];

    if (currentIndex === -1) {
      newChecked.push(productId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedProductIds(newChecked);
  };

  const onSearch = () => {
    if (searchQuery !== "") {
      refetch({
        query: `*${searchQuery}*`,
      });
    } else {
      refetch({ query: "" });
    }
  };

  const onRemoveSelectedProduct = (productId) => {
    // const avalableProductIds = [...selectedProductIds];
    // setSelectedProducts(
    //   selectedProducts.filter(
    //     (item) => item.node.id !== avalableProductIds[index]
    //   )
    // );
    onProductsSelect(selectedProductIds.filter((id) => id !== productId));
    // avalableProductIds.splice(index, 1);
    setSelectedProductIds(selectedProductIds.filter((id) => id !== productId));
  };

  const onNext = () => {
    // const productsToAdd = data.products.edges.filter(
    //   (item) => selectedProductIds.indexOf(item.node.id) > -1
    // );
    // setSelectedProducts(productsToAdd);
    onProductsSelect(selectedProductIds);
    closeDialog();
  };

  return (
    <Box
      className={classes.root}
      border={1}
      borderColor="grey.700"
      borderRadius="borderRadius"
      padding={1}
    >
      <Typography variant="subtitle1">Products</Typography>
      <Card style={{ marginBottom: 10 }}>
        <CardContent style={{ position: "relative" }}>
          <List
            style={{
              width: "100%",
              position: "relative",
              overflow: "auto",
              maxHeight: 300,
            }}
          >
            {selectedProductIds.length === 0 ? (
              <Typography>Please Select Products</Typography>
            ) : (
              selectedProducts
                .filter((sp) => sp !== null)
                .map((product, index) => {
                  const labelId = `checkbox-list-secondary-label-${product.id}`;
                  return (
                    <ListItem key={product.id} button divider>
                      <ListItemAvatar>
                        <Avatar
                          style={{
                            height: 50,
                            width: 50,
                            marginRight: 10,
                          }}
                          variant="square"
                          alt={
                            product.images.edges[0]
                              ? product.images.edges[0].node.altText
                              : "no_image"
                          }
                          src={
                            product.images.edges[0]
                              ? product.images.edges[0].node.originalSrc
                              : IMAGE_PLACEHOLDER
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={product.title} />
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Delete this sub heading"
                          onClick={() => onRemoveSelectedProduct(product.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
            )}
          </List>
          <Backdrop
            open={selectedProductQuery.loading}
            className={classes.backdrop}
          >
            <CircularProgress />
          </Backdrop>
        </CardContent>
      </Card>
      <ButtonBase className={classes.selectButton} onClick={openDialog}>
        <Typography>
          {preSelectedProducts?.length > 0 ? "EDIT PRODUCT" : "SELECT PRODUCTS"}
        </Typography>
      </ButtonBase>

      <Dialog
        open={isOpen}
        onClose={closeDialog}
        TransitionComponent={Transition}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        className={classes.dialog}
      >
        <DialogTitle id="scroll-dialog-title" style={{ padding: 10 }}>
          <Typography variant="h6">Select Products</Typography>
          <SearchBar
            value={searchQuery}
            onChange={(newValue) => setSearchQuery(newValue)}
            onRequestSearch={onSearch}
            onCancelSearch={() => {
              setSearchQuery("");
              refetch({ query: "" });
            }}
          />
        </DialogTitle>
        <DialogContent dividers style={{ padding: 0 }}>
          <List dense>
            {loading || !data || !data.products ? (
              <LoadingPlaceholder />
            ) : (
              data &&
              data.products &&
              data.products.edges
                .filter((edge) => edge !== null)
                .map((product) => {
                  const labelId = `checkbox-list-secondary-label-${product.node.id}`;
                  return (
                    <ListItem
                      key={product.node.id}
                      button
                      divider
                      onClick={() => handleToggle(product.node.id)}
                    >
                      <ListItemAvatar>
                        <Avatar
                          style={{ height: 50, width: 50, marginRight: 10 }}
                          variant="square"
                          alt={
                            product.node?.images?.edges[0]
                              ? product.node.images.edges[0].node.altText
                              : "no_image"
                          }
                          src={
                            product.node?.images?.edges[0]
                              ? product.node.images.edges[0].node.transformedSrc
                              : IMAGE_PLACEHOLDER
                          }
                        />
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={product.node.title} />
                      <ListItemSecondaryAction>
                        <Checkbox
                          edge="end"
                          onChange={() => handleToggle(product.node.id)}
                          checked={
                            selectedProductIds.indexOf(product.node.id) !== -1
                          }
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
            )}
            {data && data.products?.pageInfo?.hasNextPage && (
              <ListItem
                button
                divider
                onClick={() => loadNextPage()}
                style={{
                  justifyContent: "center",
                  paddingTop: 10,
                  paddingBottom: 10,
                }}
              >
                {isNextPageLoading ? (
                  <CircularProgress size={25} />
                ) : (
                  <Typography>LOAD MORE</Typography>
                )}
              </ListItem>
            )}
          </List>
          {!loading && data?.products?.edges?.length === 0 && (
            <Box className={classes.emptyListContainer}>
              <Typography variant="h4">Product not found</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary" variant="contained">
            CANCEL
          </Button>
          <Button
            onClick={onNext}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            NEXT
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductsSelector;

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getFormatedDate = (normalDate) => {
  const date = new Date(normalDate);
  return `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const getRatingPercentage = (ratingToCalculate, totalRating) => {
  if (!ratingToCalculate) {
    return "0%";
  }
  const rate = (ratingToCalculate * 100) / totalRating;
  return `${rate}%`;
};

import React from "react";
import { Typography } from "@material-ui/core";

const Price = ({ amount, currency }) => {
  let currencyLabel = "$";
  if (currency === "USD") {
    currencyLabel = "$";
  }
  return (
    <Typography display="inline">
      {currencyLabel} {parseFloat(amount).toFixed(2)}
    </Typography>
  );
};

export default Price;

import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import shopifyApolloClient from "./config/shopifyApolloClient";
import CssBaseline from "@material-ui/core/CssBaseline";
import Router from "./Router";
import configureStore from "./store/configureStore";
import SuccessSnackbar from "./components/SuccessSnackbar";
import ErrorSnackbar from "./components/ErrorSnackbar";
import ThemeContext from "./utils/themeContext";
import { dark, light } from "./theme";
import { createMuiTheme } from "@material-ui/core/styles";

const store = configureStore();

const App = () => {
  const getTheme = () => {
    const storedTheme = localStorage.getItem("theme");
    if (!storedTheme) {
      return "dark";
    }
    return storedTheme;
  };

  const [theme, setTheme] = React.useState(getTheme());

  const toggleTheme = () => {
    const nextTheme = theme === "light" ? "dark" : "light";
    localStorage.setItem("theme", nextTheme);
    setTheme(nextTheme);
  };

  const appliedTheme = createMuiTheme(theme === "dark" ? dark : light);

  return (
    <ApolloProvider client={shopifyApolloClient}>
      <Provider store={store}>
        <ThemeContext.Provider value={{ theme, toggleTheme }}>
          <ThemeProvider theme={appliedTheme}>
            <CssBaseline />
            <Router />
            <SuccessSnackbar />
            <ErrorSnackbar />
          </ThemeProvider>
        </ThemeContext.Provider>
      </Provider>
    </ApolloProvider>
  );
};

export default App;

import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardActions,
  Button,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { IMAGE_PLACEHOLDER } from "../config/data";
import Zoom from "react-medium-image-zoom";
import api from "../config/api";
import { useDispatch } from "react-redux";
import { showError } from "../actions/snackbarAction";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: 10,
    position: "relative",
    marginTop: 10,
    marginBottom: 10,
  },
  media: {
    height: 200,
    width: 200,
    objectFit: "contain",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer,
    color: "#fff",
    position: "absolute",
  },
}));

const ImageUploader = ({
  onImageChange,
  selectedImage,
  imageUploadFolder = "App-Content",
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [image, setImage] = useState(selectedImage || null);
  const imageInput = useRef(null);
  const [isLoading, setLoadingStatus] = useState(false);

  useEffect(() => {
    setImage(selectedImage);
  }, [selectedImage]);

  const onImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (image && image.publicId) {
        setLoadingStatus(true);
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        formData.append("publicId", image.publicId);
        api
          .post("update-image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.success) {
              setLoadingStatus(false);
              setImage(res.data.data.image);
              onImageChange(res.data.data.image);
            } else {
              setLoadingStatus(false);
              dispatch(showError(res.data.message));
            }
          })
          .catch((error) => {
            setLoadingStatus(false);
            dispatch(showError(error.message));
          });
      } else {
        setLoadingStatus(true);
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        formData.append("folder", imageUploadFolder);
        api
          .post("upload-image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data.success) {
              setLoadingStatus(false);
              setImage(res.data.data.image);
              onImageChange(res.data.data.image);
            } else {
              setLoadingStatus(false);
              dispatch(showError(res.data.message));
            }
          })
          .catch((error) => {
            setLoadingStatus(false);
            dispatch(showError(error.message));
          });
      }
    }
  };

  const onRemoveImage = () => {
    if (!image.publicId) {
      setImage(null);
      imageInput.current.value = "";
      onImageChange(null);
    } else {
      setLoadingStatus(true);
      api
        .post("remove-image", { publicId: image.publicId })
        .then((res) => {
          if (res.data.success) {
            setImage(null);
            imageInput.current.value = "";
            onImageChange(null);
            setLoadingStatus(false);
          } else {
            setLoadingStatus(false);
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setLoadingStatus(false);
          dispatch(showError(error.response.data.message));
        });
    }
  };

  return (
    <Card variant="outlined" className={classes.container}>
      <Zoom>
        <img
          src={image?.url || IMAGE_PLACEHOLDER}
          alt={image?.publicId || "NO_IMAGE"}
          className={classes.media}
        />
      </Zoom>
      <CardActions style={{ justifyContent: "center", alignItems: "center" }}>
        <Button variant="outlined" component="label" className={classes.row}>
          {image && image.url ? "Change Image" : "Upload Image"}
          <input type="file" hidden ref={imageInput} onChange={onImageUpload} />
        </Button>
        {image && image.url && (
          <Button variant="outlined" onClick={onRemoveImage}>
            REMOVE IMAGE
          </Button>
        )}
      </CardActions>
      <Backdrop open={isLoading} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </Card>
  );
};

export default ImageUploader;

import React, { useEffect, useState, useCallback, useContext } from "react";
import {
  makeStyles,
  Container,
  Typography,
  Grid,
  Paper,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Add as AddIcon } from "@material-ui/icons";
import ChangePasswordModal from "./ChangePasswordModal";
import ResetPasswordModal from "./ResetPasswordModal";
import CreateUserModal from "./CreateUserModal";
import api from "../config/api";
import { useDispatch, useSelector } from "react-redux";
import { showError, showMessage } from "../actions/snackbarAction";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import useToken from "../utils/useToken";
import ThemeContext from "../utils/themeContext";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    flex: 1,
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  backdrop: {
    zIndex: theme.zIndex.tooltip + 1,
    color: "#fff",
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer.user);
  const themeContext = useContext(ThemeContext);
  const [isOpenChangePasswordModal, toggleChangePasswordModal] = useState(
    false
  );
  const [isOpenResetPasswordModal, toggleResetPasswordModal] = useState(false);
  const [isCreateUserModalOpen, toggleCreateUserModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [isLoading, setLoadingStatus] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const { token } = useToken();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const open = Boolean(anchorEl);
  const [
    isOpenDelConfirmationDialog,
    setIsOpenDelConfirmationDialogStatus,
  ] = useState(false);

  const getUsers = useCallback(async (param) => {
    setLoadingStatus(true);
    api
      .post(
        "users/list",
        {
          limit: param.limit,
          page: param.page,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          setUsers(res.data.data.docs);
          setCount(res.data.data.totalDocs);
          setPage(res.data.data.page);
          setLoadingStatus(false);
        } else {
          setLoadingStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setLoadingStatus(false);
        dispatch(showError(error.message));
      });
  }, []);

  useEffect(() => {
    if (user.role === "admin") {
      getUsers({ limit, page });
    }
  }, []);

  const handleChangeRowsPerPage = (event) => {
    setLimit(event.target.value);
    getUsers({ limit: event.target.value, page: 1 });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    getUsers({ limit, page: newPage + 1 });
  };

  const onCreateUserModalClose = (user) => {
    if (user) {
      setUsers([user, ...users]);
      setCount(count + 1);
      setLoadingStatus(false);
    }
    toggleCreateUserModal(false);
  };

  const onOptionClick = (event, user) => {
    setSelectedUser(user);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openDelConfirmationDialog = () => {
    setIsOpenDelConfirmationDialogStatus(true);
  };

  const onResetPasswordMenuItemClick = () => {
    setAnchorEl(null);
    toggleResetPasswordModal(true);
  };

  const onDeleteMenuItemClick = () => {
    setAnchorEl(null);
    openDelConfirmationDialog();
  };

  const closeDelConfirmationDialog = (isConfired) => {
    if (isConfired) {
      setLoadingStatus(true);
      api
        .delete(`users/${selectedUser._id}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((res) => {
          setLoadingStatus(false);
          if (res.data.success) {
            setUsers(users.filter((user) => user._id !== selectedUser._id));
            setCount(count - 1);
            dispatch(showMessage(res.data.message));
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setLoadingStatus(false);
          dispatch(showError(error.response.data.message));
        });
    }
    setIsOpenDelConfirmationDialogStatus(false);
  };

  return (
    <Container className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <Typography variant="h3">Settings</Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={12} xl={12}>
            <Paper className={classes.paper}>
              <Grid container alignItems="center">
                <Grid item xs>
                  <Typography variant="subtitle1">RESET PASSWORD</Typography>
                  <Typography variant="caption">
                    Change the password you use to login in this system
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() =>
                      toggleChangePasswordModal(!isOpenChangePasswordModal)
                    }
                    variant="outlined"
                  >
                    Change Password
                  </Button>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: 10 }} />
              <Grid item>
                <FormControlLabel
                  value="DarkTheme"
                  control={
                    <Switch
                      color="primary"
                      checked={themeContext.theme === "dark"}
                      onChange={() => themeContext.toggleTheme()}
                    />
                  }
                  label="Dark Theme"
                  labelPlacement="start"
                  style={{ marginLeft: 0 }}
                />
              </Grid>
            </Paper>
          </Grid>

          {user.role === "admin" && (
            <Grid item xs={12} md={12} xl={12}>
              <Paper className={classes.paper}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="h5">Users</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        toggleCreateUserModal(!isCreateUserModalOpen)
                      }
                    >
                      <AddIcon /> Create New User
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer className={classes.tableContainer}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    padding="checkbox"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align={"left"} padding="default">
                          Name
                        </TableCell>
                        <TableCell align={"center"} padding="default">
                          Username
                        </TableCell>
                        <TableCell align={"right"} padding="default">
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={user._id}
                          >
                            <TableCell align={"left"}>{user.name}</TableCell>
                            <TableCell align={"center"}>{user.email}</TableCell>
                            <TableCell align="right">
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={(e) => onOptionClick(e, user)}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                  component="div"
                  count={count}
                  rowsPerPage={limit}
                  page={page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
                <Backdrop open={isLoading} className={classes.backdrop}>
                  <CircularProgress />
                </Backdrop>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Grid>

      <ChangePasswordModal
        open={isOpenChangePasswordModal}
        handleClose={() =>
          toggleChangePasswordModal(!isOpenChangePasswordModal)
        }
      />

      {selectedUser && (
        <ResetPasswordModal
          open={isOpenResetPasswordModal}
          userId={selectedUser?._id}
          handleClose={() =>
            toggleResetPasswordModal(!isOpenResetPasswordModal)
          }
        />
      )}

      <CreateUserModal
        open={isCreateUserModalOpen}
        handleClose={(user) => onCreateUserModalClose(user)}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={() => onResetPasswordMenuItemClick()}>
          Reset Password
        </MenuItem>
        <MenuItem onClick={() => onDeleteMenuItemClick()}>Delete</MenuItem>
      </Menu>

      {selectedUser && (
        <Dialog
          open={isOpenDelConfirmationDialog}
          onClose={() => closeDelConfirmationDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Are you sure you want to delete ${selectedUser?.name}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => closeDelConfirmationDialog(false)}
              color="secondary"
              variant="contained"
            >
              NO
            </Button>
            <Button
              onClick={(e) => closeDelConfirmationDialog(true)}
              color="primary"
              variant="contained"
            >
              YES
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
};

export default Settings;

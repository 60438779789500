import { gql } from "@apollo/client";

export const GET_COLLECTIONS = gql`
  query getCollections {
    collections(first: 100) {
      edges {
        node {
          id
          handle
          title
        }
      }
    }
  }
`;

export const GET_COLLECTIONS_WITH_IMAGE = gql`
  query getCollectionsWithImage($cursor: String, $query: String) {
    collections(first: 20, after: $cursor, query: $query) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          handle
          title
          image {
            altText
            id
            transformedSrc(maxHeight: 100, maxWidth: 100)
            originalSrc
          }
        }
      }
    }
  }
`;

export const GET_COLLECTIONS_BY_IDS = gql`
  query MyQuery($collectionIds: [ID!]!) {
    nodes(ids: $collectionIds) {
      ... on Collection {
        id
        title
        handle
        image(maxHeight: 100, maxWidth: 100) {
          id
          originalSrc
          altText
        }
      }
    }
  }
`;

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  FormGroup,
  TextField,
  Select,
  Button,
  CircularProgress,
  MenuItem,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { Formik } from "formik";
import { pushNotificationValidationScheme } from "../utils/yupValidationScheme";
import api from "../config/api";
import { useDispatch } from "react-redux";
import { showError, showMessage } from "../actions/snackbarAction";
import ImageSelector from "../components/ImageSelector";
import LinkSelector from "../components/LinkSelector";
import pushNotificationTopics from "../config/pushNotificationTopics";
import { useHistory } from "react-router-dom";

const SCHEDULE = {
  schedule: {
    type: "SCHEDULED",
    label: "Scheduled",
  },
  now: {
    type: "INSTANT",
    label: "Now",
  },
};

const useStyles = makeStyles({
  card: {
    maxWidth: "100%",
  },
  formField: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const CreatePushNotification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [schedule, setSchedule] = useState(SCHEDULE.now.type);
  const [scheduledTime, setScheduledTime] = useState();
  const [isSaving, setSavingStatus] = useState(false);

  const notification = history.location.state?.notification;

  useEffect(() => {
    if (notification && notification.time) {
      console.log(notification);
      setScheduledTime(new Date(notification.time).toISOString().split(".")[0]);
      setSchedule(SCHEDULE.schedule.type);
    }
  }, [notification]);

  const saveNotification = (values, resetForm) => {
    if (notification && notification._id) {
      setSavingStatus(true);
      api
        .post(`update-saved-notification/${notification._id}`, {
          ...values,
          scheduledTime:
            schedule === SCHEDULE.schedule.type ? scheduledTime : null,
        })
        .then((res) => {
          setSavingStatus(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            setSchedule(SCHEDULE.now.type);
            setScheduledTime(null);
            resetForm();
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSavingStatus(false);
          dispatch(showError(error.message));
        });
    } else {
      setSavingStatus(true);
      api
        .post("save-notification", {
          ...values,
          scheduledTime:
            schedule === SCHEDULE.schedule.type ? scheduledTime : null,
        })
        .then((res) => {
          setSavingStatus(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            setSchedule(SCHEDULE.now.type);
            setScheduledTime(null);
            resetForm();
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSavingStatus(false);
          dispatch(showError(error.message));
        });
    }
  };

  const onSend = (values, { setSubmitting, resetForm }) => {
    if (notification && notification._id) {
      api
        .put(`push-notification/${notification._id}`, {
          ...values,
          scheduledTime:
            schedule === SCHEDULE.schedule.type ? scheduledTime : null,
        })
        .then((res) => {
          setSubmitting(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            setSchedule(SCHEDULE.now.type);
            setScheduledTime(null);
            resetForm();
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showError(error.message));
        });
    } else {
      if (schedule === SCHEDULE.now.type) {
        api
          .post("send-push-notification", values)
          .then((res) => {
            setSubmitting(false);
            if (res.data.success) {
              dispatch(showMessage(res.data.message));
              setSchedule(SCHEDULE.now.type);
              setScheduledTime(null);
              resetForm();
              history.goBack();
            } else {
              dispatch(showError(res.data.message));
            }
          })
          .catch((error) => {
            setSubmitting(false);
            dispatch(showError(error.message));
          });
      } else {
        if (!scheduledTime) {
          dispatch(
            showError("Please set a time for schedule push notification.")
          );
          setSubmitting(false);
          return;
        }
        api
          .post("schedule-push-notification", {
            ...values,
            scheduledTime,
          })
          .then((res) => {
            setSubmitting(false);
            if (res.data.success) {
              dispatch(showMessage(res.data.message));
              resetForm();
              history.goBack();
            } else {
              dispatch(showError(res.data.message));
            }
          })
          .catch((error) => {
            setSubmitting(false);
            dispatch(showError(error.message));
          });
      }
    }
  };

  const onCancel = () => {
    history.goBack();
  };

  return (
    <Container className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <Typography variant="h3">Push Notification</Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.row}>
        <Grid item xl={12} md={12} sm={12} xs={12} className={classes.root}>
          <Card variant="outlined">
            <CardContent>
              <Formik
                initialValues={
                  notification
                    ? {
                        title: notification.title,
                        message: notification.message,
                        image: notification.image,
                        link: notification.link,
                        target: notification.target,
                      }
                    : {
                        title: "",
                        message: "",
                        image: null,
                        link: null,
                        target: pushNotificationTopics.TOPIC_GENERAL,
                      }
                }
                validationSchema={pushNotificationValidationScheme}
                onSubmit={onSend}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  setSubmitting,
                  handleSubmit,
                  resetForm,
                  setFieldValue,
                }) => (
                  <>
                    <FormControl
                      variant="outlined"
                      className={classes.formField}
                      fullWidth
                    >
                      <InputLabel id="target">Target</InputLabel>
                      <Select
                        labelId="target"
                        label="Target"
                        variant="outlined"
                        name="target"
                        value={values.target}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.target && errors.target}
                        renderValue={(value) => value.title}
                      >
                        {Object.keys(pushNotificationTopics).map(
                          (target, index) => (
                            <MenuItem
                              value={pushNotificationTopics[target]}
                              key={index.toString()}
                            >
                              {pushNotificationTopics[target].title}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {touched.title && errors.title ? (
                        <Typography variant="caption" color="textSecondary">
                          {errors.title}
                        </Typography>
                      ) : null}
                    </FormControl>
                    <FormGroup className={classes.formField}>
                      <TextField
                        label="Title"
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.title && errors.title
                            ? errors.title
                            : "Title of the notification"
                        }
                        error={touched.title && errors.title}
                        variant="outlined"
                      />
                    </FormGroup>
                    <FormGroup className={classes.formField}>
                      <TextField
                        label="Message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helperText={
                          touched.message && errors.message
                            ? errors.message
                            : "Message of the notification"
                        }
                        error={touched.message && errors.message}
                        variant="outlined"
                      />
                    </FormGroup>
                    <FormGroup className={classes.formField}>
                      <Typography variant="subtitle2">Image</Typography>
                      <ImageSelector
                        imageUploadFolder="Push-Notification"
                        selectedImage={values.image}
                        onImageChange={(image) => setFieldValue("image", image)}
                      />
                    </FormGroup>
                    <FormGroup className={classes.formField}>
                      <LinkSelector
                        preSelectedLink={values.link}
                        onParamSelect={(param) => {
                          setFieldValue("link", {
                            ...values.link,
                            param,
                          });
                        }}
                        onScreenSelect={(screen) => {
                          setFieldValue("link", {
                            ...values.link,
                            screen,
                          });
                        }}
                      />
                    </FormGroup>
                    <FormControl
                      variant="outlined"
                      className={classes.formField}
                      fullWidth
                    >
                      <InputLabel id="scheduleLabel">Schedule</InputLabel>
                      <Select
                        labelId="scheduleLabel"
                        label="Schedule"
                        variant="outlined"
                        name="schedule"
                        value={schedule}
                        onChange={(e) => setSchedule(e.target.value)}
                      >
                        <MenuItem value={SCHEDULE.now.type}>
                          {SCHEDULE.now.label}
                        </MenuItem>
                        <MenuItem value={SCHEDULE.schedule.type}>
                          {SCHEDULE.schedule.label}
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {schedule === SCHEDULE.schedule.type && (
                      <FormGroup className={classes.formField}>
                        <TextField
                          id="datetime-local"
                          label="Time"
                          type="datetime-local"
                          variant="outlined"
                          value={scheduledTime}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setScheduledTime(e.target.value);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            min: new Date().toISOString().split(".")[0],
                          }}
                        />
                      </FormGroup>
                    )}
                    <Button
                      color="secondary"
                      onClick={onCancel}
                      variant="contained"
                    >
                      CANCEL
                    </Button>
                    {!notification || notification.status === "Draft" ? (
                      <Button
                        onClick={() => saveNotification(values, resetForm)}
                        variant="outlined"
                        style={{ marginLeft: 10 }}
                        disabled={isSaving}
                      >
                        {isSaving && (
                          <CircularProgress
                            size={18}
                            style={{ marginRight: 5 }}
                          />
                        )}
                        SAVE AS DRAFT
                      </Button>
                    ) : null}
                    <Button
                      color="primary"
                      onClick={handleSubmit}
                      variant="contained"
                      style={{ marginLeft: 10 }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <CircularProgress
                          size={18}
                          style={{ marginRight: 5 }}
                        />
                      )}
                      PUBLISH
                    </Button>
                  </>
                )}
              </Formik>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CreatePushNotification;

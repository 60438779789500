import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  TextField,
  FormGroup,
  Button,
  Card,
  CardContent,
  InputLabel,
  CardActions,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  Box,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Formik } from "formik";
import api from "../../config/api";
import { showMessage, showError } from "../../actions/snackbarAction";
import { pageFormMode } from "../../contants/others";
import { cardValidationScheme } from "../../utils/yupValidationScheme";
import ImageSelector from "../../components/ImageSelector";
import LinkSelector from "../../components/LinkSelector";
import FormNote from "../../components/FormNote";
import HtmlField from "../../components/HtmlField";
import SectionCommonStyleCreator from "../../components/SectionCommonStyleCreator";
import VisibilityCreator from "../../components/VisibilityCreator";
import ButtonField from "../../components/ButtonField";

const CardTypeBox = ({ color }) => (
  <Box
    style={{ height: 50, width: 50, backgroundColor: color, marginRight: 10 }}
  />
);

const useStyle = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

const InfoCard = () => {
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const [formMode, setFormMode] = useState(pageFormMode.CREATE);
  const [prevFormData, setPrevFormData] = useState({});

  useEffect(() => {
    const { mode, data } = history.location.state;
    if (data) {
      setFormMode(mode);
      setPrevFormData(data);
    }
  }, [history.location.state]);

  const onSave = (values, { setSubmitting }) => {
    const { section } = history.location.state;
    const params = {
      title: section.title,
      contentType: section.key,
      content: null,
      settings: values,
    };
    if (!formMode || formMode === pageFormMode.CREATE) {
      api
        .post("app-content", params)
        .then((res) => {
          setSubmitting(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showMessage(error.message));
        });
    } else {
      api
        .put(`app-content/${prevFormData._id}`, params)
        .then((res) => {
          setSubmitting(false);
          if (res.data.success) {
            dispatch(showMessage(res.data.message));
            history.goBack();
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          setSubmitting(false);
          dispatch(showMessage(error.message));
        });
    }
  };

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={12} xl={12}>
          <Typography variant="h3">
            {history.location.state.data?.settings?.heading ||
              history.location.state.section?.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Formik
            enableReinitialize={true}
            initialValues={
              !formMode || formMode === pageFormMode.CREATE
                ? {
                    cardType: "primary",
                    heading: "",
                    image: null,
                    buttonLabel: "",
                    buttonLink: null,
                    visibility: true,
                  }
                : prevFormData.settings
            }
            onSubmit={onSave}
            validationSchema={cardValidationScheme}
          >
            {({
              values,
              handleChange,
              handleBlur,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleSubmit,
              setTouched,
              isValid,
            }) => (
              <Card variant="outlined">
                <CardContent>
                  <FormNote />
                  <FormControl
                    variant="outlined"
                    className={classes.formField}
                    fullWidth
                  >
                    <InputLabel id="cardTypeLabel" variant="outlined">
                      Card Type
                    </InputLabel>
                    <Select
                      labelId="cardTypeLabel"
                      label="Card Type"
                      name="cardType"
                      value={values.cardType}
                      onChange={(e) => {
                        setFieldValue("cardType", e.target.value);
                      }}
                      renderValue={(value) =>
                        value === "primary" ? (
                          <MenuItem key={"primary"} value={"primary"}>
                            <CardTypeBox color={"#29110d"} />
                            <Typography>Primary</Typography>
                          </MenuItem>
                        ) : (
                          <MenuItem key={"secondary"} value={"secondary"}>
                            <CardTypeBox color={"#fae0c6"} />
                            <Typography>Secondary</Typography>
                          </MenuItem>
                        )
                      }
                    >
                      <MenuItem key={"primary"} value={"primary"}>
                        <CardTypeBox color={"#29110d"} />
                        <Typography>Primary</Typography>
                      </MenuItem>
                      <MenuItem key={"secondary"} value={"secondary"}>
                        <CardTypeBox color={"#fae0c6"} />
                        <Typography>Secondary</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <FormGroup className={classes.formField}>
                    <TextField
                      id="heading"
                      label="Heading"
                      variant="outlined"
                      name="heading"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.heading}
                      error={errors.heading && touched.heading}
                      helperText={
                        errors.heading && touched.heading && errors.heading
                      }
                    />
                  </FormGroup>
                  <FormGroup className={classes.formField}>
                    <InputLabel>Image</InputLabel>
                    <ImageSelector
                      selectedImage={values.image}
                      onImageChange={(image) => setFieldValue("image", image)}
                      imageUploadFolder="App-Content/Card"
                    />
                  </FormGroup>
                  <FormGroup className={classes.formField}>
                    <TextField
                      id="buttonLabel"
                      label="Button Label"
                      variant="outlined"
                      name="buttonLabel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.buttonLabel}
                      error={errors.buttonLabel && touched.buttonLabel}
                      helperText={
                        errors.buttonLabel &&
                        touched.buttonLabel &&
                        errors.buttonLabel
                      }
                    />
                  </FormGroup>

                  <LinkSelector
                    label="Button Link"
                    preSelectedLink={values.buttonLink}
                    onParamSelect={(param) =>
                      setFieldValue("buttonLink", {
                        ...values.buttonLink,
                        param,
                      })
                    }
                    onScreenSelect={(screen) => {
                      setFieldValue("buttonLink", {
                        ...values.buttonLink,
                        screen,
                      });
                    }}
                  />
                  <VisibilityCreator
                    visibility={values.visibility}
                    onVisibilityChange={(visibility) =>
                      setFieldValue("visibility", visibility)
                    }
                  />
                </CardContent>
                <CardActions>
                  <Button
                    title="Save"
                    variant="contained"
                    color="secondary"
                    onClick={() => history.goBack()}
                  >
                    CANCEL
                  </Button>
                  <Button
                    title="Save"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Saving" : "Save"}
                    {isSubmitting && (
                      <CircularProgress
                        size={20}
                        color="primary"
                        style={{ marginLeft: 5 }}
                      />
                    )}
                  </Button>
                </CardActions>
              </Card>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InfoCard;

import React, { useContext } from "react";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Formik } from "formik";
import { loginValidationScheme } from "../utils/yupValidationScheme";
import api from "../config/api";
import { showError, showMessage } from "../actions/snackbarAction";
import { useDispatch } from "react-redux";
import { setUser } from "../actions/userAction";
import logoDark from "../assets/images/logo_hr_dark.png";
import logoLight from "../assets/images/logo_hr_light.png";
import ThemeContext from "../utils/themeContext";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100%",
    minWidth: "100%",
    overflow: "scroll",
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  root: {
    overflow: "scroll",
  },
  logo: {
    margin: "auto",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    objectFit: "contain",
    display: "flex",
    height: 80,
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0, 2, 0),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const onSubmit = (values, { setSubmitting }) => {
    api
      .post("users/login", values)
      .then((res) => {
        if (res.data.success) {
          setSubmitting(false);
          dispatch(setUser(res?.data?.data?.user, res?.data?.data?.token));
          dispatch(showMessage(res.data.message));
        } else {
          setSubmitting(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setSubmitting(false);
        dispatch(showError(error.message));
      });
  };

  return (
    <Container component="main" maxWidth="xs" className={classes.container}>
      <div className={classes.root}>
        <img
          src={themeContext.theme === "dark" ? logoLight : logoDark}
          alt="logo"
          className={classes.logo}
        />
        <Divider variant="fullWidth" style={{ backgroundColor: "#000000" }} />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginValidationScheme}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form className={classes.form} noValidate>
                <TextField
                  id="email"
                  label="Email"
                  margin="normal"
                  fullWidth
                  required
                  defaultValue=""
                  autoComplete="email"
                  variant="outlined"
                  autoFocus
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={errors.password && touched.password}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleSubmit}
                >
                  {isSubmitting ? (
                    <CircularProgress size={20} color="#ffffff" />
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </form>
            )}
          </Formik>
        </Paper>
      </div>
    </Container>
  );
};

export default Login;

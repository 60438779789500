import { SET_USER, SET_TOKEN } from "../contants/actionKeys";

export const setUser = (user, token) => {
  return (dispatch) => {
    localStorage.setItem("token", token);
    dispatch({
      type: SET_USER,
      user,
      token,
    });
  };
};

export const setToken = (token) => {
  return (dispatch) => {
    localStorage.setItem("token", token);
    dispatch({
      type: SET_TOKEN,
      token,
    });
  };
};

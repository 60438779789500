import * as ActionKeys from "../contants/actionKeys";

const initialState = {
  successSnackbarOpen: false,
  successSnackbarMessage: "",
  errorSnackbarOpen: false,
  errorSnackbarMessage: "",
};

const snackbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionKeys.SHOW_SUCCESS_SNACKBAR: {
      return {
        ...state,
        successSnackbarMessage: action.message,
        successSnackbarOpen: true,
      };
    }
    case ActionKeys.CLOSE_SUCCESS_SNACKBAR: {
      return {
        ...state,
        successSnackbarOpen: false,
        successSnackbarMessage: "",
      };
    }
    case ActionKeys.SHOW_ERROR_SNACKBAR: {
      return {
        ...state,
        errorSnackbarMessage: action.message,
        errorSnackbarOpen: true,
      };
    }
    case ActionKeys.CLOSE_ERROR_SNACKBAR: {
      return {
        ...state,
        errorSnackbarOpen: false,
        errorSnackbarMessage: "",
      };
    }
    case ActionKeys.CLEAR_SNACKBAR: {
      return {
        ...state,
        successSnackbarOpen: false,
        successSnackbarMessage: "",
        errorSnackbarOpen: false,
        errorSnackbarMessage: "",
      };
    }
    default:
      return state;
  }
};

export default snackbarReducer;

import React, { useState, useEffect, useCallback } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Card,
  ButtonBase,
  IconButton,
  CircularProgress,
  Switch,
  Box,
  Paper,
  Backdrop,
} from "@material-ui/core";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@material-ui/icons";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import Skeleton from "@material-ui/lab/Skeleton";
import arrayMove from "array-move";
import MenuItemDialog from "../components/MenuItemDialog";
import api from "../config/api";
import { useDispatch } from "react-redux";
import { showError } from "../actions/snackbarAction";

const LoadingPlaceholder = () => {
  const classes = useStyles();
  return [1, 2, 3, 4, 5, 6].map((item) => (
    <Grid
      item
      xl={12}
      md={12}
      sm={12}
      xs={12}
      key={item.toString()}
      className={classes.placeholderRow}
    >
      <Card className={classes.menuItem}>
        <div>
          <Skeleton className={classes.media} variant="rect" />
        </div>
        <div className={classes.titleCotainer}>
          <Typography variant="h6" style={{ width: "100%" }}>
            <Skeleton variant="text" />
          </Typography>
        </div>
        <div className={classes.actionButtonContainer}>
          <Skeleton
            className={classes.actionButtonPlaceholder}
            variant="rect"
          />
          <Skeleton
            className={classes.actionButtonPlaceholder}
            variant="rect"
          />
          <Skeleton
            className={classes.actionButtonPlaceholder}
            variant="rect"
          />
        </div>
      </Card>
    </Grid>
  ));
};

const useStyles = makeStyles((theme) => ({
  subMenuContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    margin: theme.spacing(2),
  },
  menuItem: {
    height: "100%",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  titleCotainer: {
    display: "flex",
    flex: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  row: {
    marginTop: theme.spacing(1),
  },
  placeholderRow: {
    marginTop: theme.spacing(2),
  },
  addButton: {
    borderWidth: 1,
    borderStyle: "dashed",
    width: "100%",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    justifyContent: "space-around",
  },
  actionButtonPlaceholder: {
    height: 30,
    width: 30,
    marginLeft: 5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DragHandle = SortableHandle(() => (
  <IconButton>
    <DragIndicatorIcon style={{ cursor: "move" }} />
  </IconButton>
));

const SideMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isFetchingMenu, setFetchingMenuStatus] = useState(true);
  const [isLoadingPage, setPageLoadingStatus] = useState(false);
  const [deleting, setDeleting] = useState([]);
  const [menu, setMenu] = useState([]);
  const [selectedMenuItemParentId, setSelectedMenuItemParentId] = useState(
    null
  );
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [formMode, setFormMode] = useState();
  const [isOpenMenuItemDialog, setMenuItemDialogOpenStatus] = useState(false);
  const [openedSubmenu, setOpenedSubmenu] = useState([]);

  const FORM_MODE = {
    CREATE_MENU: "CREATE_MENU",
    EDIT_MENU: "EDIT_MENU",
    CREATE_SUBMENU: "CREATE_SUBMENU",
    EDIT_SUBMENU: "EDIT_SUBMENU",
  };

  const getMenu = useCallback(() => {
    api
      .get("menu")
      .then((res) => {
        setFetchingMenuStatus(false);
        if (res.data.success) {
          setMenu(
            res.data.data.map((item) => {
              return {
                ...item,
                subMenu: item.subMenuOrder.map((order) =>
                  item.subMenu.find((sm) => sm._id === order)
                ),
              };
            })
          );
          setPageLoadingStatus(false);
        } else {
          setPageLoadingStatus(false);
          dispatch(showError(res.message));
        }
      })
      .catch((error) => {
        setPageLoadingStatus(false);
        setFetchingMenuStatus(false);
        dispatch(showError(error.message));
      });
  }, []);

  useEffect(() => {
    getMenu();
  }, [getMenu]);

  const toggleSubmenu = (menuId) => {
    const index = openedSubmenu.indexOf(menuId);
    if (index > -1) {
      setOpenedSubmenu(openedSubmenu.filter((id) => id !== menuId));
    } else {
      setOpenedSubmenu([...openedSubmenu, menuId]);
    }
  };

  const onSortEnd = ({ oldIndex, newIndex, data }) => {
    const newData = arrayMove(data, oldIndex, newIndex);
    return newData;
  };

  const onMenuItemClick = (parentMenuId, selfId, menuItem, formModeToSet) => {
    setSelectedMenuItemParentId(parentMenuId);
    setSelectedMenuItem(menuItem);
    setFormMode(formModeToSet);
    setMenuItemDialogOpenStatus(true);
  };

  const onMenuSortEnd = ({ oldIndex, newIndex, data }) => {
    setPageLoadingStatus(true);
    setMenu(onSortEnd({ oldIndex, newIndex, data }));
    const newMenuOrder = onSortEnd({ oldIndex, newIndex, data }).map(
      (item) => item._id
    );
    api
      .put("update-menu-order", {
        newOrder: newMenuOrder,
      })
      .then((res) => {
        setPageLoadingStatus(false);
      })
      .catch((error) => {
        setPageLoadingStatus(false);
      });
  };

  const onSubmenuSortEnd = ({ oldIndex, newIndex, data, parentMenuId }) => {
    setPageLoadingStatus(true);
    setMenu(
      menu.map((item) => {
        if (item._id !== parentMenuId) {
          return item;
        }
        return {
          ...item,
          subMenu: onSortEnd({ oldIndex, newIndex, data }),
        };
      })
    );

    const newMenuOrder = onSortEnd({ oldIndex, newIndex, data }).map(
      (item) => item._id
    );
    api
      .put(`menu/${parentMenuId}/update-submenu-order`, {
        newOrder: newMenuOrder,
      })
      .then((res) => {
        setPageLoadingStatus(false);
      })
      .catch((error) => {
        setPageLoadingStatus(false);
      });
  };

  const onMenuItemAdd = (savedItem, parentId) => {
    setPageLoadingStatus(true);
    getMenu();
  };

  const onVisibilityChange = (parentId, selfId, visibility) => {
    let url = parentId
      ? `menu/${parentId}/submenu/${selfId}/visibility`
      : `menu/${selfId}/visibility`;
    setPageLoadingStatus(true);
    api
      .put(url, {
        visibility,
      })
      .then((res) => {
        if (res.data.success) {
          getMenu();
        } else {
          setPageLoadingStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setPageLoadingStatus(false);
        dispatch(showError(error.message));
      });
  };

  const onDelete = (parentId, selfId) => {
    let url = parentId
      ? `menu/${parentId}/submenu/${selfId}`
      : `menu/${selfId}`;
    setPageLoadingStatus(true);
    api
      .delete(url)
      .then((res) => {
        if (res.data.success) {
          getMenu();
        } else {
          setPageLoadingStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setPageLoadingStatus(false);
        dispatch(showError(error.message));
      });
  };

  const SortableItem = SortableElement(({ value, parentMenuId }) => (
    <Grid item xl={12} md={12} sm={12} xs={12}>
      <Card>
        <Box className={classes.menuItem}>
          {value.subMenu && (
            <IconButton onClick={() => toggleSubmenu(value._id)}>
              {openedSubmenu.indexOf(value._id) > -1 ? (
                <ArrowDropUpIcon />
              ) : (
                <ArrowDropDownIcon />
              )}
            </IconButton>
          )}

          <ButtonBase
            style={{ flex: 1 }}
            onClick={() =>
              onMenuItemClick(
                parentMenuId,
                value._id,
                value,
                FORM_MODE.EDIT_MENU
              )
            }
          >
            <Box className={classes.titleCotainer}>
              <Typography variant="h6">{value.title}</Typography>
            </Box>
          </ButtonBase>

          <Box className={classes.actionButtonContainer}>
            <IconButton
              aria-label="Delete this section"
              onClick={() => onDelete(parentMenuId, value._id)}
            >
              {deleting.indexOf(value._id) > -1 ? (
                <CircularProgress size={14} color="inherit" />
              ) : (
                <DeleteIcon />
              )}
            </IconButton>
            <Switch
              checked={value.visible}
              value={value.visible}
              onChange={(e) =>
                onVisibilityChange(parentMenuId, value._id, e.target.checked)
              }
              color="primary"
              name="Toggle_Visibility"
              inputProps={{
                "aria-label": "Toggle section visibility",
              }}
            />
            <DragHandle />
          </Box>
        </Box>
        {openedSubmenu.indexOf(value._id) > -1 && (
          <Paper className={classes.subMenuContainer} variant="outlined">
            {value.subMenu && value.subMenu.length > 0 && (
              <SortableList
                items={value.subMenu}
                parentMenuId={value._id}
                onSortEnd={({ oldIndex, newIndex }) =>
                  onSubmenuSortEnd({
                    oldIndex,
                    newIndex,
                    data: value.subMenu,
                    parentMenuId: value._id,
                  })
                }
                useDragHandle
              />
            )}

            <ButtonBase
              className={classes.addButton}
              onClick={() =>
                onMenuItemClick(
                  parentMenuId,
                  value._id,
                  value,
                  FORM_MODE.CREATE_SUBMENU
                )
              }
            >
              <AddIcon />
              <Typography> ADD SUBMENU</Typography>
            </ButtonBase>
          </Paper>
        )}
      </Card>
    </Grid>
  ));

  const SortableList = SortableContainer(({ items, parentMenuId }) => {
    return (
      <Grid container className={classes.row} spacing={2}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value._id}-${new Date().toString()}`}
            index={index}
            value={value}
            parentMenuId={parentMenuId}
          />
        ))}
      </Grid>
    );
  });

  return (
    <Container className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3">Side Menu</Typography>
        </Grid>
      </Grid>

      {isFetchingMenu ? (
        <LoadingPlaceholder />
      ) : (
        <SortableList
          items={menu}
          onSortEnd={({ oldIndex, newIndex }) =>
            onMenuSortEnd({ oldIndex, newIndex, data: menu })
          }
          useDragHandle
        />
      )}

      <ButtonBase
        className={classes.addButton}
        style={{ marginTop: 20 }}
        onClick={() => onMenuItemClick(null, null, null, FORM_MODE.CREATE_MENU)}
      >
        <AddIcon />
        <Typography> ADD MENU</Typography>
      </ButtonBase>

      <MenuItemDialog
        open={isOpenMenuItemDialog}
        parentId={selectedMenuItemParentId}
        menuItemToEdit={selectedMenuItem}
        formMode={formMode}
        handleClose={() => setMenuItemDialogOpenStatus(false)}
        onMenuItemAdd={onMenuItemAdd}
      />

      <Backdrop open={isLoadingPage} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </Container>
  );
};

export default SideMenu;

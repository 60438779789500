import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Button,
  Card,
  IconButton,
  Switch,
  CircularProgress,
  ButtonBase,
  Backdrop,
} from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  DragIndicator as DragIndicatorIcon,
} from "@material-ui/icons";
import SelectSectionType from "./SelectSectionType";
import api from "../config/api";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showError, showMessage } from "../actions/snackbarAction";
import Skeleton from "@material-ui/lab/Skeleton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { pageFormMode } from "../contants/others";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { IMAGE_PLACEHOLDER, SIDE_MENU_ICON } from "../config/data";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

const useStyles = makeStyles((theme) => ({
  row: {
    marginTop: 10,
  },
  card: {
    height: "100%",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  emptyCard: {
    height: "100%",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  },
  media: {
    height: 80,
    width: 50,
    borderRadius: 10,
    objectFit: "contain",
  },
  titleCotainer: {
    display: "flex",
    flex: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  actionButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    justifyContent: "space-around",
  },
  addNewButtonContainer: {
    alignItems: "center",
    justifyContent: "flex-end",
    display: "flex",
    flex: 1,
  },
  actionButtonPlaceholder: {
    height: 30,
    width: 30,
    marginLeft: 5,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  listContainer: {
    marginBottom: 20,
  },
}));

const LoadingPlaceholder = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        <Skeleton variant="text" style={{ width: 150 }} />
      </Typography>
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <Grid
          item
          xl={12}
          md={12}
          sm={12}
          xs={12}
          key={item.toString()}
          className={classes.listContainer}
        >
          <Card className={classes.card}>
            <div>
              <Skeleton className={classes.media} variant="rect" />
            </div>
            <div className={classes.titleCotainer}>
              <Typography variant="h6" style={{ width: "100%" }}>
                <Skeleton variant="text" />
              </Typography>
            </div>
            <div className={classes.actionButtonContainer}>
              <Skeleton
                className={classes.actionButtonPlaceholder}
                variant="rect"
              />
              <Skeleton
                className={classes.actionButtonPlaceholder}
                variant="rect"
              />
              <Skeleton
                className={classes.actionButtonPlaceholder}
                variant="rect"
              />
            </div>
          </Card>
        </Grid>
      ))}
    </>
  );
};

const HeaderLoadingPlaceholder = () => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        <Skeleton variant="text" style={{ width: 150 }} />
      </Typography>
      {[1, 2].map((item) => (
        <Grid
          item
          xl={12}
          md={12}
          sm={12}
          xs={12}
          key={item.toString()}
          className={classes.listContainer}
        >
          <Card className={classes.card}>
            <div>
              <Skeleton className={classes.media} variant="rect" />
            </div>
            <div className={classes.titleCotainer}>
              <Typography variant="h6" style={{ width: "100%" }}>
                <Skeleton variant="text" />
              </Typography>
            </div>
            <div className={classes.actionButtonContainer}>
              <Skeleton
                className={classes.actionButtonPlaceholder}
                variant="rect"
              />
              <Skeleton
                className={classes.actionButtonPlaceholder}
                variant="rect"
              />
              <Skeleton
                className={classes.actionButtonPlaceholder}
                variant="rect"
              />
            </div>
          </Card>
        </Grid>
      ))}
    </>
  );
};

const AppSections = () => {
  const classes = useStyles();
  const [isOpenSelectSectionType, setIsOpenSelectSectionTypeStatus] = useState(
    false
  );
  const [isLoading, setIsLoadingStatus] = useState(true);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState([]);
  const history = useHistory();
  const [
    isOpenDelConfirmationDialog,
    setIsOpenDelConfirmationDialogStatus,
  ] = useState(false);
  const [contentToDelete, setContentToDelete] = useState(null);
  const [isPageInProgress, setPageInProgressStatus] = useState(false);

  const [headers, setHeaders] = useState([]);
  const [isHeaderLoading, setHeadingLoadingStatus] = useState(true);

  useEffect(() => {
    api
      .get("app-content")
      .then((res) => {
        console.log(res);
        setIsLoadingStatus(false);
        if (res.data.success) {
          setData(res.data.data);
        } else {
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setIsLoadingStatus(false);
        dispatch(showError(error.message));
      });

    api
      .get("app-header")
      .then((res) => {
        setHeadingLoadingStatus(false);
        if (res.data.success) {
          setHeaders(res.data.data);
        } else {
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setHeadingLoadingStatus(false);
        dispatch(showError(error.message));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigateToAddNewContent = (item) => {
    setIsOpenSelectSectionTypeStatus(true);
  };

  const navigateToSideMenu = () => {
    history.push(`/app-sections/side-menu`);
  };

  const deleteAppContent = (content) => {
    setContentToDelete(content);
    openDelConfirmationDialog();
  };

  const editAppContent = (contentId, section) => {
    setPageInProgressStatus(true);
    api
      .get(`app-content/${contentId}`)
      .then((res) => {
        if (res.data.success) {
          history.push(`/app-sections/${section.key}`, {
            mode: pageFormMode.EDIT,
            data: res.data.data,
            section,
          });
        } else {
          dispatch(showError(res.data.message));
        }
        setPageInProgressStatus(false);
      })
      .catch((error) => {
        setPageInProgressStatus(false);
        dispatch(showError(error.message));
      });
  };

  const openDelConfirmationDialog = () => {
    setIsOpenDelConfirmationDialogStatus(true);
  };

  const closeDelConfirmationDialog = (isConfired) => {
    if (isConfired) {
      setDeleting([...deleting, contentToDelete._id]);
      api
        .delete(`app-content/${contentToDelete._id}`)
        .then((res) => {
          const currentDeleting = [].concat(deleting);
          currentDeleting.splice(
            currentDeleting.indexOf(contentToDelete._id),
            1
          );
          setDeleting(currentDeleting);
          if (res.data.success) {
            console.log(res);
            setData(
              data.map((contentOrderSetting) => {
                return {
                  ...contentOrderSetting,
                  order: contentOrderSetting.order.filter(
                    (id) => id !== contentToDelete._id
                  ),
                  sections: contentOrderSetting.sections.filter(
                    (section) => section._id !== contentToDelete._id
                  ),
                };
              })
            );
            dispatch(showMessage(res.data.message));
          } else {
            dispatch(showError(res.data.message));
          }
        })
        .catch((error) => {
          const currentDeleting = [].concat(deleting);
          currentDeleting.splice(
            currentDeleting.indexOf(contentToDelete._id),
            1
          );
          setDeleting(currentDeleting);
          dispatch(showError(error.response.data.message));
        });
    }
    setIsOpenDelConfirmationDialogStatus(false);
  };

  const toggleVisibility = (contentId, visibility) => {
    setPageInProgressStatus(true);
    api
      .put(`app-content/setVisibility/${contentId}`, { visibility })
      .then((res) => {
        if (res.data.success) {
          setHeaders(
            headers.map((contentOrderSetting) => {
              return {
                ...contentOrderSetting,
                sections: contentOrderSetting.sections.map((section) => {
                  if (section._id === contentId) {
                    return {
                      ...res.data.data,
                      contentType: section.contentType,
                    };
                  }
                  return section;
                }),
              };
            })
          );
          setData(
            data.map((contentOrderSetting) => {
              return {
                ...contentOrderSetting,
                sections: contentOrderSetting.sections.map((section) => {
                  if (section._id === contentId) {
                    return {
                      ...res.data.data,
                      contentType: section.contentType,
                    };
                  }
                  return section;
                }),
              };
            })
          );
          setPageInProgressStatus(false);
        } else {
          setPageInProgressStatus(false);
          dispatch(showError(res.data.message));
        }
      })
      .catch((error) => {
        setPageInProgressStatus(false);
        dispatch(showError(error.message));
      });
  };

  const onSortEnd = ({ oldIndex, newIndex }, sections, contentOrderIndex) => {
    const temp = [...data];
    temp[contentOrderIndex].sections = arrayMove(sections, oldIndex, newIndex);
    setData(temp);
    // setData(arrayMove(sections, oldIndex, newIndex));
    console.log(temp[contentOrderIndex].sections);
    setPageInProgressStatus(true);
    const newOrderIds = temp[contentOrderIndex].sections.map((item) =>
      item.contentType.key !== "announcement-bar" ? item._id : null
    );
    api
      .put(`update-sections-order/${temp[contentOrderIndex]._id}`, {
        newOrder: newOrderIds,
      })
      .then((res) => {
        console.log(res);
        setPageInProgressStatus(false);
      })
      .catch((error) => {
        setPageInProgressStatus(false);
        console.log(error);
      });
  };

  const SectionItem = ({ value, isSortable }) => (
    <Grid item xl={12} md={12} sm={12} xs={12}>
      <Card key={value.slug} className={classes.card}>
        <Zoom>
          <div>
            {value.contentType &&
            value.contentType.image &&
            value.contentType.image.url !== "" ? (
              <img
                src={value.contentType.image.url}
                className={classes.media}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = IMAGE_PLACEHOLDER;
                }}
                alt={value.contentType.image.url.publicId}
              />
            ) : (
              <img
                src={IMAGE_PLACEHOLDER}
                className={classes.media}
                alt="placeholder"
              />
            )}
          </div>
        </Zoom>
        <ButtonBase
          style={{ flex: 1 }}
          onClick={() => editAppContent(value._id, value.contentType)}
        >
          <div className={classes.titleCotainer}>
            <Typography variant="h6">
              {value?.settings?.heading?.text &&
              value?.settings?.heading?.text !== ""
                ? value.settings.heading.text
                : value.title}
            </Typography>
          </div>
        </ButtonBase>
        <div className={classes.actionButtonContainer}>
          <IconButton
            aria-label="Delete this section"
            onClick={() => deleteAppContent(value)}
          >
            {deleting.indexOf(value._id) > -1 ? (
              <CircularProgress size={14} color="inherit" />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
          <Switch
            checked={value.settings.visibility}
            value={value.settings.visibility}
            onChange={(e) => toggleVisibility(value._id, e.target.checked)}
            color="primary"
            name="Toggle_Visibility"
            inputProps={{
              "aria-label": "Toggle section visibility",
            }}
          />
          {isSortable && <DragHandle />}
        </div>
      </Card>
    </Grid>
  );

  const DragHandle = SortableHandle(() => (
    <IconButton>
      <DragIndicatorIcon style={{ cursor: "move" }} />
    </IconButton>
  ));

  const SortableItem = SortableElement(({ value }) => (
    <SectionItem value={value} isSortable={true} />
  ));

  const SortableList = SortableContainer(({ item }) => {
    return item.sections.length > 0 ? (
      <Grid container spacing={2} className={classes.listContainer}>
        <Typography variant="h6" style={{ marginLeft: 10 }}>
          {item.content}
        </Typography>
        {item.sections.map((value, index) => (
          <SortableItem key={`item-${value._id}`} index={index} value={value} />
        ))}
      </Grid>
    ) : null;
  });

  return (
    <Container>
      <Grid container>
        <Grid item xs={6} md={6} xl={6}>
          <Typography variant="h3">App Content</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          xl={6}
          className={classes.addNewButtonContainer}
        >
          <Button onClick={() => navigateToAddNewContent()} variant="outlined">
            <AddIcon /> ADD NEW
          </Button>
        </Grid>
      </Grid>
      {!isLoading && data.length === 0 && (
        <Card
          className={classes.card}
          style={{ height: "100%", marginTop: 10 }}
        >
          <Typography>Your app data is empty</Typography>
        </Card>
      )}

      <Grid container className={classes.row}>
        {/* Menu Bar Start */}
        <Grid container spacing={2} className={classes.listContainer}>
          <Typography variant="h6" style={{ marginLeft: 10 }}>
            Side Menu
          </Typography>
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <Card className={classes.card}>
              <Zoom>
                <img
                  src={SIDE_MENU_ICON}
                  className={classes.media}
                  alt="placeholder"
                />
              </Zoom>
              <ButtonBase style={{ flex: 1 }} onClick={navigateToSideMenu}>
                <div className={classes.titleCotainer}>
                  <Typography variant="h6">Side Menu</Typography>
                </div>
              </ButtonBase>
            </Card>
          </Grid>
        </Grid>
        {/* Menu bar ends */}

        {/* Header start */}
        {isHeaderLoading ? (
          <HeaderLoadingPlaceholder />
        ) : (
          <>
            {headers.map((contentOrder, contentOrderIndex) => {
              if (contentOrder.content === "Header") {
                return contentOrder.sections.length > 0 ? (
                  <Grid
                    container
                    spacing={2}
                    className={classes.listContainer}
                    key={contentOrderIndex.toString()}
                  >
                    <Typography variant="h6" style={{ marginLeft: 10 }}>
                      {contentOrder.content}
                    </Typography>
                    {contentOrder.sections.map((value, index) => (
                      <SectionItem
                        key={`item-${value._id}`}
                        value={value}
                        isSortable={false}
                      />
                    ))}
                  </Grid>
                ) : null;
              }
              return (
                <SortableList
                  key={contentOrderIndex.toString()}
                  item={contentOrder}
                  onSortEnd={({ oldIndex, newIndex }) =>
                    onSortEnd(
                      { oldIndex, newIndex },
                      contentOrder.sections,
                      contentOrderIndex
                    )
                  }
                  useDragHandle
                />
              );
            })}
          </>
        )}
        {/* Header end */}

        {/* App content start */}
        {isLoading ? (
          <LoadingPlaceholder />
        ) : (
          <>
            {data.map((contentOrder, contentOrderIndex) => {
              if (contentOrder.content === "Header") {
                return contentOrder.sections.length > 0 ? (
                  <Grid
                    container
                    spacing={2}
                    className={classes.listContainer}
                    key={contentOrderIndex.toString()}
                  >
                    <Typography variant="h6" style={{ marginLeft: 10 }}>
                      {contentOrder.content}
                    </Typography>
                    {contentOrder.sections.map((value, index) => (
                      <SectionItem
                        key={`item-${value._id}`}
                        value={value}
                        isSortable={false}
                      />
                    ))}
                  </Grid>
                ) : null;
              }
              return (
                <SortableList
                  key={contentOrderIndex.toString()}
                  item={contentOrder}
                  onSortEnd={({ oldIndex, newIndex }) =>
                    onSortEnd(
                      { oldIndex, newIndex },
                      contentOrder.sections,
                      contentOrderIndex
                    )
                  }
                  useDragHandle
                />
              );
            })}
          </>
        )}
      </Grid>
      <SelectSectionType
        open={isOpenSelectSectionType}
        handleClose={() => setIsOpenSelectSectionTypeStatus(false)}
      />
      <Dialog
        open={isOpenDelConfirmationDialog}
        onClose={() => closeDelConfirmationDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete ${
              contentToDelete?.settings?.heading?.text
                ? contentToDelete?.settings?.heading?.text
                : contentToDelete?.title
            } ?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => closeDelConfirmationDialog(false)}
            color="secondary"
            variant="contained"
          >
            NO
          </Button>
          <Button
            onClick={(e) => closeDelConfirmationDialog(true)}
            color="primary"
            variant="contained"
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={isPageInProgress} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>
    </Container>
  );
};

export default AppSections;
